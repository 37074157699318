import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Navigation, Pagination, Autoplay } from 'swiper/dist/js/swiper.esm'
import InputPhone from '../../comp/inputPhone/InputPhone';
import './css.scss';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import CONSTANTS from '../../comp/constants';
import CustomBuildSwiper from '../../comp/customSwiper/CustomBuildSwiper';
// import Skeleton from 'antd/lib/skeleton/index';
// import LazyLoad from 'react-lazyload/lib/index';

const URL = CONSTANTS.URL_IMAGES;
const icNext = URL + 'pre1.png'
const icPrev = URL + 'next1.png'

const paramsPC = {
    modules: [Navigation, Autoplay],
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
        clickable: true
    },
    renderPrevButton: () => <img src={icPrev} alt="IconPrev" className="swiper-button-next"></img>,
    renderNextButton: () => <img src={icNext} alt="IconNext" className="swiper-button-prev"></img>,
}

const paramsMB = {
    modules: [Pagination, Autoplay],
    autoplay: {
        delay: 5000,
        disableOnInteraction: false
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
    }
}

const FakeBanner = [{ link: "", redirectLink: '' }]

const Slide = () => {

    return (
        <div className="home_slide">
            <ContainerSP />
            {/*<InputPhone />*/}
        </div>
    );
}

export default Slide;


const Container = _ => {
    const [data, setData] = useState({ pc: FakeBanner, mb: FakeBanner })
    const height = useRef({ mb: document.documentElement.offsetWidth / 1.8, pc: document.documentElement.offsetWidth / 3.097 })

    function setBanner() {
        let listBannerPc = [];
        let listBannerMb = [];
        _.dataBanner.map((item) => {
            return item.placementId === 1 ? item.isWebsite ? listBannerPc.push(item) : listBannerMb.push(item) : null;
        })
        setData({ pc: listBannerPc, mb: listBannerMb });
    }

    useEffect(() => {
        if (_.dataBanner.length > 0)
            setBanner();
    }, [_.dataBanner])

    return (
        <Fragment>
            <MediaQuery minWidth={768}>
                <CustomBuildSwiper {...paramsPC} key={data.pc.length} data={data.pc.id}>
                    {data.pc.map((item, key) => {
                        return (
                            <div key={key + item.link} style={height ? { minHeight: height.current.pc } : {}}>
                                <Item item={item} className="pc" height={height} />
                            </div>
                        )
                    })}

                </CustomBuildSwiper>
            </MediaQuery>

            <MediaQuery maxWidth={767}>
                <CustomBuildSwiper {...paramsMB} key={data.mb.length}>
                    {data.mb.map((item, key) => {
                        return (
                            <div key={key + item.id} style={height ? { minHeight: height.current.mb } : {}}>
                                <Item item={item} className="mb" key={key} height={height} />
                            </div>
                        )
                    })}
                </CustomBuildSwiper>
            </MediaQuery>
        </Fragment>
    );
}

function mapStateToProps(state) {
    const { dataBanner } = state;
    return { dataBanner: dataBanner }
}


export const ContainerSP = connect(mapStateToProps)(Container);

const Item = ({ item, className }) => {
    return (
        <Fragment>
            <a href={item.redirectLink} target="_blank" rel="noopener noreferrer" >

                {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}> */}
                <img src={item.link} className={className} alt="" />
                {/* </LazyLoad> */}
            </a>
            {/* <div className="swiper-lazy-preloader"></div> */}
        </Fragment>
    )
}
