import React, { useEffect, useState, Fragment } from 'react';
import Header from '../header/Header';
import Menu from '../menu/Menu';
import BackDrop from '../back-drop/BackDrop';
import { getBanner } from '../../utils/CommomServices';
import { connect } from 'react-redux';
import { SetDataBanner } from '../../actions'
import { useTranslation } from "react-i18next";

let dataBanner = [];
const BoxHeader = (_) => {
    const [, i18n] = useTranslation();
    const [showMenu, setShowMenu] = useState(false);

    const togleMenu = (value) => {
        setShowMenu(value);
    }

    useEffect(() => {
        setBanner();
    }, [])

    useEffect(() => {
        SetbannerLanguage();
    }, [i18n.language])

    const SetbannerLanguage = () => {
        let data = [];
        if (i18n.language.includes("en")) {
            data = dataBanner.filter(item => { return item.langCode === "EN" })
        } else {
            data = dataBanner.filter(item => { return item.langCode !== "EN" })
        }
        _.SetDataBanner(data);
    }

    const setBanner = () => {
        getBanner({ pageId: 1 }).then((response) => {
            dataBanner = response;
            SetbannerLanguage();
        })
            .catch((error) => console.log(error))
    }

    return (
        <Fragment>
            <Header togleMenu={togleMenu} />
            <Menu show={showMenu} togleMenu={togleMenu} />
            <BackDrop show={showMenu} togleMenu={togleMenu} />
        </Fragment>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        SetDataBanner: (value) => dispatch(SetDataBanner(value))
    }
};
export default connect(null, mapDispatchToProps)(BoxHeader);