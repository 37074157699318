import React, { Component } from 'react';
import $ from 'jquery';

const removeElementByIds = ids => {
    ids.forEach(id => {
        const element = document.getElementById(id);
        if (element && element.parentNode) {
            element.parentNode.removeChild(element);
        }
    });
};

export default class MessengerCustomerChat extends Component {
    static defaultProps = {
        appId: null,
        shouldShowDialog: false,
        showBadge: false,
        htmlRef: undefined,
        minimized: undefined,
        themeColor: undefined,
        loggedInGreeting: undefined,
        loggedOutGreeting: undefined,
        greetingDialogDisplay: undefined,
        greetingDialogDelay: undefined,
        autoLogAppEvents: true,
        xfbml: true,
        version: '2.11',
        onCustomerChatDialogShow: undefined,
        onCustomerChatDialogHide: undefined,
    };
    
    state = {
        fbLoaded: false,
        shouldShowDialog: undefined,
    };
    
    componentDidMount() {
        this.setFbAsyncInit();
        this.reloadSDKAsynchronously();
    }
    
    componentDidUpdate(prevProps) {
        if (
            prevProps.pageId !== this.props.pageId ||
            prevProps.appId !== this.props.appId ||
            prevProps.shouldShowDialog !== this.props.shouldShowDialog ||
            prevProps.htmlRef !== this.props.htmlRef ||
            prevProps.minimized !== this.props.minimized ||
            prevProps.themeColor !== this.props.themeColor ||
            prevProps.loggedInGreeting !== this.props.loggedInGreeting ||
            prevProps.loggedOutGreeting !== this.props.loggedOutGreeting ||
            prevProps.greetingDialogDisplay !== this.props.greetingDialogDisplay ||
            prevProps.greetingDialogDelay !== this.props.greetingDialogDelay ||
            prevProps.autoLogAppEvents !== this.props.autoLogAppEvents ||
            prevProps.xfbml !== this.props.xfbml ||
            prevProps.version !== this.props.version ||
            prevProps.language !== this.props.language
        ) {
            this.setFbAsyncInit();
            this.reloadSDKAsynchronously();
        }
        
        if ((prevProps.shouldShowDialog !== this.props.shouldShowDialog || prevProps.showBadge !== this.props.showBadge)) {
            this.toggleBadge();
        }
    }
    
    toggleBadge() {
        if (this.props.showBadge) {
            $("#fb-root").css("display", "block");
            if (window.FB) window.FB.CustomerChat.show(false);
        } else {
            $("#fb-root").css("display", "none");
        }
    }
    
    componentWillUnmount() {
        if (window.FB !== undefined) {
            window.FB.CustomerChat.hide();
        } else {
            this.removeFacebookSDK();
        }
    }
    
    setFbAsyncInit() {
        const { appId, autoLogAppEvents, xfbml, version } = this.props;
        
        window.fbAsyncInit = () => {
            window.FB.init({
                xfbml            : true,
                version          : 'v9.0'
            });
    
            this.toggleBadge();
            this.setState({ fbLoaded: true });
        };
    }
    
    loadSDKAsynchronously() {
        /* eslint-disable */
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src =
                'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
        /* eslint-enable */
    }
    
    removeFacebookSDK() {
        removeElementByIds(['facebook-jssdk', 'fb-root']);
        delete window.FB;
    }
    
    reloadSDKAsynchronously() {
        this.removeFacebookSDK();
        this.loadSDKAsynchronously();
    }
    
    controlPlugin() {
        const { shouldShowDialog, showBadge } = this.props;
        if (shouldShowDialog) {
            window.FB.CustomerChat.showDialog();
        } else {
            window.FB.CustomerChat.hideDialog();
        }
        
        if (showBadge) {
            window.FB.CustomerChat.show(false);
        } else {
            window.FB.CustomerChat.hide();
        }
    }
    
    
    subscribeEvents() {
        const { onCustomerChatDialogShow, onCustomerChatDialogHide } = this.props;
        
        if (onCustomerChatDialogShow) {
            window.FB.Event.subscribe(
                'customerchat.dialogShow',
                onCustomerChatDialogShow
            );
        }
        
        if (onCustomerChatDialogHide) {
            window.FB.Event.subscribe(
                'customerchat.dialogHide',
                onCustomerChatDialogHide
            );
        }
    }
    
    createMarkup() {
        const {
            pageId,
            htmlRef,
            minimized,
            themeColor,
            loggedInGreeting,
            loggedOutGreeting,
            greetingDialogDisplay,
            greetingDialogDelay
        } = this.props;
        
        const refAttribute = htmlRef !== undefined ? `ref="${htmlRef}"` : '';
        const minimizedAttribute =
            minimized !== undefined ? `minimized="${minimized}"` : '';
        const themeColorAttribute =
            themeColor !== undefined ? `theme_color="${themeColor}"` : '';
        const loggedInGreetingAttribute =
            loggedInGreeting !== undefined
                ? `logged_in_greeting="${loggedInGreeting}"`
                : '';
        const loggedOutGreetingAttribute =
            loggedOutGreeting !== undefined
                ? `logged_out_greeting="${loggedOutGreeting}"`
                : '';
        const greetingDialogDisplayAttribute =
            greetingDialogDisplay !== undefined
                ? `greeting_dialog_display="${greetingDialogDisplay}"`
                : '';
        const greetingDialogDelayAttribute =
            greetingDialogDelay !== undefined
                ? `greeting_dialog_delay="${greetingDialogDelay}"`
                : '';
        
        return {
            __html: `<div
                class="fb-customerchat"
                page_id="${pageId}"
                ${refAttribute}
                ${minimizedAttribute}
                ${themeColorAttribute}
                ${loggedInGreetingAttribute}
                ${loggedOutGreetingAttribute}
                ${greetingDialogDisplayAttribute}
                ${greetingDialogDelayAttribute}
            ></div>`,
        };
    }
    
    render() {
        const { fbLoaded, shouldShowDialog } = this.state;
        
        if (fbLoaded && shouldShowDialog !== this.props.shouldShowDialog) {
            document.addEventListener(
                'DOMNodeInserted',
                event => {
                    const element = event.target;
                    if (
                        element.className &&
                        typeof element.className === 'string' &&
                        element.className.includes('fb_dialog')
                    ) {
                        this.controlPlugin();
                    }
                },
                false
            );
            this.subscribeEvents();
        }
        // Add a random key to rerender. Reference:
        // https://stackoverflow.com/questions/30242530/dangerouslysetinnerhtml-doesnt-update-during-render
        return <div key={Date()} dangerouslySetInnerHTML={this.createMarkup()} />;
    }
}