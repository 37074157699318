import React, { Fragment } from 'react'
import { Tabs } from "antd";
import { useTranslation, Trans } from 'react-i18next'
import './style.scss';
import DropdownLocation from '../../../../comp/dropdown/DropdownLocation';

const { TabPane } = Tabs;

function Button_Tab(header, text) {
    return (
        <div className="item_tab">
            <div className="circle" ><span>{header}</span></div>
            <div className="text-bottom">{text}</div>
        </div>
    )
}


const ShinCare = () => {
    const { t, i18n } = useTranslation();
    const LIST_TAB = [
        {
            header: <div className="header-tab"><div>7</div> <div>{t("SHINE_CARE_DAY")}</div></div>,
            text: <span className="fix-header-tab">
                <Trans i18nKey="SHINE_CARE_TITLE_1" />
                <br />
            </span>,
            // content: <span>Nếu anh chưa hài lòng về kiểu tóc sau khi về nhà vì bất kỳ lý do gì,
            //      30Shine sẽ hỗ trợ anh sửa lại mái tóc đó hoàn toàn miễn phí trong vòng 7 ngày. Anh đặt lịch bình thường và báo sửa tóc với lễ tân <br /> <span style={{ fontWeight: 'bold' }}>Áp dụng:</span> Salon bất kỳ toàn hệ thống 30Shine.</span>
            content: t('SHINE_CARE_CONTENT_1')
        },
        {
            header: <div className="header-tab"><div>7</div> <div>{t("SHINE_CARE_DAY")}</div></div>,
            text: <span><Trans i18nKey="SHINE_CARE_TITLE_2"></Trans></span>,
            // content: <span>Các sản phẩm tạo kiểu (gôm, sáp) có giá dưới 400 ngàn đồng mua tại 30Shine anh có thể đổi hoặc trả lại hoàn toàn MIỄN PHÍ (hoàn lại 100% số tiền) trong vòng 3 ngày kể từ thời điểm mua hàng, ngay cả khi sản phẩm đó đã qua sử dụng. <br /> <span style={{ fontWeight: 'bold' }}>Áp dụng: Salon</span>
            //     bất kỳ toàn hệ thống 30Shine.<br />  <span style={{ fontWeight: 'bold' }}> Cam kết:</span> hoàn lại 100% tiền</span>
            content: t('SHINE_CARE_CONTENT_2')
        },
        {
            header: <div className="header-tab"><div>3</div> <div>{t("SHINE_CARE_DAY")}</div></div>,
            text: <span><Trans i18nKey="SHINE_CARE_TITLE_3"></Trans></span>,
            // content: <span>Mái tóc sau khi uốn nhuộm có thể không đúng ý anh sau khi về nhà.
            //     30Shine sẽ hỗ trợ anh chỉnh sửa hoàn toàn miễn phí trong vòng 15 ngày. Anh đặt lịch bình thường và báo sửa tóc với lễ tân<br /><span style={{ fontWeight: 'bold' }}> Áp dụng:</span> Salon bất kỳ toàn hệ thống 30Shine. <br /><span style={{ fontWeight: 'bold' }}> Lưu ý:</span> Các màu tóc tẩy chỉ áp dụng sửa sau 7 ngày kể từ ngày làm.</span>
            content: t('SHINE_CARE_CONTENT_3')
        },
        {
            header: <div className="header-tab"><div>14</div> <div>{t("SHINE_CARE_DAY")}</div></div>,
            text: <span><Trans i18nKey="SHINE_CARE_TITLE_4"></Trans><br /> </span>,
            // content: <span>30Shine cam kết phục vụ anh đúng giờ đặt lịch. Nếu anh phải chờ lâu hơn 20 phút so với giờ đặt lịch, 30Shine sẽ giảm giá ngay 20% cho hoá đơn Shine Combo.<br /> <span style={{ fontWeight: 'bold' }}> Áp dụng:</span> Khi các anh đến đúng giờ đã đặt. Thời gian chờ được tính từ lúc checkin tới lúc bắt đầu gội.<br /> <span style={{ fontWeight: 'bold' }}>Trừ trường hợp:
            //     </span> Salon xảy ra sự cố bất khả kháng: Mất điện, nước.</span>
            content: t('SHINE_CARE_CONTENT_4')
        }
    ]

    return (
        <div className="shin-care">
            <div className="content">
                <div className="content-left">
                    <div className="header">
                        <div><span>{t("SHINE_CARE")}</span></div>
                    </div>
                    <div className="content-tab">
                        <Tabs defaultActiveKey="1" type="card">
                            {LIST_TAB.map((item, key) => {
                                return (
                                    <TabPane style={style}
                                        tab={Button_Tab(item.header, item.text)}
                                        key={key + 1}>
                                            <div dangerouslySetInnerHTML={{__html: `${item.content}`}}/>
                                    </TabPane>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>

                <div className="content-right">
                    <div className="header">
                        <div><span>{t("LIST_STORE")}</span></div>
                    </div>
                    {/*<DropdownLocation />*/}
                </div>
            </div>
        </div>
    );
}

export default ShinCare;


const style = {
    padding: '15px',
    background: '#dfdfdf',
}