export const HairRemoval_th = {
    "HA_TITLE": "ฟอกสีผมผู้ชาย",
    "HA_TITLE_SUB": "ฟอกสีผมผู้ชายที่ 30SHINE",
    "HA_CONTENT_SUB1": "เป็นเจ้าของผมสีสดใส: สีควันบุหรี่, สีแพลตตินัม, สีฟ้า, ... คุณจะต้องฟอกสีผม 1-2 ครั้งเพื่อให้ได้สีผมที่คุณต้องการ!",
    "HA_CONTENT_SUB2": "สีผมที่สดใสเหล่านี้: สีควันบุหรี่, สีแพลตตินัม, สีฟ้า, ... กำลังเป็นเทรนด์ของวัยรุ่น คุณจะต้องฟอกสีผม 1-2 ครั้ง ขึ้นอยู่กับความสว่างของสีผม บริการฟอกสีผมผู้ชายที่ 30SHINE จะช่วยให้คุณมีสีผมที่ทันสมัยที่สุดเท่าที่คุณต้องการ!",
    "HA_PRICE": "ราคา: 100k / ครั้ง",

    "HA_CONTENT_TITLE": "ขั้นตอนการทำ",
    "HA_CONTENT_CONTENT": "เพื่อให้ได้สีผมที่สดใสเหมาะกับใบหน้าของคุณ ก่อนทำการย้อมสีผมสไตลิสต์มืออาชีพประสบการณ์สูงของ 30Shine จะทำการฟอกสีผม 1-2 ครั้ง ตามขั้นตอนแบบมืออาชีพ:",

    "HA_ITEM_1_TITLE": "สระผม",
    "HA_ITEM_1_CONTENT": "สระผม 1 ครั้ง พร้อมเกาเบาๆ เพื่อป้องกันไม่ให้เกิดแผลบนหนังศีรษะ",
    "HA_ITEM_2_TITLE": "ฟอกสีผม (ด้วยสีที่ต้องการ)",
    "HA_ITEM_2_CONTENT": "ผสมสีฟอกขาว + ออกซิเจน 12 หวีห่างจากโคนผม 1-2 เซนติเมตร พักไว้ประมาณ 30-40 นาที ขึ้นอยู่กับสภาพผม",
    "HA_ITEM_3_TITLE": "สระผม",
    "HA_ITEM_4_CONTENT": "สระผมและนวดผมด้วยครีมนวดผมเพื่อให้ผมนุ่มขึ้นก่อนการไดร์และจัดทรงด้วย WAX",

    "HA_BOTTOM_1": "หมายเหตุ: หากคุณต้องการฟอกสีและทำสีผมต้องห้ามสระผมหรือเกาหนังศีรษะก่อนการฟอกสี เพื่อหลีกเลี่ยงการเกิดแผลบนหนังศีรษะ",
    // "HA_BOTTOM_2": "ไปที่ร้าน 30Shine ที่ใกล้ที่สุด สไตลิสต์ที่มีประสบการณ์สูงจะช่วยเติมเต็มความต้องการของคุณด้วยทรงผมที่หล่อและสีผมที่น่าประทับใจ",
    "HA_BOTTOM_3": "อย่ารอช้ารีบจองตอนนี้เลย! ที่"
}

export const HairRemoval_en = {
    "HA_TITLE": "Men's Hair Bleach Wash",
    "HA_TITLE_SUB": "MEN'S HAIR BLEACH WASH AT 30SHINE",
    "HA_CONTENT_SUB1": "In order to possess bright colored hair such as smoke, platinum blonde, blue color, etc., you have to have your hair bleached 1-2 times to realize your dream hair!",
    "HA_CONTENT_SUB2": "These bright hair colors (smoke, platinum blonde and blue, etc.) are all the rage among teenagers. You are supposed to have your hair bleached once or twice depending on the brightness of your hair. The men's hair bleach wash service of 30Shine guarantees to grant you a desired most trendy hair color!",
    "HA_PRICE": "Price: 100k/1 time of bleaching",

    "HA_CONTENT_TITLE": "Process:",
    "HA_CONTENT_CONTENT": "In order to achieve bright hair color that best suits your face, prior to hair coloring, our skilled and experienced 30Shine's stylists will do hair bleach washing once or twice under strict procedures.",

    "HA_ITEM_1_TITLE": "Gentle hair wash",
    "HA_ITEM_1_CONTENT": "Hair is shampooed once before having bleached. This would be done gently so as not to get your scalp hurt.",
    "HA_ITEM_2_TITLE": "Bleach wash (if needed)",
    "HA_ITEM_2_CONTENT": "Mix with bleach powder with OXY 12, apply bleach from the hair root with the brush. Let it sit for 30-40 minutes depending on the hardness or softness of hair.",
    "HA_ITEM_3_TITLE": "Hair wash",
    "HA_ITEM_4_CONTENT": "Wash and condition hair to make it smooth before drying and styling with wax",


    "HA_BOTTOM_1": "Notice: If you are planning to have your hair bleached or dyed, remember not to wash your hair or scratch the scalp the day before your hair bleaching since it might get hurt when you do bleach wash.",
    // "HA_BOTTOM_2": "",

    "HA_BOTTOM_3": "Book now, getting service without waiting in line at"
}