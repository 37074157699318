import React, { useState, useEffect } from 'react'
import Modal from 'antd/lib/modal/Modal';
import './style.scss'
import DropdownLocation from '../dropdown/DropdownLocation';
import { useTranslation } from 'react-i18next'

const ModalLocation = (props) => {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    const handleCancel = () => {
        props.toggleVisible();
        setVisible(false);
    };

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);

    return (
        <div>
            <Modal
                className="modal-location"
                visible={visible}
                title={t("LIST_STORE")}
                onCancel={handleCancel}
                footer={null}
            >
                <DropdownLocation />
                <div className="hotline"><span>{t("FEEDBACK_HOTLINE")}</span></div>
                <div className="action">
                    <div className="text"><span>HotLine</span></div>
                    <div className="image"><a href="tel:0972765960" >‭0972.765.960</a></div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalLocation;