import React from "react";
import {withTranslation} from "react-i18next";
import {Modal} from "antd";

const ModalOff = (props) => {
    const { t } = props;
    
    return (
        <Modal
            title={false}
            centered
            visible={props.visible}
            onCancel={() => props.setVisible(false)}
            onOk={() => props.setVisible(false)}
            footer={false}
        >
            <div style={{padding: "5px 10px"}}>
                <h2>{t("NOTI")}</h2>
                {t("SALON_OFF_MESSAGE").split("\n").map(line => {
                    return (
                        <p style={{marginBottom: 5}}>{line}</p>
                    )
                })}
            </div>
        </Modal>
    )
}

export default withTranslation()(ModalOff);