import { home_th, home_en } from './Home'
import { kid_en, kid_th } from './Kidcombo'
import { dye_th, dye_en } from './DyeFashion'
import { Curling3_th, Curling3_en } from './HairCurling3'
import { Curling_th, Curling_en } from './HairCurling'
import { Echosline_th, Echosline_en } from './Echosline'
import { HairCare_th, HairCare_en } from './HairCare'
import { Exfoliation_th, Exfoliation_en } from './Exfoliation'
import { CoolShine_th, CoolShine_en } from './CoolShine'
import { HairRemoval_th, HairRemoval_en } from './HairRemoval'

export const services = {
    th: {
        ...home_th,
        ...kid_th,
        ...dye_th,
        ...Curling3_th,
        ...Curling_th,
        ...Echosline_th,
        ...HairCare_th,
        ...Exfoliation_th,
        ...CoolShine_th,
        ...HairRemoval_th,
    },
    en: {
        ...home_en,
        ...kid_en,
        ...dye_en,
        ...Curling3_en,
        ...Curling_en,
        ...Echosline_en,
        ...HairCare_en,
        ...Exfoliation_en,
        ...CoolShine_en,
        ...HairRemoval_en,
    },

}