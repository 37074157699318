
import CONSTANTS from './comp/constants'

const initialState = {
    isLoadding: 0,
    infoUpdate: {},
    dataBanner: [],
    bookingSuccessId: null
}

export const myReducer = (state, aciton) => {
    if (typeof state === 'undefined') {
        return initialState
    }
    switch (aciton.type) {
        case CONSTANTS.ADD_LOADDING:
            return { ...state, isLoadding: state.isLoadding + 1 }
        case CONSTANTS.REMOVE_LOADDING:
            return { ...state, isLoadding: state.isLoadding > 0 ? state.isLoadding - 1 : 0 }
        case CONSTANTS.INFO_UPDATE_BOOKING:
            return { ...state, infoUpdate: aciton.infoUpdate }
        case CONSTANTS.DATA_BANNER:
            return { ...state, dataBanner: aciton.dataBanner }
        case CONSTANTS.SET_BOOKING_SUCCESS_ID:
            return { ...state, bookingSuccessId: aciton.id }
        default:
            return state;
    }
}