export const CoolShine_th = {
    "CO_TITLE": "มาร์คหน้าเย็น",
    "CO_TITLE_SUB": "ตัวช่วยของผิวสำหรับหน้าร้อนนี้",
    "CO_CONTENT_TITLE": "ทำไมต้องใช้?",
    "CO_CONTENT_CONTENT": "หลังจากที่ทำงานหนักตลอดวัน ทำให้คุณมีอาการอ่อนล้าทั้งร่างกายและจิตใจ เหงื่อและความมันที่อุดตันในรูขุมขนทำให้คุณอึดอัดมากขึ้น!",
    "CO_LI_1": "สร้างความสดชื่นและเปล่งประกายให้แก่ใบหน้า",
    "CO_LI_2": "ผ่อนคลายและเพลิดเพลินกับความเย็น",
    "CO_LI_3": "เผยผิวใสสุขภาพดีและเรียบเนียน"
}



export const CoolShine_en = {
    "CO_TITLE": "Apply Cool & Fresh Face Masks",
    "CO_TITLE_SUB": "A life saver for your skin in the summer",
    "CO_CONTENT_TITLE": "Why should you apply face masks?",
    "CO_CONTENT_CONTENT": "After a long hard working day, you are exhausted both physically and mentally. Additionally, that sweat and oil clogged in the pores drives you even more crazy!",
    "CO_LI_1": "Revitalize and brighten up your face.",
    "CO_LI_2": "Relax with the coolness and freshness of the masks.",
    "CO_LI_3": "Bring a smooth and healthy complexion to your face."
}