import React, { Component } from 'react';
import './App.scss';
import Routes from './pages/Routes';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import { myReducer } from './reducer';
import "react-id-swiper/lib/styles/scss/swiper.scss";
import 'antd/dist/antd.css';
import "./i18n";

export const store = createStore(myReducer);
class App extends Component {
  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <Routes />
        </Provider>
      </div>
    );
  }
}

export default App; 
