import axios from 'axios';
// import * as ACITONS from '../actions'
// import { store } from '../App';
const http = require('http');
const https = require('https');
const instance = axios.create({
    // baseURL: 'https://3sgus10dig.execute-api.ap-southeast-1.amazonaws.com/Prod/',
    // baseURL: 'https://std-api-booking.30shine.net/',
    // baseURL: 'https://std-api-booking.30shine.net/',
    baseURL: 'https://y136on3kd3.execute-api.ap-southeast-1.amazonaws.com/Staging',
    // baseURL: 'https://api-booking-truongtn.30shine.net/',
    // //keepAlive pools and reuses TCP connections, so it's faster
    httpAgent: new http.Agent({ keepAlive: true }),
    httpsAgent: new https.Agent({ keepAlive: true }),
})

instance.interceptors.request.use(function (config) {
    // store.dispatch(ACITONS.AddLoadding());
    config.headers = {
        'Accept': 'application/json',
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
    try {
        // store.dispatch(ACITONS.RemoveLoadding());
        if (response.status !== 200)
            return Promise.reject(response.data);
        return response.data;
    } catch (error) {
        return Promise.reject(error);
    }
}, function (error) {
    // store.dispatch(ACITONS.RemoveLoadding());
    return Promise.reject(error);
});

export default instance;