import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import Icon from 'antd/lib/icon/index';
import { useTranslation } from "react-i18next";
import './Menu.scss';
import CONSTANTS from '../../comp/constants'
import { listMenuPc, listMenuMb } from './ListMenu';
import MediaQuery from 'react-responsive';
import DropdownLocation from '../../comp/modalLocation/ModalLocation';
const URL = CONSTANTS.URL_IMAGES;

const icThailand = URL + "thailand.png"
const icEng = URL + "eng.png"
const IconPhone = URL + 'icons8.png'
const IconHome = URL + 'home.png'
const NavItem = props => (
    <Fragment>
        <li className={props.item.class ? `nav-item ${props.item.class}` : 'nav-item'}>
            <Link className="nav-link" to={props.item.url} onClick={props.togleMenu}>
                {props.t(props.item.title)}
            </Link>
        </li>
    </Fragment>
)

const ShowListMenu = props => {

    return (
        props.listMenu.map((item, key) => {
            return (
                (item.subMenu && item.subMenu.length > 0) ?
                    <li key={key} className="nav-item dropdown">
                        <a className="nav-link " href={item.url} id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <Icon className="icon-menu" type={item.img} /> &nbsp; {item.title} </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                            {item.subMenu.length > 0 && item.subMenu.map((item, key) => {
                                return (
                                    <Link className="dropdown-item" key={key} to={item.url} onClick={props.togleMenu}>
                                        <Icon className="icon-menu" type="minus" /> &nbsp;
                                    <span>{props.t(item.title)} </span></Link>
                                )
                            })}

                        </div>
                    </li>
                    :
                    <NavItem key={key} item={item} togleMenu={props.togleMenu} t={props.t} />
            )
        })
    )
}

const Menu = props => {
    const { t, i18n } = useTranslation();
    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        setVisible(!visible);
    }

    const togleMenu = () => {
        props.togleMenu(false);
    }
    const changeLanguage = lng => {
        i18n.changeLanguage(lng);
    };
    const drawerClasses = props.show ? 'navbar open' : 'navbar'
    return (
        <div className={drawerClasses}>
            <ul className="navbar-nav">
                <li className="icon-close" data-toggle="navbar-nav" onClick={togleMenu}><Icon type="close-square" />
                    <span>Menu</span>
                </li>
                <MediaQuery minWidth={768}>
                    <li className="nav-item dropdown icon-left">
                        <Link className="icon-home" to="/"><img src={IconHome} alt="Icon" style={{ marginBottom: '4px' }} /></Link>
                        &nbsp;
                        {/*<img src={IconPhone} onClick={() => setVisible(true)} style={{ cursor: 'pointer', width: '24px' }} alt="Icon" />*/}
                    </li>
                    <ShowListMenu listMenu={listMenuPc} togleMenu={togleMenu} t={t} />
                    <li className="nav-item icon-right">
                        <Locale changeLanguage={changeLanguage} language={i18n.language} />
                    </li>

                    <DropdownLocation visible={visible} toggleVisible={toggleVisible} />

                </MediaQuery>
                <MediaQuery maxWidth={767}>
                    <ShowListMenu listMenu={listMenuMb} togleMenu={togleMenu} t={t} />
                    < li className="nav-item" >
                        <div className="nav-locale">
                            {t("LANGUAGE")}: <Locale changeLanguage={changeLanguage} language={i18n.language} />
                        </div>
                    </li >
                </MediaQuery>
            </ul>
        </div>
    );

}

export default Menu;

const Locale = _ => {
    const [isEnd, setIsEnd] = useState(_.language.includes("en") ? true : false);

    const onClick = (value) => {
        setIsEnd(!isEnd)
        _.changeLanguage(value);
    }

    return (
        <div className="image-locale">

            {isEnd ?
                <div onClick={() => onClick("th")}> <img src={icThailand} alt="Icon" /> <span>TH</span></div>
                :
                <div onClick={() => onClick("en")}> <img src={icEng} alt="Icon" /> <span>EN</span></div>
            }
        </div>
    )
}
