import React, { Fragment, useState } from 'react';
import MediaQuery from 'react-responsive';
import Icon from 'antd/lib/icon/index';
import './style.scss';
import { Swiper } from 'swiper/dist/js/swiper.esm'
import ReactIdSwiperCustom from 'react-id-swiper/lib/ReactIdSwiper.custom';

const Content = props => {
    const [swiper, updateSwiper] = useState(null);

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext();
        }
    };

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev();
        }
    };

    return (
        <div className="my-swiper">
            <ReactIdSwiperCustom Swiper={Swiper} {...props} getSwiper={updateSwiper} >
                {props.children}
            </ReactIdSwiperCustom>
            {props.pc && !props.notNavigation &&
                <Fragment>
                    <div className="btn-action left"><Icon type="left" onClick={goPrev} /></div>
                    <div className="btn-action right"><Icon type="right" onClick={goNext} /></div>
                </Fragment>
            }
        </div>
    );
}

const MySwiper = (props) => {

    return (
        <Fragment>
            <MediaQuery minWidth={1024}>
                <Content slidesPerView={props.large ? props.large : 4.25} {...props} pc={true} />
            </MediaQuery>
            <MediaQuery minWidth={768} maxWidth={1023}>
                <Content slidesPerView={props.medium ? props.medium : 3.25} {...props} pc={true} />
            </MediaQuery>
            <MediaQuery maxWidth={767} >
                <Content slidesPerView={props.small ? props.small : 2.25} {...props} pc={false} />
            </MediaQuery>
        </Fragment>
    );
}

export default MySwiper;