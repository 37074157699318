import React from 'react';
import CONSTANTS from '../../../../comp/constants';
import Icon from 'antd/lib/icon/index';
import LazyLoad from 'react-lazyload/lib/index';
import Skeleton from 'antd/lib/skeleton/index';
import { withTranslation } from 'react-i18next';
import { getTopLike } from '../../../../utils/CommomServices';
import './style.scss';
import $ from "jquery";
const src = '/style-master';
const iconLogo = CONSTANTS.URL_IMAGES + 'Trangchu/logo.png';

const ItemIcon = props => (
    <div className="item">
        <a href={src} target="_blank" rel="noopener noreferrer">
            <img className="logo-hair-hot" src={iconLogo} alt="Icon"></img>
            <LazyLoad throttle={200} height={250} once placeholder={<Skeleton />} >
                <img className="image img-hair-hot" src={props.img_1} alt="Icon"></img>
            </LazyLoad >
            {/* <div className="like">
                <span className="icon"><Icon type="like" /></span>
                <span>{props.totalLikes} &nbsp; {props.t("LIKE")}</span>
            </div> */}
        </a>
    </div>
)

class HairHot extends React.Component {
    state = {
        data: []
    }

    componentWillMount() {
        getTopLike().then((data) => {
            if (data.length > 0) {
                this.setState({ data });
            }
        }).catch(() => { });
    }

    componentDidMount() {
        $(window).resize(function () {
            let w = $(".img-hair-hot").width();
            const height = w * 1.35;
            let selected = $(".img-hair-hot");
            selected.height(height);
        })
    }

    render() {
        const data = this.state.data;
        const { t } = this.props;
        return (
            <div className="hair-hot">
                <div className="header" >
                    {t("TOP_HAIR_HOT_IN_MONTH")}
                </div>

                <div className="hair-iamge">
                    {data.map((item, key) => {
                        return (<ItemIcon {...item} key={key} t={t} />);
                    })}
                </div>

                <a href="/style-master" target="_blank" rel="noopener noreferrer"
                    className="add-show">
                    <span>{t("SEE_MORE")}</span>
                    <span><Icon type="down" /></span>
                </a>
            </div>
        );
    }
}

export default withTranslation()(HairHot);