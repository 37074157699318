export const ModalCheckBooking = {
    en: {
        "SENT": "Sent",
        "SENT_OTP_SUCCESS": "รหัสส่งให้คุณเรียบร้อยกรุณาตรวจสอบทางโทรศัพท์อีกครั้งและป้อนรหัสใหม่",
        "INPUT_OTP_SMS": "ป้อนรหัสยืนยันจากโทรศัพท์",
        "OTP_ERROR": " รหัส OTP ไม่ถูกต้อง",
        "CONFIRM": "ยืนยัน",
        "CHECK_BOOK": "Check book",
        "SEE": "See",
        "PLEASE_CLICK": "คุณกดใหม่อีกครั้ง",
        "SENT_OTP_AGAIN": "ส่งรหัสอีกครั้ง",
        "IF_NULL_OTP": " ถ้าไม่ได้รับข้อความจาก SMS",
        "CHECK_PHONE": "กรุณาป้อนหมายเลขโทรศัพท์ถูกต้อง",
        "SENT_IMFOMATION": "ทางเราส่งข้อมุนคิวจอง เข้าเบอร์โทรโทรศัพท์เรียบร้อยแล้วค่ะ",
        "YOU_RECHECK": "คุณตรวจสอบอีกครั้ง",
        "SENT_AGAIN": "ส่งใหม่อีกครั้ง",
        "ONCHANGE": "Change",
        "NOT_BOOKING": "คุณค่าทางเรายังไม่เห็นคิวจองของคุณ",
        "BOOKING_HERE": "คุณจองคิวที่นี่นะคะ",
        "FOR_US": "ให้ทางเรา",
        "ATTENTIVE SERVICE": "ดูแลคุณดีที่สุดค่ะ",
        "INPUT_OTP": " ป้อนรหัส OTP",
        "PLEASE_AGAIN_INPUT": "กรุณาป้อนรหัส opt อีกครั้งค่ะ",
        "SELECT_SALON": "เชิญคุณเลือกซาลอนที่ต้องการ",
        "SALON_SUGESSION": " คุณสามารถเลือกซาลอนที่เรามาแนะนำ หรือซาลอนอื่นแทน",
        "NOT_FOMAT_PHONE": "เบอร์โทรศัพท์ไม่ถูกต้องกรุณาป้อนอีกครั้ง",
    },
    th: {
        "SENT": "ส่ง",
        "SENT_OTP_SUCCESS": "รหัสส่งให้คุณเรียบร้อยกรุณาตรวจสอบทางโทรศัพท์อีกครั้งและป้อนรหัสใหม่",
        "INPUT_OTP_SMS": "ป้อนรหัสยืนยันจากโทรศัพท์",
        "OTP_ERROR": " รหัส OTP ไม่ถูกต้อง",
        "CONFIRM": "ยืนยัน",
        "CHECK_BOOK": "ตรวจสอบการจอง",
        "SEE": "ดู",
        "PLEASE_CLICK": "คุณกดใหม่อีกครั้ง",
        "SENT_OTP_AGAIN": "ส่งรหัสอีกครั้ง",
        "IF_NULL_OTP": " ถ้าไม่ได้รับข้อความจาก SMS",
        "CHECK_PHONE": "กรุณาป้อนหมายเลขโทรศัพท์ถูกต้อง",
        "SENT_IMFOMATION": "ทางเราส่งข้อมุนคิวจอง เข้าเบอร์โทรโทรศัพท์เรียบร้อยแล้วค่ะ",
        "YOU_RECHECK": "คุณตรวจสอบอีกครั้ง",
        "SENT_AGAIN": "ส่งใหม่อีกครั้ง",
        "ONCHANGE": "เปลี่ยนแปลง",
        "NOT_BOOKING": "คุณค่าทางเรายังไม่เห็นคิวจองของคุณ",
        "BOOKING_HERE": "คุณจองคิวที่นี่นะคะ",
        "FOR_US": "ให้ทางเรา",
        "ATTENTIVE SERVICE": "ดูแลคุณดีที่สุดค่ะ",
        "INPUT_OTP": " ป้อนรหัส OTP",
        "PLEASE_AGAIN_INPUT": "กรุณาป้อนรหัส opt อีกครั้งค่ะ",
        "SELECT_SALON": "เชิญคุณเลือกซาลอนที่ต้องการ",
        "SALON_SUGESSION": " คุณสามารถเลือกซาลอนที่เรามาแนะนำ หรือซาลอนอื่นแทน",
        "NOT_FOMAT_PHONE": "เบอร์โทรศัพท์ไม่ถูกต้องกรุณาป้อนอีกครั้ง",
    }
}