import React, { useState, useEffect, useRef } from 'react'

const DropdownCustom = _ => {
    const _isMouter = useRef(true)

    const [visible, setVisible] = useState(false);

    const showMenu = () => {
        if (!visible) document.addEventListener('click', closeMenu);
        setVisible(!visible);
    }

    const closeMenu = () => {
        if (_isMouter.current) setVisible(false);
        document.removeEventListener('click', closeMenu);
    }


    useEffect(() => {
        return () => {
            _isMouter.current = false;
        }
    }, [])

    return (
        <div className="dropdown-custom">
            <div className="btn-custom" onClick={showMenu}>{_.text}</div>
            {visible && _.children}
        </div>
    )
}

export default DropdownCustom;