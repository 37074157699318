export const Summer_remix_en = {
    "SUMMER_TEXT_1": "Summertime is ideal for travel, for endless outdoor activities and vibrant music. As such, your summer would not be fulfilled without a trendy hairstyle in luxurious colors.",
    "SUMMER_TEXT_2": "SUMMER REMIX men's hair collection includes short hairstyles that are youthful and dynamic, breaking all monotonous concepts of daily life. We offer up to 3 styles for you to choose.",
    "SUMMER_TEXT_3": "Get high style",
    "SUMMER_TEXT_4": "Sporty-looking spiky hairstyles",
    "SUMMER_TEXT_5": "Spiky or sporty hairstyle always makes men's faces more youthful and dynamic. This summer, sporty-looking crew cut with spikes is on the top list of all men.",
    "SUMMER_TEXT_6": "Mohican hairstyle \"cheats\" men's height",
    "SUMMER_TEXT_7": "Mohican continues to create a fever this summer owing to its huge advantages: it is not only fashionable and cool but also helps men cheat 3-4 cm height thanks to those volumized spikes. ",
    "SUMMER_TEXT_8": "In addition, Mohican is one-size-fits-all hairstyle which can be applied to many face shapes, ranging from round, long, v-line and square, etc. ",
    "SUMMER_TEXT_9": "Dashing Layered hair",
    "SUMMER_TEXT_9_1": "Hair trends this summer are varied. Beside 2 super cool hairstyles listed above, we also offer a Korean-looking hairstyle named layered hair.",
    "SUMMER_TEXT_10": "Add some highlights for a burning summer",
    "SUMMER_TEXT_11": "Highlight is the stye where some strands or areas of hair with lighter color than the natural look of your hair. If you want to have unique hair color, add some highlights to one of these 3 hairstyles listed above.",
    "SUMMER_TEXT_12": "This summer's highlight colors are exquisite 3D and 4D versions which give more depth to your hair look. Walking on the street or strolling along the beach with highlighted hair definitely makes you become a center of attention. ",
    "SUMMER_TEXT_13_1": "A vibrant summer awaiting, change your look now with SUMMER REMIX Collection to be ready for all fun!",
    "SUMMER_TEXT_13_2": "Visit 30Shine now to experience Summer Remix collection and 7-steps Shine Combo:",

    "SUMMER_TEXT_14_1": "Facial wash",
    "SUMMER_TEXT_14_2": "Skin care",
    "SUMMER_TEXT_14_3": "Hair shampooing & scalp massage",
    "SUMMER_TEXT_14_4": "Advisory",
    "SUMMER_TEXT_14_5": "Haircut",
    "SUMMER_TEXT_14_6": "Post haircut wash",
    "SUMMER_TEXT_14_7": "Hairstyling with wax",
    "SUMMER_TEXT_15": "Free hair correction within 7 days, discount offered for long waiting time...warranty services to be found nowhere else but 30Shine.",
    "SUMMER_TEXT_16": "30SHINE IS THE BIGGEST HAIR SALON CHAIN ​​WITH MORE THAN 60 SALONS THROUGHOUT THE COUNTRY. OUR MODERN BOOKING TECHNOLOGY, ADVANCED HAIRCUT TECHNIQUES AND SUPER CARING SERVICES  WILL DEFINITELY MAKE YOU SHINE LIKE A STAR. WANNA SHINE - VISIT 30SHINE"
}



export const Summer_remix_th = {
    "SUMMER_TEXT_1": "ฤดูร้อนเป็นฤดูกาลของการเดินทาง ความสนุกไม่รู้จบกับดนตรีที่มีชีวิตชีวา ดังนั้นแน่นอนว่าฤดูร้อนของคุณจะไม่สนุกหากไม่มีทรงผมและสีผมที่ทันสมัย",
    "SUMMER_TEXT_2": "คอลเล็กชั่นทรงผมผู้ชาย SUMMER REMIX นั้นรวมถึงทรงผมสั้นที่มีความอ่อนเยาว์และมีความกระฉับกระเฉง ทำลายความน่าเบื่อในชีวิตประจำวัน เรามีถึง 3 สไตล์ให้คุณเลือก",
    "SUMMER_TEXT_3": "Get high style",
    "SUMMER_TEXT_4": "____",
    "SUMMER_TEXT_5": "ทรงผมที่จัดทรงสูงมักจะทำให้ใบหน้าของผู้ชายดูอ่อนเยาว์และมีชีวิตชีวามากขึ้น ฤดูร้อนนี้ทรงผม Sport เป็นทรงผมที่ตัดสั้นจัดทรงสูงเป็นทรงผมที่คนนิยมเลือกเป็นอันดับ 1",
    "SUMMER_TEXT_6": "ทรง Mohican \"โกง\" ความสูง",
    "SUMMER_TEXT_7": "Mohican ยังคงเป็นที่นิยมในฤดูร้อนนี้เนื่องมีจากข้อได้เปรียบใหญ่ๆ คือ แฟชั่นและเอกลักษณ์เฉพาะตัวโดยเฉพาะอย่างยิ่งช่วยให้ผู้ชายโกงความสูงได้ถึง 3-4 ซม.เพราะการจัดทรงสูง",
    "SUMMER_TEXT_8": "ไม่เพียงแค่นั้น Mohican ยังเหมาะสมกับใบหน้าที่หลากหลายเช่น กลม ยาว  v-line เหลี่ยม ... ต่างก็สวย",
    "SUMMER_TEXT_9": "ทรงผม Layer แสนเท่",
    "SUMMER_TEXT_9_1": "เทรนด์ผมฤดูร้อนปีนี้ยังเป็นที่ชื่นชอบของชายหนุ่ม นอกเหนือจากทรงผมที่เด็ดสุดๆ ทั้ง 2 ข้างต้นแล้วยังมีทรงผมสไตล์เกาหลีที่เรียกว่า Layer",
    "SUMMER_TEXT_10": "เพิ่มไฮไลต์นิดหน่อยเพื่อความร้อนแรงในฤดูร้อนนี้",
    "SUMMER_TEXT_11": "ไฮไลต์ผมคือผมบางส่วนที่มีสีต่างจากสีของส่วนผมที่เหลือ ทรงผมทั้ง 3 แบบด้านบนนั้นจะน่าประทับใจยิ่งขึ้นเมื่อคุณ \"เล่น\" ไฮไลต์กับทรงผมเหล่านั้นรับรองได้ว่าคุณจะดูไม่เหมือนใคร",
    "SUMMER_TEXT_12": "สีไฮไลต์ของฤดูร้อนนี้เป็นรุ่น 3D และ 4D ที่ยอดเยี่ยมซึ่งจะให้ความลึกกับทรงผมของคุณมากขึ้น การเดินบนถนนหรือเดินเล่นไปตามชายหาดในฤดูร้อนนี้กับสีผมไฮไลต์แน่นอนว่าจะทำให้คุณกลายเป็นที่สนใจของทุกคน",
    "SUMMER_TEXT_13_1": "ฤดูร้อนที่น่าตื่นเต้นรอคุณอยู่ เปลี่ยนลุคของคุณตอนนี้กับคอลเล็กชั่น SUMMER REMIX ของ 30Shine เพื่อเตรียมพร้อมสำหรับความสนุกที่กำลังจะมาถึง!",
    "SUMMER_TEXT_13_2": "มาที่ 30Shine ทันทีเพื่อลองทำผมตามคอลเลคชั่นทรงผมสำหรับผู้ชายของ Summer Remix และ Shine Combo 7 ขั้นตอน:",
    "SUMMER_TEXT_14_1": "ล้างหน้า",
    "SUMMER_TEXT_14_2": "ดูแลผิว",
    "SUMMER_TEXT_14_3": "สระผมและนวดหนังศีรษะ",
    "SUMMER_TEXT_14_4": "ให้คำแนะนำ",
    "SUMMER_TEXT_14_5": "ตัดผม",
    "SUMMER_TEXT_14_6": "สระผม",
    "SUMMER_TEXT_14_7": "จัดทรงด้วย WAX",
    "SUMMER_TEXT_15": "แก้ไขทรงผมฟรีภายใน 7 วัน ลดราคาทันทีหากคุณต้องรอนาน ... บริการรับประกันที่ไม่มีที่ไหนกล้าทำยกเว้นที่ 30Shine",
    "SUMMER_TEXT_16": "30SHINE เป็นร้านทำผมที่ใหญ่ที่สุดมีร้านในเครือมากกว่า 60 ร้านทั่วประเทศ ด้วยเทคโนโลยีการจองที่ทันสมัยของเรา เทคโนโลยีการตัดผมขั้นสูงและบริการการดูแลระดับ 5 ดาวจะทำให้คุณโดดเด่นราวกับเป็นดารา อยากหล่อเปล่งประกาย - ต้องมาที่ 30SHINE "
}
