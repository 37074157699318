import React, {Suspense, useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LazyLoad from 'react-lazyload/lib/index';
import Skeleton from 'antd/lib/skeleton/index';
import Home from './home/Home';
import Footer from '../comp/footer/Footer';
import BoxHeader from '../comp/box-header/Boxheader'
import { Modal } from "antd";
import ModalOff from "../comp/ModalOff";

const Booking = React.lazy(() => import('./booking/Booking'));
const BookingSale = React.lazy(() => import('./booking/BookingSale'));
const BookingSuccess = React.lazy(() => import('./booking-success/BookingSuccess'));
const About = React.lazy(() => import('./about/About'));
const Services = React.lazy(() => import('./sevices/Services'));
const OtherServices = React.lazy(() => import('./orther-services/OtherServices'));
const Security = React.lazy(() => import('../pages/security/Sevurity'));
// const ShinMember = React.lazy(() => import('../pages/shinmember/ShinMember'));
const LookBook = React.lazy(() => import('../pages/lookbook/LookBook'));
const Recruitment = React.lazy(() => import('../pages/recruitment/Recruitment'));
const Page404 = React.lazy(() => import('../pages/not_foud/pageNotFoud'));
const StyleMaster = React.lazy(() => import('./style_master/StyleMaster'));

const Routes = () => {
    const [visible, setVisible] = useState(true);

    return (
        <Router>
            <div id="content">
                <BoxHeader />
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/booking/success" component={WaitingComponent(BookingSuccess)} />
                    <Route path="/booking/salon" component={WaitingComponent(BookingSale)} />
                    <Route exact path="/booking/:phone" component={WaitingComponent(Booking)} />
                    <Route exact path="/about" component={WaitingComponent(About)} />
                    <Route exact path="/services" component={WaitingComponent(Services)} />
                    <Route path="/other-service" component={WaitingComponent(OtherServices)} />
                    <Route exact path="/security" component={WaitingComponent(Security)} />
                    {/* <Route exact path="/shine-member" component={WaitingComponent(ShinMember)} /> */}
                    <Route path="/lookbook" component={WaitingComponent(LookBook)} />
                    <Route exact path="/recruitment" component={WaitingComponent(Recruitment)} />
                    <Route exact path="/style-master" component={WaitingComponent(StyleMaster)} />
                    <Route component={Page404} />
                </Switch>
                <Footer />
            </div>
            <ModalOff visible={visible} setVisible={setVisible}/>
        </Router>
    );
}

export default Routes;

function WaitingComponent(Component) {
    return props => (
        <Suspense fallback={<Skeleton paragraph={{ rows: 18 }} />}>
            <Component {...props} />
        </Suspense>
    );
}