export const Exfoliation_th = {
    "EX_TITLE": "ผลัดเซลล์ผิว",
    "EX_TITLE_SUB": "ผลัดเซลล์ผิวที่ตายแล้วเผยผิวใสสุขภาพดีให้แก่ผิวของผู้ชาย!",
    "EX_CONTENT_TITLE": "ทำไมต้องใช้?",
    "EX_CONTENT_CONTENT": "ผิวของผู้ชายมักจะมีเหงื่อและความมันมากโดยเฉพาะในวันที่อากาศร้อน เซลล์ผิวที่ตายแล้วสะสมเป็นเวลานานทำให้ง่ายต่อการเกิดสิวและทำให้ผิวหมองคล้ำ!",
    "EX_LI_1": "ผลัดเซลล์ผิวที่ตายแล้ว สิ่งสกปรก ความมันบนใบหน้าหลังจากใช้งาน 1 ครั้ง",
    "EX_LI_2": "ลดความเสี่ยงของการเกิดสิวถึง 90%",
    "EX_LI_3": "ปรับผิวให้ขาวกระจ่างใส เนียนนุ่ม ลดริ้วรอย"
}



export const Exfoliation_en = {
    "EX_TITLE": "FACIAL EXFOLIATION",
    "EX_TITLE_SUB": "Removing dead skin cells makes men's faces look brighter and healthier!",
    "EX_CONTENT_TITLE": "Why should men do exfoliation?",
    "EX_CONTENT_CONTENT": "Men's skins often give out much sweat and oil, especially in hot weather. Dead skin cells are built up overtime, leading to breakouts and swarthy skin!",
    "EX_LI_1": "Remove all dead skin cells, dirt and oil after one step.",
    "EX_LI_2": "Reduce the risk of breakouts up to 90%.",
    "EX_LI_3": "Brighten and smoothen your skin, reduce wrinkles."
}