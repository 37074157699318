export const home_th = {
    "SV_HOME_HEADER_TTILE_1": "Shine Combo 7 ขั้นตอน",
    SV_HOME_HEADER_CONTENT_1: "",
    "SV_HOME_HEADER_TTILE_2": "เพลิดเพลินและผ่อนคลายไปกับ Shine Combo 7 ขั้นตอน",
    "SV_HOME_HEADER_CONTENT_2_1": "ผ่อนคลายจากความร้อนที่ทำให้เราเหนื่อยล้ากับ Shine Combo 7 ขั้นตอนที่ 30Shine เพียง 349฿ ผ่อนคลายอย่างเต็มที่แล้วคุณจะดูดียิ่งขึ้น!",
    "SV_HOME_HEADER_CONTENT_2_2": "ให้สไตลิสต์และ สาวสกินเนอร์ ช่วยทำให้คุณดูดีและเพลิดเพลินไปกับช่วงเวลาที่ผ่อนคลายหลังจากเหนื่อยล้ามาทั้งวัน!",
    "SV_HOME_ITEM_TITLE_1": "ทำความสะอาดผิวหน้า",
    "SV_HOME_ITEM_TITLE_2": "ดูแลผิวหน้าด้วยเทคโนโลยีขั้นสูง",
    "SV_HOME_ITEM_TITLE_3": "สระผมนวดศีรษะและกดจุด",
    "SV_HOME_ITEM_TITLE_4": "ให้คำแนะนำและออกแบบทรงผมให้เหมาะสมกับแต่ละบุคคล",
    "SV_HOME_ITEM_TITLE_5": "ตัดผมโดยสไตลิสต์ชั้นนำ",
    "SV_HOME_ITEM_TITLE_6": "โกนหนวด - สระผม",
    "SV_HOME_ITEM_TITLE_7": "จัดทรงด้วย WAX และสเปรย์คุณภาพสูง",
    "SV_HOME_ITEM_CONTENT_1": "ขขั้นตอนการทำความสะอาดผิวหน้าได้รับการออกแบบเป็นพิเศษสำหรับผู้ชายเพื่อล้างสิ่งสกปรกและเซลล์ที่ตายแล้วออกเพื่อให้คุณมีผิวที่สะอาดสุขภาพดีไม่เหนียวเหนอะหนะ มือที่นุ่มนวลของ สาวสกินเนอร์ จะนวดไปทั่วใบหน้าจากหูถึงด้านหลังคอเพื่อช่วยให้ผ่อนคลายอย่างเต็มที่",
    "SV_HOME_ITEM_CONTENT_2": "การลอกสิวเสี้ยนด้วยอุปกรณ์พิเศษเฉพาะที่ 30Shine ผิวหน้าที่บอบบางจะได้รับการดูแลและให้ความชุ่มชื้นด้วยสารสกัดจากกุหลาบที่พบในสปาระดับไฮเอนด์เท่านั้น ช่วยให้คุณเป็นเจ้าของใบหน้าที่สดใส นุ่มนวลและดึงดูดผู้หญิง",
    "SV_HOME_ITEM_CONTENT_3": "ผ่อนคลายไปกับความเย็นที่กระจายไปทั่วศีรษะ ด้วยการนวดที่ชำนาญของ สาวสกินเนอร์ จะนวดและกดจุดบนศีรษะทำให้คุณรู้สึกผ่อนคลายและไร้กังวลในทุกปัญหาของชีวิต",
    "SV_HOME_ITEM_CONTENT_4": "สไตลิสต์ที่ 30Shine จะแนะนำทรงผมที่เหมาะกับลักษณะเฉพาะตัวของผู้ชาย (ใบหน้า, สภาพผม ... ) ตามสไตล์ผม BST ทรงผมที่เป็นที่นิยมในปี 2562",
    "SV_HOME_ITEM_CONTENT_5": "20 ปีที่รักษาทรงผมที่น่าเบื่อก็ถึงเวลาที่คุณจะเปลี่ยนทรงผมของคุณ โดยทีมสไตลิสต์มืออาชีพชาวเวียดนามที่ให้ความมั่นใจกับคุณว่าทรงผมที่คุณจะได้รับเกินความคาดหมายแน่นอน",
    "SV_HOME_ITEM_CONTENT_6": "โกนหนวดเพื่อช่วยให้ผิวเรียบเนียน การล้างทำความสะอาดและปรับสภาพเส้นผมหลังจากการตัดผมทำให้คุณดูหล่ออย่างสมบูรณ์ก่อนจะไปเดทที่สำคัญ หมดกังวลว่าเศษผมติดตามคอเพราะมีกระดาษพันคอพิเศษจาก 30Shine ไม่ต้องสระผมอีกครั้งเมื่อกลับบ้าน",
    "SV_HOME_ITEM_CONTENT_7": "30Shine ใช้ wax และสเปรย์พรีเมี่ยม 100% เพื่อให้ผมสมบูรณ์แบบแม้ว่าคุณจะสวมหมวกก็ตาม  WAX คุณภาพสูงช่วยให้ผมแข็งแรงไม่เกิดรังแคและแตกปลายเมื่อเทียบกับ WAX ทั่วไป",
}

export const home_en = {
    "SV_HOME_HEADER_TTILE_1": "7-Step Shine Combo",
    SV_HOME_HEADER_CONTENT_1: "",
    "SV_HOME_HEADER_TTILE_2": "Enjoy The Relaxing 7-Step Shine Combo",
    "SV_HOME_HEADER_CONTENT_2_1": "Say goodbye to the exhaustion of summer time with new 7 steps of Shine Combo only at 30Shine. Only with 349฿ for the full combo package and you will be in total relaxation and perfectly handsome!",
    "SV_HOME_HEADER_CONTENT_2_2": "Let our professional stylists and gorgeous Skinner Girls help you enjoy every relaxing moment after a tiring day at work and make you shine like a star!",
    "SV_HOME_ITEM_TITLE_1": "Facial washing And Oil Removing",
    "SV_HOME_ITEM_TITLE_2": "High-Tech Facial Skin Care",
    "SV_HOME_ITEM_TITLE_3": "Hair Shampooing, Head Acupressure And Massage",
    "SV_HOME_ITEM_TITLE_4": "Hairstyle Consultation Fitting Your Face",
    "SV_HOME_ITEM_TITLE_5": "Stylist Haircut By Top Rated Stylists",
    "SV_HOME_ITEM_TITLE_6": "Smooth Facial Shaving - Clean Hair Wash",
    "SV_HOME_ITEM_TITLE_7": "Hair Styling With Wax/Spray",
    "SV_HOME_ITEM_CONTENT_1": "The face wash process is specifically designed for men to wash away dirt & dead cells, bringing about a clean, healthy, non-oily skin for them. Our Skinner Girl with soft hands gently massage all over your face, stroke from the ear to the back of the neck to help you relax and regain your vitality.",
    "SV_HOME_ITEM_CONTENT_2": "Peeling off blemishes by exclusive equipment only available at 30Shine. Sensitive facial skin will be moisturized by rose essence only found in high-end Spa, helping boys own bright, smooth  and woman-attracting faces.",
    "SV_HOME_ITEM_CONTENT_3": "Enjoy the cool water showering on your head. With the skillful massage and acurpressure practices of our Skinner girls, you will be lost in relaxation and unmindful of every trouble in life.",
    "SV_HOME_ITEM_CONTENT_4": "Our 30Shine's stylists will advise you on choosing the hairstyle that best suits your face shape or hair quality among styles presented in the 30Shine's 2562 exclusive collection of men's hairstyles.",
    "SV_HOME_ITEM_CONTENT_5": "For 20 years keeping a monotonous hairstyle, it is time for you to change your hair look. Our professional Vietnamese stylists pride ourselves in granting you a hairstyle beyond your expectation.",
    "SV_HOME_ITEM_CONTENT_6": "Smooth facial shaving helps tone up the skin. Clean washing and hair conditioning after haircut makes you look perfectly handsome before any important appointment. With 30Shine's special neck paper, the worries of small locks of hair or home hair wash have disappeared.",
    "SV_HOME_ITEM_CONTENT_7": "30Shine uses 100% premium hair wax and spray to keep it perfectly in-place even when you wear a helmet. High-grade wax helps hair avoid dandruff and breakage compared to normal types.",
}
