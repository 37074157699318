
export const CONSTANTS = {
    ADD_LOADDING: 'ADD_LOADDING',
    REMOVE_LOADDING: 'REMOVE_LOADDING',
    INFO_UPDATE_BOOKING: 'INFO_BOOKING',
    SET_BOOKING_SUCCESS_ID: 'SET_BOOKING_SUCCESS_ID',
    DATA_BANNER: 'DATA_BANNER',
    URL_IMAGES: 'https://storage.30shine.co.th/web/data/images/',
    LIST_PHONE: ['086', '086', '096', '097', '098', '032', '033', '034', '035', '036', '037', '038', '039',
        '091', '094', '088', '083', '084', '085', '081', '082',
        '089', '090', '093', '070', '079', '077', '076', '078',
        '092', '056', '058', '099', '059']
}

export default CONSTANTS;