import React from 'react';
// import ListStore from './liststore/ListStore';
// import ShineMember from './ShineMember';
import MenuService from './menuservice/MenuService';
import HairHot from './hairhot/HairHot';
// import StarVietNam from './starvietnam.js/StarVietNam';
// import ImgDownLoadApp from './ImgDownLoadApp';
import Fashion30Shine from './fashion30shine.js/Fashion30Shine';
// import ShineTV from './30shineTV/30ShineTV';
// import HotBoy from './hotboy/HotBoy';
import ShinCare from './shinCare/ShinCare';
import Skeleton from 'antd/lib/skeleton/index';
import LazyLoad from 'react-lazyload/lib/index';

import './style.scss';

const Container = () => {
    return (
        <div className="container">
            <MenuService />

            {/* <ShineMember /> */}

            {/* <LazyLoad height={300} placeholder={<Skeleton />}>
                <HotBoy />
            </LazyLoad> */}

            {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}> */}
                <HairHot />
            {/* </LazyLoad> */}

            {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <StarVietNam />
            </LazyLoad> */}

            {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <ImgDownLoadApp />
            </LazyLoad> */}

            <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <Fashion30Shine />
            </LazyLoad>

            {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <ShineTV />
            </LazyLoad> */}

            {/* <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <ListStore />
            </LazyLoad> */}

            <LazyLoad throttle={200} height={300} placeholder={<Skeleton />}>
                <ShinCare />
            </LazyLoad>
        </div>
    )
}

export default Container
