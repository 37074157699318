export const kid_th = {
    "KID_HEADER_TITLE": "รับหล่อเหมือนพ่อเหมือนลูกชายกับ Kid combo 80k ที่ 30shine",
    "KID_HEADER_TITLE_SUB": "KID COMBO 5 ขั้นตอน",
    "KID_HEADER_CONTENT": "หากคุณมี Shine Combo 100K ลูกชายของคุณก็จะได้รับบริการ Kid Combo 5 ขั้นตอนเพียง 80K สำหรับเด็กผู้ชายที่มาที่ 30Shine เพื่อให้ทั้งพ่อและลูกชายหล่อเหลาและเปล่งประกาย (เด็กสูงไม่เกิน 1.3 เมตร)",
    "KID_1_TITLE": "กระบวนการ",
    "KID_1_CONTENT": "สไตลิสต์ที่ 30Shine จะออกแบบทรงผมที่หล่อเหลาให้คุณลูกชายดูดีเหมือนคุณพ่อ แต่ยังคงเหมาะสมกับวัยที่น่ารักโดยมีกระบวนการ 5 ขั้นตอนพิเศษจาก 30Shine:",
    "KID_1_B1": "ขั้นตอนที่ 1 / สระผมให้นุ่มและสะอาด",
    "KID_1_B2": "ขั้นตอนที่ 2 / ให้คำแนะนำและออกแบบทรงผมที่เหมาะสมกับวัยและบุคลิกภาพของแต่ละคน",
    "KID_1_B3": "ขั้นตอนที่ 3 / ตัดผมและจัดทรง",
    "KID_1_B4": "ขั้นตอนที่ 4 / สระผมให้สะอาดทำให้เด็กรู้สึกสบายตลอดวัน",
    "KID_1_B5": "ขั้นตอนที่ 5 / จัดทรงด้วย wax",
    "KID_2_TITLE": "ทรงผมเด็กยอดนิยม  Kid Combo",
    "KID_2_CONTENT_TOP": "ดูน่ารักแต่ยังคงมีความเป็น “ผ้ใหญ่” จาก “ลูกค้าตัวน้อย” ของ 30Shine:",
    KID_2_CONTENT_BOTTOM: "คุณอยากให้ลูกของคุณมีทรงผมที่ดูเรียบร้อยไหม? <br/>คุณอยากให้ลูกของคุณหล่อเหมือนพ่อไหม?<br/>คุณอยากให้ลูกของคุณทั้งดูน่ารักและดูเป็นผู้ใหญ่ไปพร้อมกันไหม?",
    KID_LINK: "จองคิวตัดผมที่ร้าน 30Shine ใกล้บ้านคุณ เพื่อที่ทั้งคุณพ่อและคุณลูกชายจะได้มีทรงผมที่ดูดีเหมือนกัน! <br/>อย่ารอช้าจองคิวตัดผมกับ 30shine ที่นี่:"
}


export const kid_en = {
    "KID_HEADER_TITLE": "Get handsome like a father like a son with a Kid combo 80k at 30shine",
    "KID_HEADER_TITLE_SUB": "KID COMBO 5 STEPS",
    "KID_HEADER_CONTENT": "If Dads have Shine combo 100K, sons are also served with 5-step Kid Combo priced at 80K. This Combo is tailored for kids nowhere else but at 30Shine in order for both dads and sons to shine. (Apply for kids under 1.3m height)",
    "KID_1_TITLE": "Process",
    "KID_1_CONTENT": "In accordance with our exclusive 30Shine 5-step process, our 30Shine's stylists will offer a neat and Dad-like haircut for kids while still preserving the cuteness of kids themselves.",
    "KID_1_B1": "Step 1/ Shampoo hair to make it soft and clean",
    "KID_1_B2": "Step 2/ Make suggestions for perfect hairstyles",
    "KID_1_B3": "Step 3/ Do the haircut",
    "KID_1_B4": "Step 4/ Do post haircut washing for relaxing feeling all day",
    "KID_1_B5": "Step 5/ Style hair with wax",
    "KID_2_TITLE": "Some stylish hairstyles in Kid Combo",
    "KID_2_CONTENT_TOP": "Let's have some look at the “baby-like” but also “adult-like” faces from our “little customers” at 30Shine:",
    KID_2_CONTENT_BOTTOM: "You want your kids have a neat hairstyle? <br/>You hope to be said: Like father, like son<br/>You would love to see both baby-like and adult-like versions of your kids?<br/>",
    KID_LINK: "Book a reservation at the nearest 30Shine salon now to realize your dream! Book now for no waiting in line at"
}
