export const about_th = {
    "ABOUT_HEADER": "พันธกิจของ 30Shine",
    ABOUT_CONTENT_HEADER: "“30Shine เชื่อมั่นและพยายามทุกวันเพื่อร่วมมือกับช่างฝีมือชาวเวียดนามโดยการกระบวนการทางวิทยาศาสตร์ 30 นาที เพื่อให้ผู้ชายทั้งโลกมีทรงผมที่ดูดี สุขภาพผิวดีดึงดูดผู้หญิงและการผ่อนคลายเพื่อการพัฒนาที่ดีในการทำงานในอนาคต”",
    "ABOUT_ITEM_TITLE_1": "ความเป็นมาของ 30Shine ",
    "ABOUT_ITEM_TITLE_2": "ความมุ่งมั่นของ 30Shine",
    "ABOUT_ITEM_TITLE_3": "ค่านิยมหลัก",
    "ABOUT_ITEM_TITLE_4": "ความหมายของโลโก้และสโลแกน",
    "ABOUT_ITEM_TITLE_5": "บริการ SHINE COMBO 7 ขั้นตอน",
    "ABOUT_ITEM_TITLE_6": "ร้านตัดผมผู้ชาย",
    "ABOUT_ITEM_CONTENT_1": "30Shine เปิดให้บริการครั้งแรกที่กรุงฮานอยในเดือนพฤษภาคม 2558 และใช้เวลาหนึ่งปีในการค้นหาทิศทางจนเหมือนปัจจุบัน ผู้ก่อตั้ง 30Shine เชื่อว่าช่างฝีมือชาวเวียตนามมีความทุ่มเทและความชำนาญในด้านการตัดผม การทำเล็บ การนวด ... ถ้านำรูปแบบเทคโนโลยีใหม่และกระบวนการที่เหมาะสมมาใช้ จะสามารถช่วยให้ 30Shine ก้าวหน้าไปได้อย่างมั่นคง การบริการลูกค้าอย่างดีที่สุด การสร้างสภาพแวดล้อมที่ดีให้แก่พนักงาน ธุรกิจก็สามารถพัฒนาอย่างยั่งยืนในเวียดนามและต่างประเทศได้",
    "ABOUT_ITEM_CONTENT_2": "ในปีพ. ศ. 2563 จะมีสาขาเพิ่มขึ้นถึง 300 ร้านในเวียดนามและขยายสาขาไปทั่วโลก มีส่วนร่วมในอุตสาหกรรมการบริการโดยการใช้แรงงานชาวเวียตนาม",
    "ABOUT_ITEM_CONTENT_3.1": "ความซื่อสัตย์ คือ การคิด-การพูด-การกระทำ เหมือนกัน แต่ตรงข้ามกับความซื่อสัตย์ก็คือการโกหก โกงเงิน โกงเวลา บิดเบือนข้อมูล ... ก็เป็นเรื่องต้องห้ามที่ 30Shine 30Shine จะใช้ความสามารถและทรัพยากรทั้งหมดเพื่อตรวจสอบและลงโทษตามความเหมาะสมของการกระทำที่ถือเป็นการฉ้อโกงรวมถึงดำเนินการตามกฎหมาย",
    "ABOUT_ITEM_CONTENT_3.2": "ความกระตือรือร้นที่จะเรียนรู้ ใช้เวลาและความพยายามที่คุณมีในการเรียนรู้ปัญหาหรือข้อสงสัยที่คุณยังไม่รู้ การกระตือรือร้นที่จะเรียนรู้สิ่งใหม่ๆ จะช่วยให้พัฒนาตัวคุณเองทำให้ไม่ล้าหลังเหมือนกับการพัฒนาสังคมอย่างต่อเนื่อง",
    "ABOUT_ITEM_CONTENT_3.3": "ความทุ่มเท คือพร้อมที่จะใช้ความพยายามและความกระตือรือร้นที่มีปฏิบัติงานและแก้ปัญหาได้ดี มีความใส่ใจลูกค้า ทุ่มเทให้กับเพื่อนร่วมงาน เพื่อน ครอบครัวเสมอ ... จะทำให้เราได้รับความพึงพอใจ และได้รับความเคารพในการทำงานและชีวิตมากขึ้น",
    "ABOUT_ITEM_CONTENT_3.4": "ความรับผิดชอบ คือการมองเห็นสาเหตุของปัญหาด้วยตัวเราเอง และสามารถหาวิธีแก้ปัญหาเพื่อให้ได้ผลลัพธ์ที่ดีขึ้นได้ การยอมรับความรับผิดชอบไม่ได้หมายถึงการรับผิดชอบ ทัศนคติที่ดีเกี่ยวกับความรับผิดชอบจะช่วยให้คุณลดความเสี่ยงในการเกิดความขัดแย้งและการเผชิญหน้า ช่วยให้คุณสร้างทีมงานที่แข็งแกร่งและมีความคิดริเริ่มสร้างสรรค์ ปรับปรุงคุณภาพการทำงานและการใช้ชีวิตให้ดียิ่งขึ้น",
    "ABOUT_ITEM_CONTENT_4": "สโลแกน: “ผ่อนคลาย 30 นาทีแล้วคุณจะเปล่งประกาย” เป็นเหมือนสัญญาที่ 30Shine มอบให้กับลูกค้า ลูกค้าจะได้รับคำสัญญาและ 30Shine ก็พยายามทุกวันเพื่อทำตามคำสัญญาซึ่งเป็นภารกิจของ 30Shine (*) โลโก้และสโลแกนของ 30Shine ได้รับการคุ้มครองเป็นพิเศษจากสำนักงานทรัพย์สินทางปัญญาแห่งชาติในเดือนกรกฎาคม 2560",
    "ABOUT_ITEM_CONTENT_5": "เป็นแพ็คเกจบริการที่ถูกคิดค้นและมีความเป็นตัวตนของ 30Shine Shine Combo จะช่วยให้ลูกค้าได้ทรงผมที่สวยงาม ได้ผ่อนคลาย ได้รับการดูแลผิวหน้า ... โดยไม่ต้องรอนานในราคาที่น่าสนใจ 30Shine ใช้กระบวนการทางวิทยาศาสตร์และเทคโนโลยีเพื่อปรับปรุง Shine Combo 7 ขั้นตอน เพื่อบริการลูกค้าอย่างดีที่สุด",
    "ABOUT_ITEM_CONTENT_6": "30Shine ปัจจุบันมีสถานประกอบการธุรกิจมากมายตั้งอยู่บนถนนสายหลักของเมืองใหญ่และจังหวัดใหญ่ๆ ทั่วประเทศ พื้นที่ของร้านแต่ละแห่งคือ 200 ตารางเมตร อุปกรณ์ทั้งหมดเป็นอุปกรณ์ชั้นนำ มีการตกแต่งที่สวยงามและพนักงานมืออาชีพ",
}


export const about_en = {
    "ABOUT_HEADER": "30Shine's mission",
    ABOUT_CONTENT_HEADER: "“30Shine strives every day to bring our customers the best service performed by the talent of Vietnamese stylists under the scientifically-proven efficient 30-minute procedure. We promise to grant our customers, globally, handsome-looking hair styles, healthy and woman-alluring skin as well as relaxing spirit to go a long way in the career path.”",
    "ABOUT_ITEM_TITLE_1": "30Shine's story",
    "ABOUT_ITEM_TITLE_2": "30Shine's ambition",
    "ABOUT_ITEM_TITLE_3": "Core values",
    "ABOUT_ITEM_TITLE_4": "The meaning of Logo and Slogan",
    "ABOUT_ITEM_TITLE_5": "7-step SHINE COMBO SERVICE",
    "ABOUT_ITEM_TITLE_6": "Men's hair cutting chain",
    "ABOUT_ITEM_CONTENT_1": "30Shine first opened to public in May 2015 in Hanoi and it took us 1 year to find the right way as we have today.The founders of 30Shine believe that Vietnamese hairdressers are very dedicated and skillful in the field of hair cutting, manicuring and massage, etc. If a new model of salon that can apply high-tech and professional procedure like 30Shine is founded, it can not only create a better working environment for those talented hairdressers but also bring about premium service for customers. Looking further, 30Shine can also achieve sustainable development within Vietnam and promisingly branch out overseas. ",
    "ABOUT_ITEM_CONTENT_2": "By 2020, 30Shine will have had 300 salons throughout Vietnam and branched out overseas. Our talented stylists will have had chances to experience in other related jobs in service industry that demands the skillfulness of Vietnamese men. ",
    "ABOUT_ITEM_CONTENT_3.1": "HONEST means thinking-speaking-doing the same, and the opposite is dishonest. The acts of being dishonest about money, dishonest about time and dishonest about information, etc. are all prohibited at 30Shine. 30Shine commits itself to using every resource to detect and punish any dishonest behaviours, even taking them to courts.",
    "ABOUT_ITEM_CONTENT_3.2": "WILLING TO LEARN means using time and efforts efficiently to research on any questionable or unknown issues. A willing-to-learn attitude also help each one move forward to keep up with the on-going development of the whole society. ",
    "ABOUT_ITEM_CONTENT_3.3": "DEDICATED means being ready to exert every effort and enthusiasm when performing any tasks or solving any problems in order to achieve the best results. Being dedicated to customers, dedicated to co-workers, to friends and family, etc. is a way to achieve more satisfaction and appreciation in both work and life.",
    "ABOUT_ITEM_CONTENT_3.4": "RESPONSIBLE means seeing the root cause of any problem from yourself, thereby giving solutions to achieve better results. It is admitting responsibility, not taking responsibility. The responsibility-admitting attitude will help minimize the risks of conflict as well as build a strong society with proactive attitude, thereby improving the quality of both work and life.",
    "ABOUT_ITEM_CONTENT_4": "The Slogan: “Relax for 30 minutes and you will shine” is like a promise 30Shine makes to customers. Customers gain trust from that promise and 30Shine makes all efforts every day to fulfill that promise, which is also the mission of 30Shine. (*) The Logo and Slogan of 30Shine was granted exclusive protection by the National Office of Intellectual Property in July 2017.",
    "ABOUT_ITEM_CONTENT_5": "This is the package that is thoroughly researched and considered identity of 30Shine's mission. Shine Combo provides customers with attractive hairstyles, relaxing experience, and facial care service, etc. at a reasonable price without having to wait long. 30Shine continuously applies scientific and technological advancement to 7-step Shine Combo to best serve customers.",
    "ABOUT_ITEM_CONTENT_6": "30Shine currently has many branches located on major streets of big cities and provinces across the country. The minimum service area for each salon is 200m2. All are invested in leading equipment, classy decoration and professional staff.",
}