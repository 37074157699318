export const OrtherServices_th = {
    "ORTHER_SERVICES_KID_COMBO": "",
    "ORTHER_SERVICES_DYE": "เปลี่ยนลุคด้วยทรงผมแฟชั่นหลายร้อยสีในราคาเหลือเชื่อเพียง 180K การใช้สีไฮเอนด์สไตลิสต์ยังแนะนำวิธีปกป้องสีผมอย่างมีประสิทธิภาพมากที่สุด",
    "ORTHER_SERVICES_CURLING": "ช่วยคุณจัดแต่งทรงผมง่ายขึ้น โดยเฉพาะถ้าคุณมักจะจัดแต่งทรงยาก",
    "ORTHER_SERVICES_CURLING3": "ดัดวอลลุ่ม เป็นความลับที่ผู้ชายผมมักจะลอยอยู่ในรอยพับ แพ็คเกจระดับพรีเมี่ยมของ 30Shine ที่ใช้ ATS ระดับไฮเอนด์พร้อมข้อได้เปรียบที่โดดเด่นมากมาย",
    "ORTHER_SERVICES_REMOVAL": "สนับสนุนผู้ที่ต้องการย้อมผมสี",
    "ORTHER_SERVICES_CARE": "",
    "ORTHER_SERVICES_EXFOLIATION": "กำจัดผิวที่ตายแล้วกำจัดได้ถึง 90% ของความเสี่ยงต่อการเกิดสิวและนำผิวที่เปล่งปลั่งสุขภาพดีมาสู่ผู้ชาย!",
    "ORTHER_SERVICES_COOL": "ผู้ช่วยให้รอดของพระเจ้าสำหรับวันฤดูร้อนฟื้นฟูความสดชื่นและให้ใบหน้าที่มีสุขภาพกับผู้ชาย",

    "ORTHER_SERVICES_TITLE_2": "ทำสีแฟชั่น",
    "ORTHER_SERVICES_TITLE_3": "ดัดผม",
    "ORTHER_SERVICES_TITLE_4": "ดัดผมวอลลุ่มด้วยน้ำยา, ATS",
    "ORTHER_SERVICES_TITLE_5": "ฟอกสีผม",
    "ORTHER_SERVICES_TITLE_7": "ขัดหน้า",
    "ORTHER_SERVICES_TITLE_8": "มาร์คหน้าเย็น",
}

export const OrtherServices_en = {
    "ORTHER_SERVICES_KID_COMBO": "",
    "ORTHER_SERVICES_DYE": "Change your look with hundreds of fashionable hair colors at an unbelievable price of just 180K. Using high-quality dye, our stylist also advises you how to protect the hair color most effectively.",
    "ORTHER_SERVICES_CURLING": "Help you style your hair much easier",
    "ORTHER_SERVICES_CURLING3": "Premium hair curling package of 30Shine using ATS perm solution with outstanding advantages is  the secret to keep men's hair volumized and in-place",
    "ORTHER_SERVICES_REMOVAL": "For those who want to dye their hair light.",
    "ORTHER_SERVICES_CARE": "",
    "ORTHER_SERVICES_EXFOLIATION": "Remove dead tisues, eliminate up to 90% of the risk of acne and bring healthy glowing skin to men!",
    "ORTHER_SERVICES_COOL": "Savior for summer skin, refreshing and bringing a healthy look for men.",

    "ORTHER_SERVICES_TITLE_2": "HAIR COLORING",
    "ORTHER_SERVICES_TITLE_3": "HAIR PERMING",
    "ORTHER_SERVICES_TITLE_4": "PREMIUM ATS HAIR CURLING",
    "ORTHER_SERVICES_TITLE_5": "MEN'S HAIR BLEACH WASH",
    "ORTHER_SERVICES_TITLE_7": "FACIAL EXFOLIATION",
    "ORTHER_SERVICES_TITLE_8": "APPLY COOL FRESH FACE MASKS",
}