import React, { Fragment, useState } from 'react';
import CONSTAINS from '../../comp/constants';
import ModalBuyShine from './ModalBuyShine'
import { withRouter } from "react-router-dom";
import './style.scss';
import MediaQuery from 'react-responsive';
import CustomModal from '../modalcheckbooking/ModalCheckBooking';
import ModalBooking from '../../comp/modal_booking/ModalBooking';
import { useTranslation } from 'react-i18next'

const URL = CONSTAINS.URL_IMAGES;
// const icDownLoadApp = URL + 'taiapp.png'
// const icDownLoadApp_Red = URL + 'Membership/v2/app.png'
// const icCheck = URL + 'kiem-tra-lich.png'
const icbook_th = URL + 'dat-lich-th.png'
const icbook_en = URL + 'dat-lich-en.png'
const icbuyShine = URL + 'Membership/v2/order.png'


const Content = props => {
    const { i18n } = useTranslation();
    const [visible, setVisible] = useState(false);
    const [visibleBuyShine, setvisibleBuyShine] = useState(false);
    // const [icon, setIcon]  = useState({});

    const Url = props.location.pathname;
    let pathName = '/';
    if (Url.length === 1) {
        pathName = 1;
    } else {
        pathName = 'subPage'
    }

    if (Url.search('booking/success') > 0) {
        pathName = 3;
    } else {
        if (Url.search('booking') > 0) {
            pathName = 2;
        }
    }

    if (Url.search('shine-member') > 0) pathName = 4;

    const isEn = i18n.language.includes("en");

    return (
        <Fragment>

            {(pathName === '/' || pathName === 'subPage') &&
                <img style={{ height: props.height }} src={isEn ? icbook_en : icbook_th} alt="Booking" onClick={() => props.setVisible(true)}></img>
            }

            {
                pathName === 4 &&
                <img style={{ height: props.height, cursor: 'pointer' }} src={icbuyShine} alt="Download_App"
                    onClick={() => setvisibleBuyShine(true)} ></img>
            }

            <CustomModal visible={visible} setVisible={setVisible} />
            <ModalBuyShine visible={visibleBuyShine} setVisible={setvisibleBuyShine} />
        </Fragment >
    )
}


const DownloadApp = (props) => {
    const [visible, setVisible] = useState(false);

    const togleModal = (value) => {
        setVisible(value);
    }

    return (
        <div className="download-app">
            <MediaQuery minWidth={768}>
                <Content height="55px" location={props.location} setVisible={setVisible} />
            </MediaQuery>
            <MediaQuery maxWidth={767}>
                <Content height="40px" location={props.location} setVisible={setVisible} />
            </MediaQuery>
            <ModalBooking visible={visible} togleModal={togleModal} />
        </div>
    )
}


export default withRouter(DownloadApp);
