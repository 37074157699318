import axios from './api';
import dfConfig from './configTest'

// export let config = {};
// const _config = localStorage.getItem("config");
// fetch('https://std.30shine.com/config/configProduction.json')
//     .then(response => response.json()
//         .then(data => {
//             if (JSON.stringify(data) !== _config) {
//                 config = data;
//                 localStorage.setItem("config", JSON.stringify(data));
//             }
//             config = data;
//         })
//     ).catch((er) => { console.log("error File Web.config", er) })
// config = JSON.parse(localStorage.getItem("config"));


export function getBanner(params) {
    return axios.get("api/banner", { params })
}

export function checkBooking(params) {

    return axios.get(dfConfig.checkBooking, { params });
}

export function smsRequest(data) {
    return axios.post(dfConfig.smsRequest, data);
}

export function verifySmsRequest(data) {
    return axios.post(dfConfig.verifySmsRequest, data);
}

export function GetHousByDate(params) {
    // return axios.get('api-booking/fifteen-minutes/book-hours', { params });  //_v3
    return axios.get(dfConfig.GetHousByDate, { params });   //_v2
}

export function getTopLike() {
    return axios.get(dfConfig.getTopLike);
}

export function getShineTV(params) {
    return axios.get(dfConfig.getShineTV, { params });
}

export function getPer_Page(params) {
    return axios.get(dfConfig.getPer_Page, { params });
}

export function getAllDistrict(params) {
    return axios.get(dfConfig.getAllDistrict, { params });
}

export function getAllProvince(params) {
    return axios.get(dfConfig.getAllProvince, { params });
}

export function getDistrictById(params) {
    return axios.get(dfConfig.getDistrictById, { params });
}


export function getAllSalonFilter(params) {
    return axios.get(dfConfig.getAllSalonFilter, { params });
}

export function getStyListInfo(params) {
    return axios.get(dfConfig.getStyListInfo, { params });
}

export function getSalonSuggestion(params) {
    // return axios.get('api-booking/fifteen-minutes/salon-near/book-hours', { params }); //_v3
    return axios.get(dfConfig.getSalonSuggestion, { params });//_v2
}

export function getSalonNear(params) {
    // return axios.get('/api-booking/fifteen-minutes/salon-near-customer/book-hours', { params }); //_v3
    return axios.get(dfConfig.getSalonNear, { params }); //_v2
}

export function GetSalonById(id) {
    return axios.get(dfConfig.GetSalonById + id);
}

export function GetAllSalonById(params) {
    return axios.get(dfConfig.GetAllSalonById, { params });
}

export function setVoucher(data) {
    return axios.post(dfConfig.setVoucher, { ...data });
}

export function checkCampaign(params) {
    return axios.get(dfConfig.checkCampaign, { params });
}
//
export function booKing(data) {
    // return axios.post('api-booking/fifteen-minutes/booking', { ...data }); //_v3
    return axios.post(dfConfig.booKing, { ...data });   //_v2
}

export function upDateBooKing(data) {
    // return axios.put('api-booking/fifteen-minutes/booking/' + data.id, { ...data }); //_v3
    return axios.put(dfConfig.upDateBooKing + data.id, { ...data }); //_v2
}


export function deleteBooKing(data) {
    //  return axios.delete('api-booking/booking/' + data);//_v3
    return axios.delete(dfConfig.deleteBooKing + data);//_v2
}

export function getInfoBooKing(params) {
    // return axios.get('api-booking/fifteen-minutes/', { params }); //_v3
    return axios.get(dfConfig.getInfoBooKing, { params }); //_v2
}

export function checkOtp(data) {
    return axios.put(dfConfig.checkOtp, { ...data });
}

export function requesOtp(params) {
    return axios.get(dfConfig.requesOtp, { params });
}

export function GetSalonOff(params) {
    return axios.get(dfConfig.GetSalonOff, { params });
}

export function putTracking(data) {
    return axios.put(dfConfig.putTracking, { ...data });
}

export function postTracking(data) {
    return axios.post(dfConfig.postTracking, { ...data });
}

export function getServiceStep3(params) {
    return axios.get(dfConfig.getServiceStep3, { params });
}

export function getQuestions(params) {
    return axios.get(dfConfig.getQuestions, { params });
}

export function postAswers(data) {
    return axios.post(dfConfig.postAswers, { ...data });
}

export function getStyleMaster() {
    return axios.get(dfConfig.getStyleMaster)
}

export function getStyleMasterMonth() {
    return axios.get(dfConfig.getStyleMasterMonth)
}

export function getAllStyList(params) {
    return axios.get(dfConfig.getAllStyList, { params });
}

export function getBlogHome() {
    return axios.get(dfConfig.getBlogHome);
}

export function getShineMember(params) {
    return axios.get(dfConfig.getShineMember, { params });
}

