import CONSTANTS from './comp/constants';

export function AddLoadding() {
    return {
        type: CONSTANTS.ADD_LOADDING,
    }
}

export function RemoveLoadding() {
    return {
        type: CONSTANTS.REMOVE_LOADDING,
    }
}

export function SetUpdateBooKing(infoUpdate) {
    return {
        type: CONSTANTS.INFO_UPDATE_BOOKING,
        infoUpdate
    }
}


export function SetDataBanner(dataBanner) {
    return {
        type: CONSTANTS.DATA_BANNER,
        dataBanner
    }
}

export function SetBookingSuccessId(id) {
    return {
        type: CONSTANTS.SET_BOOKING_SUCCESS_ID,
        id
    }
}



