import React, { useState, useEffect, Fragment } from 'react'
import Notification from 'antd/lib/notification/index'
import Modal from 'antd/lib/modal/Modal';
import Input from 'antd/lib/input/Input';
import { Link } from 'react-router-dom';
import { checkBooking, smsRequest, verifySmsRequest } from '../../utils/CommomServices';
import './style.scss';
import { useTranslation } from 'react-i18next'

const Placeholder = "PLACEHODER_INPUT_PHONE"
const HolderOtp = "INPUT_OTP_SMS"

const CustomModal = props => {
    const { t } = useTranslation();
    const SendAgainSms = props => {
        const [otp, setOtp] = useState('');

        return (
            <div className="send-again">
                <div className="box">
                    <Input size="large" placeholder={props.t(HolderOtp)} value={otp} onChange={(e) => setOtp(e.target.value)}
                    />
                    <div className="action-check" onClick={() => props.postVerifySmsRequest(otp)}>{t("SENT")}</div>
                </div>
            </div>
        );
    }
    const TextNotBooking = props => {
        const link = props.customer.phone ? '/booking/' + props.customer.phone.replace(/\./g, '') : props.newPhone;
        return (
            <div>{t("NOT_BOOKING")} <br /> <Link className="link-booking" onClick={() => props.onCancel()} to={link}>{t("BOOKING_HERE")}</Link>  {t("FOR_US")}<br /> {t("ATTENTIVE SERVICE")}</div>
        )
    }
    const TextIsBooking = (props) => {
        const link = '/booking/' + props.customer.phone.replace(/\./g, '');
        return (
            <div>
                {t("SENT_IMFOMATION")} <b className="cus_phone">{props.customer.phone}</b> {t("YOU_RECHECK")}
                <div className="action">
                    <Link to={link} onClick={() => props.onCancel()} >{t("ONCHANGE")}</Link>
                    <div onClick={() => props.handleSetViewsms(true)}>{t("SENT_AGAIN")}</div>
                </div>
            </div>
        )
    }

    const [visible, setVisible] = useState(false);
    const [isBooked, setIsBooked] = useState('');
    const [phone, setPhone] = useState('');
    const [viewsms, setViewsms] = useState(false);

    const [customer, setCustomer] = useState({});
    const getDetailBooking = () => {
        const pramsPhone = phone.replace(/\./g, '');
        if (pramsPhone.length === 10) {
            checkBooking({ phone: pramsPhone }).then((response) => {
                setCustomer(response.customer);
                if (response.customer.isBooked) {
                    setIsBooked(1);
                } else {
                    setIsBooked(0);
                }
            })
                .catch((error) => {
                    setIsBooked(0)
                    setCustomer({});
                });
        } else {
            showMessage(t("CHECK_PHONE"));
        }
    }

    function showMessage(value) {
        const config = {
            message: t("NOTICE"),
            description: value,
            duration: 3,
            className: "error"
        }
        return Notification.error(config);
    }

    const onchangePhone = (e) => {
        const value = e.target.value
        if (((value.charCodeAt(value.length - 1) > 47 && value.charCodeAt(value.length - 1) < 58) || !value || value[value.length - 1] === '.') && value.length < 13) {
            let text = value;
            if (value.length === 4 && value[4] !== '.' && value.length > phone.length) {
                text = value + '.';
            }
            if (value.length === 8 && value[8] !== '.' && value.length > phone.length) {
                text = value + '.';
            }
            setPhone(text);
        }

    }

    const handleSetViewsms = (value) => {
        setViewsms(value);
        postSmsRequest();
    }

    const postSmsRequest = () => {
        smsRequest({ customerPhone: phone }).then((response) => {
        }).catch(() => { });
    }


    const postVerifySmsRequest = (otp) => {
        verifySmsRequest({ customerPhone: phone, otp: otp }).then((response) => {
            const config = {
                message: t("INPUT_OTP"),
                description: t("BOOKING_SUCCESS"),
                duration: 3,
                className: "success"
            }
            Notification.success(config);
        }).catch((error) => {
            const config = {
                message: t("INPUT_OTP"),
                description: t("PLEASE_AGAIN_INPUT"),
                duration: 3,
                className: "error"
            }
            Notification.error(config);
        });
    }

    useEffect(() => {
        if (props.visible && props.page === 'home')
            setPhone(props.phone.replace(/\./g, ''));
        setVisible(props.visible);
    }, [props.visible])

    useEffect(() => {
        if (props.page === 'home') {
            if (props.phone && props.phone.replace(/\./g, '') === phone)
                getDetailBooking();
        }
    }, [phone])

    const onCancel = () => {
        if (visible) {
            props.setVisible(false);
        }
        setViewsms(false);
        setPhone('');
        setIsBooked('')
    }

    return (
        <Modal
            className="modal-checkbooking"
            title={viewsms ? t("CONFIRM") : t("CHECK_BOOK")}
            visible={visible}
            footer={null}
            onCancel={onCancel}
        >

            {!viewsms ? <Fragment>
                <div className="box">
                    <Input size="large" placeholder={t(Placeholder)} value={phone} onChange={(e) => onchangePhone(e)} ></Input>
                    {(phone.length === 0 || phone.length === 12 || phone.length === 10) && <div className="action-check" onClick={() => getDetailBooking()}>{t("SEE")}</div>}
                </div>
                <div className="text">
                    {isBooked === 0 && <TextNotBooking onCancel={onCancel} newPhone={phone} customer={customer} />}
                    {isBooked === 1 && <TextIsBooking customer={customer} handleSetViewsms={handleSetViewsms} onCancel={onCancel} />}
                </div>
            </Fragment> : <SendAgainSms postVerifySmsRequest={postVerifySmsRequest} />}
        </Modal>
    );
}

export default CustomModal;