export const HairCare_th = {
    "HC_HEADER_TITLE": "บำรุงผมเสียด้วยโปรตีน",
    "HC_HEADER_TITLE_SUB": "บำรุงผมเสียด้วยโปรตีน",
    "HC_HEADER_TITLE_1": "คืนความสวยงามและสุขภาพดีให้เส้นผมของคุณกับบริการฟื้นบำรุงผมเสียด้วยโปรตีนที่ร้าน 30SHINE",
    "HC_HEADER_CONTENT_1": "ถือได้ว่าเป็น “ยาวิเศษ” ที่ช่วยบำรุงให้ผมนุ่มลื่นสุขภาพดี บริการดูแลผมเสียด้วยโปรตีนที่ 30Shine เหมาะสำหรับผมที่ผ่านการดัด การทำสีและการฟอกสีผมมาหลายครั้ง",

    "HC_ITEM_1_TITLE": "1. ส่วนผสม - ประโยชน์",
    "HC_ITEM_1_CONTENT": "ผลิตภัณฑ์ฟื้นบำรุงผมเสีย Protein Sophia เป็นผลิตภัณฑ์พรีเมี่ยมจากประเทศเกาหลีเพื่อซ่อมแซมผมเสียโดยเฉพาะ ผลิตภัณฑ์ “ขนาดเล็ดแต่มีคุณภาพ” นี้จะมอบผลลัพธ์หลังการใช้งานที่ยอดเยี่ยมให้กับเส้นผมของคุณ:",
    "HC_ITEM_LI_1": "- เพิ่มความยืดหยุ่นสำหรับผม",
    "HC_ITEM_LI_1_CONTENT1": "ด้วยสารสกัดจาก Silk Protein, Collagen และ Keratin Sophia จะแทรกซึมลึกเข้าไปในเส้นผมทำให้เส้นผมแข็งแรงขึ้น",
    "HC_ITEM_LI_2": "- ฟื้นฟูความเสียหาย",
    "HC_ITEM_LI_1_CONTENT2": "Sophia จะเติมโปรตีนที่จำเป็นสำหรับผมเสียที่เกิดจากการย้อมผมหลายต่อหลายครั้ง ช่วยลดความเปราะบางของเส้นผม และคืนความแข็งแรงสุขภาพดีให้แก่เส้นผมของคุณ",
    "HC_ITEM_LI_3": "- ปกป้องเส้นผม",
    "HC_ITEM_LI_1_CONTENT3": "ปกป้องเส้นผมจากอิทธิพลภายนอก เช่น อุณหภูมิ ควัน ฝุ่นและสารเคมีจากการจัดแต่งทรงผม และยิ่งไปกว่านั้นผลิตภัณฑ์นี้ยังช่วยดับกลิ่นแอมโมเนียและชดเชยการสูญเสียน้ำเนื่องจากผมแห้งอีกด้วย",

    "HC_ITEM_2_TITLE": "2. เมื่อใดที่คุณควรดูแลเส้นผมด้วยโปรตีน",
    "HC_ITEM_2_CONTENT_1": "หลังจากดัดหรือทำสี ผมจะสูญเสียความแข็งแรงทำให้ผมแห้งและขาดหลุดร่วง",
    "HC_ITEM_2_CONTENT_2": "แม้ว่าคุณจะไม่ได้ดัดหรือทำสี แต่ผมของคุณก็ยังคงแห้งและแข็ง คุณควรฟื้นฟูเส้นผมของคุณเพื่อให้ผมไม่ชี้ฟูและจัดทรงง่ายขึ้น",
    "HC_ITEM_2_CONTENT_3": "ในขั้นตอนการดัดหรือทำสีหลายคนมักจะใช้บริการฟื้นฟูเส้นผมเพื่อเพิ่มความสวยงามของเส้นผม ลดความเสียหายที่เกิดจากการดัดหรือทำสี นอกจากนี้เมื่อใดก็ตามที่คุณรู้สึกว่าเส้นผมของคุณดูอ่อนแอและขาดหลุดร่วงให้ไปที่ร้านทำผมชายที่น่าเชื่อถือเพื่อดูแลฟื้นฟูสุขภาพเส้นผมของคุณ! ",
    "HC_ITEM_2_CONTENT_4": "อย่าปล่อยให้ผมของคุณทำให้คุณสูญเสียความมั่นใจ! แค่ 99k 30Shine จะคืนความแข็งแรงให้กับเส้นผมของคุณ จองบริการตัดผมชายพร้อมรับการบริการวันนี้ทันที สไตลิสต์มืออาชีพมากด้วยประสบการณ์จะช่วยให้คุณเป็นเจ้าของทรงผมที่ดูดีที่สุด!",
}

export const HairCare_en = {
    "HC_HEADER_TITLE": "Protein Treatment For Dameged Hair",
    "HC_HEADER_TITLE_SUB": "PROTEIN TREATMENT FOR DAMAGED HAIR",
    "HC_HEADER_TITLE_1": "BRING HANDSOME-LOOK, HEALTHY HAIR BACK TO LIFE WITH THE PROTEIN TREATMENT FOR DAMAGED HAIR SERVICES AT 30SHINE",
    "HC_HEADER_CONTENT_1": "Regared as “a panacea” that truly revitalizes your hair, the protein treatment for damaged hair service at 30Shine is the best pick for hair that has been treated for bending, dyeing and bleaching many times.",

    "HC_ITEM_1_TITLE": "1. Ingredients - Benefits",
    "HC_ITEM_1_CONTENT": "Sophia hair repair protein is a premium product from Korea, specially applied in repairing dry and damaged hair. This “mighty mite” offers you numerous benefits, including:",
    "HC_ITEM_LI_1": "- Improve hair elasticity",
    "HC_ITEM_LI_1_CONTENT1": "With an extract from Silk Protein, Collagen and Keratin, Sophia would penetrate deep into the hair follicles, heal the epidermis and make hair look healthier.",
    "HC_ITEM_LI_2": "- Repair damages",
    "HC_ITEM_LI_1_CONTENT2": "Sophia not only replenishes protein needed for hair that loses vitality due to excessive bending or dyeing, but also completely improves the brittleness of hair, restores damaged hair and offers it a more energetic look.",
    "HC_ITEM_LI_3": "- Protect hair",
    "HC_ITEM_LI_1_CONTENT3": "Sophia protects hair from external agents such as heat, smoke, dirt and hair styling chemicals. In addition, the products also deodorizes ammonia and rehydrates dry hair.",

    "HC_ITEM_2_TITLE": "2. When should you use Protein treatment?",
    "HC_ITEM_2_CONTENT_1": "After a few bends/dyes, the epidermis will lose their bond, leading to the hardening and loss of hair.",
    "HC_ITEM_2_CONTENT_2": "For those who have not had their hair bended or dyed but having dry and hard hair, it is recommended to repair hair to make it softer, smoother and easier to style.",
    "HC_ITEM_2_CONTENT_3": "In the process of bending/dyeing, many of our clients choose to use hair repair service since it will boost the beauty of the hair as well as minimize the damages caused by bending/dyeing. Besides, whenever you feel your hair weak and broken, you should pay a visit to the well-known male hair salons to revitalize your hair!",
    "HC_ITEM_2_CONTENT_4": "Don't make your hair take away your confidence! Only with 99k can you regain healthy hair look right at 30Shine. Book a haircut and other services now, our skilled and experienced stylists will grant you the most trendy-healthy hair look!",

}