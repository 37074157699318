import React, { useEffect, createRef, useState } from 'react';
import './style.scss';
import MessengerCustomerChat from '../FBchatCustomer';
import useWindowSize from '../../hook/useWindowsize';
import iconClose from '../../images/close.png';
import iconLine from '../../images/icon-line-app.png';
import iconMessenger from '../../images/icon-messenger-facebook.png';
import iconGlobal from '../../images/icon-chat.svg';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useOutside = (ref1, ref2, ref3, callBackFunction) => {
	useEffect(() => {
		function handleClickOutside(event) {
			if (
				ref1.current &&
				!ref1.current.contains(event.target) &&
				ref2.current &&
				!ref2.current.contains(event.target) &&
				ref3.current &&
				!ref3.current.contains(event.target)
			) {
				callBackFunction();
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref1, ref2, ref3]);
};

const FooTer = () => {
	const [isToggles, setIsToggles] = useState(false);
	const { t } = useTranslation();
	const location = useLocation();
	const refDiv = createRef();
	const refDiv2 = createRef();
	const refDiv3 = createRef();
	const size = useWindowSize();

	useOutside(refDiv, refDiv2, refDiv3, () => {
		if (isToggles) {
			setIsToggles(false);
		}
	});

	return (
		<div className="footer">
			<div className="content">
				<div className="left">
					<span> Copyright 2015 30Shine, Inc. All Rights Reserved</span>
				</div>
			</div>

			{/*{size.width < 768 && location.pathname.indexOf('/booking') === -1 ? (*/}
			{/*	<div*/}
			{/*		style={*/}
			{/*			isToggles*/}
			{/*				? {*/}
			{/*						backgroundColor: 'rgba(0,0,0,0.8)',*/}
			{/*						width: '100%',*/}
			{/*						height: '100%',*/}
			{/*				  }*/}
			{/*				: null*/}
			{/*		}*/}
			{/*		className={`show-btn-wrapper ${isToggles ? 'toggle' : ''}`}*/}
			{/*	>*/}
			{/*		<div*/}
			{/*			onClick={() => setIsToggles(!isToggles)}*/}
			{/*			className="chat-custom"*/}
			{/*			aria-hidden="true"*/}
			{/*			ref={refDiv}*/}
			{/*		>*/}
			{/*			{isToggles ? (*/}
			{/*				<img src={iconClose} alt="" className="icon-close" />*/}
			{/*			) : (*/}
			{/*				<img src={iconGlobal} alt="" />*/}
			{/*			)}*/}
			{/*		</div>*/}
			{/*		<div className={isToggles ? 'chat-custom-active' : 'chat-custom-no-active'}>*/}
			{/*			<div className="chat-custom__button" ref={refDiv2}>*/}
			{/*				<div className="text">{t('CHAT_ON_LINE')}</div>*/}
			{/*				<a href="https://page.line.me/283idylx" target="_blank" rel="nofollow noreferrer">*/}
			{/*					<img src={iconLine} alt="" />*/}
			{/*				</a>*/}
			{/*			</div>*/}
			{/*			<div className="chat-custom__button chat-custom__button-2" ref={refDiv3}>*/}
			{/*				<div className="text">{t('CHAT_ON_FACEBOOK')}</div>*/}
			{/*				<a href="http://m.me/30ShineSukhumvit" target="_blank" rel="nofollow noreferrer">*/}
			{/*					<img src={iconMessenger} alt="" />*/}
			{/*				</a>*/}
			{/*			</div>*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*) : null}*/}
			{/*{size.width > 767 ? (*/}
			{/*	<>*/}
			{/*		<div class="line-chat">*/}
			{/*			<a href="https://page.line.me/283idylx" target="_blank" rel="nofollow noreferrer">*/}
			{/*				<img src={iconLine} alt="" />*/}
			{/*			</a>*/}
			{/*		</div>*/}
			{/*		<MessengerCustomerChat*/}
			{/*			pageId="2120757418222827"*/}
			{/*			shouldShowDialog={false}*/}
			{/*			showBadge={true}*/}
			{/*			greetingDialogDisplay="hide"*/}
			{/*		/>*/}
			{/*	</>*/}
			{/*) : null}*/}
		</div>
	);
};

export default FooTer;
