import React from 'react';
import './style.scss'
import Modal from 'antd/lib/modal/Modal';
import InputPhone from '../other-inputphone/OtherInputPhone'


const ModalBooking = _ => {

    const onCancel = () => {
        _.togleModal(false);
    }

    return (
        <Modal
            className="modal-booking"
            title={"ĐẶT LỊCH"}
            visible={_.visible}
            footer={null}
            onCancel={onCancel}
            maskClosable={_.maskClosable ? false : true}
        >
            <InputPhone onCancel={onCancel} salonNew={_.salonNew} onChanPhone={_.onChanPhone} />
        </Modal>
    );
}

export default ModalBooking;