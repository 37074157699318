export const listMenuPc = [
    // {
    //     title: "HOME",
    //     url: "/about"
    // },
    {
        title: "SERVICES",
        url: "/services"

    },
    // {
    //     title: "30SHINE_TV",
    //     url: "/30shine-tv",
    //     class: 'store',
    //     img: 'appstore'
    // },
    {
        title: "TOP_HAIR_HOT",
        url: "/style-master"

    },

    // {
    //     title: "SHINE MEMBER",
    //     url: "/shine-member",
    //     img: 'user'
    // },
    {
        title: "LOOK_BOOK_MENU",
        url: "/lookbook"

    },
    // {
    //     title: "BLOG",
    //     url: "https://blog.30shine.com/",
    //     img: 'calendar',
    //     to: true
    // },
    // {
    //     title: "TUYỂN DỤNG",
    //     url: "/recruitment",
    //     class: 'recruitment',
    //     img: 'user-add'
    // },
    // {
    //     title: "30SHINE STORE",
    //     url: "https://30shinestore.com/",
    //     class: 'store',
    //     img: 'appstore',
    //     to: true
    // },
]

export const listMenuMb = [
    // {
    //     title: "HOME",
    //     url: "/about"

    // },
    {
        title: "SERVICES",
        url: "/services"

    },
    // {
    //     title: "30SHINE_TV",
    //     url: "/30shine-tv",
    //     img: 'appstore'
    // },
    {
        title: "TOP_HAIR_HOT",
        url: "/style-master"

    },
    {
        title: "LOOK_BOOK_MENU",
        url: "/lookbook"

    },
    // {
    //     title: "HOME",
    //     url: "/",
    //     img: 'home'
    // },
    // {
    //     title: "SERVICES",
    //     url: "/services",
    //     img: 'setting'
    // },
    // {
    //     title: "SHINE MEMBER",
    //     url: "/shine-member",
    //     img: 'user'
    // },
    // {
    //     title: "30SHINE TV",
    //     url: "/30shine-tv",
    //     class: 'store',
    //     img: 'appstore'
    // },
    // {
    //     title: "BẢNG GIÁ",
    //     url: "/price-menu",
    //     img: 'plus',
    //     subMenu: [
    //         {
    //             title: "SHINE COMBO",
    //             url: "/shine-combo",
    //             img: 'question-circle',
    //         },
    //         {
    //             title: "DỊCH VỤ KHÁC",
    //             url: "/other-service",
    //             img: 'setting',
    //         },
    //     ]
    // },

    // {
    //     title: "BLOG",
    //     url: "https://blog.30shine.com/",
    //     img: 'calendar',
    //     to: true
    // },
    // {
    //     title: "TUYỂN DỤNG",
    //     url: "/recruitment",
    //     class: 'recruitment',
    //     img: 'user-add'
    // },
    // {
    //     title: "30SHINE STORE",
    //     url: "https://30shinestore.com/",
    //     class: 'store',
    //     img: 'appstore',
    //     to: true
    // },
    // {
    //     title: "30SHINE TV",
    //     url: "/30shine-tv",
    //     class: 'store',
    //     img: 'appstore'
    // },
    // {
    //     title: "TOP KIỂU TÓC HOT",
    //     url: "/style-master",
    //     img: 'scissor'
    // },
    // {
    //     title: "",
    //     url: "/shine_member",
    //     img: ''
    // },
]



