import React, { Fragment, useRef } from 'react';
import { Link } from 'react-router-dom';
import CONSTANS from '../../../../comp/constants';
import MySwiper from '../../../../comp/my-swiper/MySwiper';
import iconDetox from '../../../../images/icon-detox.png';
import { useTranslation } from 'react-i18next';
import './style.scss';
const URL = CONSTANS.URL_IMAGES;
const ic20 = URL + 'Service/tay-da-chet-icon.png';
const ic20a = URL + 'Service/dap-mat-na-icon.png';
// const ic100a = URL + 'Trangchu/trangchu-desktop-v2_23.png';
const ic99 = URL + 'Trangchu/trangchu-desktop-v2_29.png';
// const ic80 = URL + 'Trangchu/trangchu-desktop-v2_32.png';
const ic349 = URL + 'Trangchu/icon-uon-01.png';

const ic100 = URL + 'Trangchu/trangchu-desktop-v2_17.png';

const ic260 = URL + 'Trangchu/trangchu-desktop-v2_26.png';
const ic180 = URL + 'Trangchu/trangchu-desktop-v2_20.png';

const ListData = [
	{
		title: 'SHINE_COMBO',
		price: '349฿',
		icon: ic100,
		url: '/services',
	},
	{
		title: 'CURLING_VIP',
		price: '1200฿',
		icon: ic349,
		url: '/other-service/uon-toc-nam-cao-cap-ats',
	},
	{
		title: 'EP_TOC',
		price: '1200฿',
		icon: ic260,
		url: '/other-service/',
	},
	{
		title: 'HAIR_COLORING',
		price: '800฿',
		icon: ic180,
		url: '/other-service/nhuom-mau-thoi-trang',
	},
	{
		title: 'EXFOLIATION',
		price: '100฿',
		icon: ic20,
		url: '/other-service/tay-da-chet-skin-shine',
	},
	{
		title: 'APPLY_COOL',
		price: '100฿',
		icon: ic20a,
		url: '/other-service/mat-na-lanh-cool-shine',
	},
	{
		title: 'DETOX',
		price: '100฿',
		icon: iconDetox,
		url: '/other-service',
	},
];

const Item = (props) => (
	<Fragment>
		<Link to={props.url}>
			<img src={props.icon} alt="icon" style={{ height: props.height }}></img>
			<div className="title">{props.t(props.title)}</div>
			<div className="price">{props.price}</div>
		</Link>
	</Fragment>
);

const MenuService = () => {
	const { t } = useTranslation();
	const height = useRef((document.documentElement.offsetWidth * 0.8) / 3.7);
	height.current = height.current < 79 ? height.current : {};
	return (
		<div className="menu-service">
			<div className="container-header header">{t('HOME_PRICE_LIST')}</div>
			<div className="price-menu">
				<MySwiper large={7.3} medium={6.3} small={3.7}>
					{ListData.map((item, key) => {
						return (
							<div className="item" key={key}>
								<Item {...item} height={height.current} t={t}></Item>
							</div>
						);
					})}
				</MySwiper>
			</div>
		</div>
	);
};

export default MenuService;
