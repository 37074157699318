export const dye_th = {
    "SV_DYE_HEADER_TITLE": "ทำสีแฟชั่น",
    "SV_DYE_HEADER_TITLE_BOTTOM": "แสดงออกถึงความเป็นตัวตนของคุณกับบริการทำสีผมแฟชั่นที่ 30SHINE",
    SV_DYE_HEADER_CONTENT: "การทำสีผมกลายเป็นเทรนด์ความงามสำหรับผู้ชาย การทำสีผมไม่เพียงแต่ช่วยให้ผิวหน้ากระจ่างใสขึ้นเท่านั้น แต่ยังเป็นวิธีที่เด็กผู้ชายจะแสดงออกถึงสไตล์และความเป็นตัวของตัวเอง! <br/>บริการทำสีผมแฟชั่นสำหรับผู้ชายในราคาเพียง 180K ที่ 30Shine นอกจากใช้สีย้อมผมระดับไฮเอนด์แล้วสีที่ได้ย้อมก็เป็นสีที่ถูกต้องตามที่ลูกค้าต้องการ แฮร์สไตลิสต์ยังแนะนำวิธีการปกป้องสีผมอย่างมีประสิทธิภาพอีกด้วย ",
    "SV_DYE_1_TITLE": "ผลิตภัณฑ์",
    SV_DYE_1_CONTENT: "30Shine ใช้สีย้อมคุณภาพสูงจากไต้หวัน ผลิตภัณฑ์นี้มีลักษณะเด่น 3 ข้อคือ: <br/>- ปริมาณแอมโมเนียต่ำ มีกลิ่นไม่ฉุนเหมือนสีย้อมผมทั่วไป<br/>- ไม่ทำให้เกิดการระคายเคืองหนังศีรษะ<br/>- ไม่ทำให้ผมแห้งเสีย ให้สีติดทนนานกว่า 1.5 เท่า นาน 4-6 เดือน",
    "SV_DYE_2_TITLE": "ขั้นตอนการบริการ",
    SV_DYE_2_CONTENT: "นสไตลิสต์จะประเมินสภาพผมและผิวหน้าก่อนที่จะแนะนำให้คุณอย่างมืออาชีพ:",
    "SV_DYE_TITLE_2.1": "สระผม",
    "SV_DYE_TITLE_2.2": "ฟอกสีผม (ด้วยสีที่ต้องการ)",
    "SV_DYE_TITLE_2.3": "อบผม - หวีด้วยยาย้อมผม",
    "SV_DYE_TITLE_2.4": "สระผม",
    SV_DYE_CONTENT_2_1: "สระผม 1 ครั้ง พร้อมเกาเบาๆ เพื่อป้องกันไม่ให้หนังศีรษะเป็นแผล",
    SV_DYE_CONTENT_2_2: "ผสมสีฟอกขาว + ออกซิเจน 12 หวีห่างจากโคนผม 1-2 เซนติเมตร พักไว้ประมาณ 30-40 นาที ขึ้นอยู่กับสภาพผม",
    SV_DYE_CONTENT_2_3: "หวีผมจากรากจรดปลายเพื่อให้สีไม่กระจาย รอให้สีติดประมาณ 10-15 นาที",
    SV_DYE_CONTENT_2_4: "สระผมและนวดผมด้วยครีมนวดผมเพื่อให้ผมนุ่มขึ้นก่อนการไดร์และจัดทรงด้วย WAX",
    SV_DYE_CONTENT_2_4_1: "ราคาสำหรับบริการฟอกสีผม: 100k / ครั้ง",
    "SV_DYE_3_TITLE": "สีผมที่นิยมที่สุดตอนนี้",
    "SV_DYE_TITLE_3.1": "สีควันบุหรี่",
    "SV_DYE_TITLE_3.2": "สีน้ำตาลกาแฟ",
    SV_DYE_CONTENT_3_1: "ตั้งแต่ประมาณ 2 ปีที่ก่อนจนถึงตอนนี้สีควันบุหรี่ยังคงเป็นหนึ่งในสีผมที่นิยมที่สุดสำหรับผู้ชายเพราะมีความเป็นเอกลักษณ์เฉพาะตัวและ “ไม่เหมือนใคร” สีเทาตะกั่ว สีควันบุหรี่ สีฟ้าหม่น โทนสีหม่นเป็นสีที่ได้รับความนิยมจากเด็กผู้ชายเพราะสร้างความแปลกใหม่ให้แก่พวกเขา",
    SV_DYE_CONTENT_3_2: "โทนสีน้ำตาลเข้มยังคงได้รับความนิยมดูเรียบง่ายแต่ยังคงมีสไตล์ สีโทนนี้เหมาะสำหรับเกือบทุกสีผิว เข้ากับทุกสไตล์การแต่งตัวจึงเป็นสีที่นิยมที่สุด",
    "SV_DYE_BOTTOM_1_TITLE": "เพื่อให้สีผมของคุณติดทนคุณจำเป็นต้องทำตามขั้นตอนต่อไปนี้:",
    "SV_DYE_BOTTOM_1": "1. ห้ามสระผมหลังจากย้อมสี 1-2 วัน",
    "SV_DYE_BOTTOM_2": "2. ไม่ควรใช้แชมพูที่มีส่วนผสมของมิ้นท์ เช่น Clear, Head & Shoulder, Xmen, ..",
    "SV_DYE_BOTTOM_3": "3. อย่าให้ผมโดนความร้อนมากเกินไป",
    "SV_DYE_BOTTOM_4": "4. อย่าให้ผมเผชิญกับแสงแดดมากเกินไปโดยฉพาะช่วง 10:00 - 15:00 น.",
    "SV_DYE_BOTTOM_5": "หากคุณเป็นคนที่ตามเทรนด์อยู่เสมอและต้องการเป็นเจ้าของทรงผมที่หล่อและสีผมที่น่าประทับใจมั่นใจมากขึ้น จองเวลาตัดผมที่ร้าน 30Shine ใกล้บ้านคุณเพื่อทรงผมที่หล่อที่จะทำให้คุณเปล่งประกาย!",
    "SV_DYE_BOTTOM_6": "อย่ารอช้าจองเลยพร้อมรับประสบการณ์การการบริการที่: "

}


export const dye_en = {
    "SV_DYE_HEADER_TITLE": "Stylish Hair Coloring",
    "SV_DYE_HEADER_TITLE_BOTTOM": "Express your personality by using stylish hair coloring service at 30Shine",
    SV_DYE_HEADER_CONTENT: "Hair coloring or hair dyeing is becoming a beauty trend for men.<br/>Hair dyeing not only helps men's facial skin look brighter, but it is also a way of expressing their own style and personality. 30Shine offers the most stylish hair dyeing service at a really reasonable price, with only 180K. Our stylists not only use high-quality hair dyes that allow even coverage, but also give advice on how to take care of your hair after dyeing.",
    "SV_DYE_1_TITLE": "Product",
    SV_DYE_1_CONTENT: "30Shine promises to use high-quality hair dyes, orignated from Taiwan. The product has 3 outstanding features:<br/>- Low Ammonia concentration, more pleasant odor<br/>- Avoid hurting scalp<br/>- Cause no dry hair, keep color longer for 1.5 times, lasting for 4-6 months",
    "SV_DYE_2_TITLE": "Process",
    SV_DYE_2_CONTENT: "Our stylists will assess the hair quality, skin tone before advising on the hair colors and styles.",
    "SV_DYE_TITLE_2.1": "Hair wash",
    "SV_DYE_TITLE_2.2": "Bleach wash (if needed)",
    "SV_DYE_TITLE_2.3": "Dry out hair - Put on hair dye",
    "SV_DYE_TITLE_2.4": "Hair wash",
    SV_DYE_CONTENT_2_1: "Hair is shampooed once before having bleached. This would be done gently so as not to get your scalp hurt.",
    SV_DYE_CONTENT_2_2: "Mix with bleach powder with OXY 12, apply bleach from the hair root with the brush. Let it sit for 30-40 minutes depending on the hardness or softness of hair.",
    SV_DYE_CONTENT_2_3: "Apply the hair dye from the root with a brush. Let it sit for 10-15 minutes.",
    SV_DYE_CONTENT_2_4: "Wash and condition hair to make it smooth before drying and styling with wax",
    SV_DYE_CONTENT_2_4_1: "Price: 100k/time of bleaching",
    "SV_DYE_3_TITLE": "THE MOST RECENT STYLISH HAIR COLOR FOR MEN",
    "SV_DYE_TITLE_3.1": "SMOKY HAIR COLOR",
    "SV_DYE_TITLE_3.2": "COFFEE BROWN HAIR COLOR",
    SV_DYE_CONTENT_3_1: "Emerging around the past 2 years, smoky hair colors continue to be one of the most handsome dyed hair colors for men because of the unique novelty and rebelliousess.The lead smoke, grey smoke, blue smoke and moss green smoke are the smoky colors favored by men recently owing to the unique novelty.",
    SV_DYE_CONTENT_3_2: "Dark brown hair tone is still on trend by its simple but still fashionable property.This tone is suitable for most of skin tones and easy to mix-and-match with clothes so it is the most favoritve one.",
    "SV_DYE_BOTTOM_1_TITLE": "For a long-lasting hair color, you should take notice of these following",
    "SV_DYE_BOTTOM_1": "1. Do not wash hair 1-2 days after hair dyeing",
    "SV_DYE_BOTTOM_2": "2. Do not use mint-infused shampoos such as: Clear, Head&Shoulders, Xmen,..",
    "SV_DYE_BOTTOM_3": "3. Do not dry hair with hot heat",
    "SV_DYE_BOTTOM_4": "4. Limit yourself from getting exposed harsh sunlight, especially from 10a.m to 3p.m",
    "SV_DYE_BOTTOM_5": "You are a modern man who desires to own a stylish hairstyle, an alluring hair color that boosts your confidence? Book the nearest 30Shine salon now to shine!",
    "SV_DYE_BOTTOM_6": "Book a reservation now and you will get the service without waiting in line: "

}