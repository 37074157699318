export const Curling3_th = {
    "SV_CUR3_TITLE_1": "การดัดจัดทรงเพิ่มวอลลุ่ม",
    "SV_CUR3_CONTENT_1": "การดัดจัดทรงเพิ่มวอลลุ่มเป็นอีก 1 ความลับที่จะทำให้ผมอยู่ทรงอยู่เสมอ สวยเหมือนเข้าร้านทำผมเป็นประจำ ทำเพียงครั้งเดียว ทรงผมจะคงความสวยงามได้หลายเดือน",
    "SV_CUR3_TITLE_2": "ทำไมการดัดผมผู้ชายถึงเป็นที่นิยมในตอนนี้?",
    "SV_CUR3_CONTENT_2.1": "การดัดวอลลุ่มผมกำลังเป็นเทรนด์ที่ได้รับความนิยมสำหรับผู้ชาย เพราะประโยชน์เยอะ ผมเสียที่ผู้ชายมักจะเจอเป็นประจำจะได้รับการดูแลโดยเทคนิคนี้",
    "SV_CUR3_CONTENT_2.2": "หากคุณเป็นคนที่ผมน้อย ผมบาง การดัดผมจะช่วยให้ผมของคุณดูหนาขึ้น ในทางตรงกันข้ามเส้นผมที่มีความหนาและแห้งก็จะมีความนุ่มสลวยด้วย",
    "SV_CUR3_CONTENT_2.3": "สิ่งสำคัญที่สุดคือการดัดผมจะทำให้คุณดูสดใสและหล่อมากขึ้นเหมาะสำหรับไปเรียน - ไปเที่ยว - ไปทำงาน การดัดผมยังเหมาะสมกับทุกสไตล์แต่งตัวได้ง่ายมากขึ้น",
    "SV_CUR3_CONTENT_2.4": "ไม่ต้องเสียเวลาเป่าผมและจัดทรงอีกต่อไปเพราะผมของคุณจะนุ่มและอยู่ทรงเสมอ ทำแค่ครั้งเดียวก็รักษาผมให้อยู่ทรงสวยงามอยู่หลายเดือนโดยไม่ต้องเสียเวลาในการดูแลและจัดทรง",
    "SV_CUR3_TITLE_3": "เทรนด์การดัดผมนี้กำลังเป็นที่นิยมในหมู่ดาราดัง",
    "SV_CUR3_CONTENT_3.1": "ด้วยประโยชน์ดีๆแบบนี้ ดาราชายชาวเวียดนาม ซุปเปอร์สตาร์ฮอลีวูดและดาราเกาหลี...ก็หลงไหลและเป็นผู้นำเทรนด์ทรงผมดัดนี้",
    "SV_CUR3_CONTENT_3.2": "ในแวดวงบันเทิงเราจะสังเกตเห็นทรงผมดัดผมแบบต่างๆได้ทุกที่ Son Tung M-TP, Isaac, Soobin Hoang Son, Justatee, ...ทุกคนต่างก็ไม่พลาดเทรนด์การดัดผมนี้ ผู้ชายหลายพันคนได้เปลี่ยนแปลงตัวเองอย่างมั่นใจด้วยเทรนด์การดัดผม ถึงเวลาแล้วที่คุณควรลองดัดผมสักครั้งเพราะการดัดผมนั้นจะทำให้คุณดูดีสมบูรณ์แบบยิ่งขึ้น",
    "SV_CUR3_TITLE_4": "ถ้าอยากดัดและจัดทรงเพิ่มวอลลุ่มให้ผมต้องมาที่ 30SHINE",
    "SV_CUR3_CONTENT_4.1": "30Shine เป็นสถานที่ที่จะระเบิดเทรนด์ของการดัดผมในเวียดนามด้วยวิดีโอล้านวิวบน YouTube ผู้ชายหลายพันคนได้เปลี่ยนจากการตัดผมแบบง่ายๆไปเป็นการดัดจัดทรงผมเพิ่มวอลลุ่มให้น่าดึงดูดยิ่งขึ้น",
    "SV_CUR3_CONTENT_4.2": "แม้แต่คนที่มีชื่อเสียง: Justatee, Lou Hoang, Karik, นักฟุตบอลทีมชาติ Phan Van Duc, N. P. Hong Duy ... ก็ยังตามเทรนด์นี้ มาที่ 30Shine เพื่อเป็นเจ้าของทรงผมที่ดูดีที่สุด เพราะทั้งหมดนี้มีแค่ที่ 30shine ที่มี",
    "SV_CUR3_CONTENT_LI_1": "สไตลิสต์ของ 30Shine เป็นช่างทำผมที่มาจากทั่วประเทศ ไม่ว่าจะทรงผมใหม่หรือเทรนด์ใหม่สไตลิสของ 30Shine ก็ได้รับการอัพเดทอย่างรวดเร็ว",
    "SV_CUR3_CONTENT_LI_2": "เทคโนโลยีการดัดผมแบบเกาหลีด้วยราคาที่น่าเหลือเชื่อ ที่ 30Shine เพียง 260k คุณก็สามารถสัมผัสกับเทคโนโลยีดัดผมระดับพรีเมียมจากเกาหลี ยิ่งไปกว่านั้นหากคุณเป็นสมาชิก Shine Member จะได้ราคาส่วนลดเหลือเพียง 234k",
    "SV_CUR3_CONTENT_LI_3": " เทคโนโลยีการจองที่เป็นเอกลักษณ์ - มาถึงจะได้ตัดทันที จองคิวล่วงหน้าผ่าน 30shine.com คุณจะได้รับบริการทันทีเมื่อมาถึงที่ร้านช่วยประหยัดเวลาได้มากเลยทีเดียว",
    "SV_CUR3_CONTENT_LI_4": "ไม่เพียงคุณมีทรงผมที่หล่อและดูดีแต่คุณยังจะได้รับคำแนะนำโดยตรงจากสไตลิสต์เกี่ยวกับวิธีการสระผมและดูแลเส้นผมของคุณ เพื่อให้ผมของคุณอยู่ทรงเป็นเวลานาน",
    "SV_CUR3_CONTENT_LI_5": "30Shine ยังมีการรับประกันการดัดผมที่ไม่เหมือนที่อื่น: คุณจะได้รับการช่วยเหลือเกี่ยวกับการแก้ไขทรงผมภายใน 15 วันนับจากวันที่ดัดฟรี! ทั้งหมดนี้เพื่อความพึงพอใจของคุณ",
    "SV_CUR3_LI_4_TITLE": "เทรนด์ทรงผมดัดของ 30Shine",
    "SV_CUR3_LI_4_TITLE_1": "ความรู้สึกของลูกค้า",
    "SV_CUR3_LI_4_1": "ตอนแรกฉันเห็นเพื่อนๆ ดัดผมมากเกินไป ฉันก็พยายามจะลองทำ เมื่อฉันดัดผมเสร็จฉันก็ประหลาดใจมากเพราะฉันดูเปลี่ยนไปมากจริงๆ ใครๆก็ชมว่าดูดีขึ้นและหล่อขึ้น ผลลัพธ์ที่ได้เกินคาดจริงๆ",
    "SV_CUR3_LI_4_2": "ฉันเป็นพนักงานออฟฟิศจึงไม่มีเวลาพอที่จะเป่าผมและจัดทรงด้วย wax ทุกเช้า การดัดผมช่วยประหยัดเวลาได้มากในตอนเช้าที่เร่งรีบเพียงแค่ใช้มือเสย 1 - 2 ครั้งผมก็อยู่ทรง เพราะสะดวกแบบนี้ไงฉันจึง “หลงใหล” ในการดัดผมมาเป็นเวลา 2 ปีแล้ว",
    "SV_CUR3_LI_4_3": "วันนั้นพี่ลองให้ลูกดัดผมที่ร้านทำผมที่ Hang Bong และพบว่าลูกพี่ดูดีมีสไตล์ พอออกไปที่ถนนและใครๆ ต่างก็ถามพี่ว่าให้ลูกตัดผมที่ไหนทำไมสวยจัง ขอบคุณสไตลิสต์ในวันนั้นสำหรับคำแนะนำอย่างกระตือรือร้นให้กับพี่และลูกจริงๆ",
    "SV_CUR3_LI_4_4": "ชายหนุ่มหลายพันคนมาที่ 30Shine เพื่อเป็นเจ้าของทรงผมดัดที่สวยอยู่ทรงทำให้คุณหล่อเท่และดูโรแมนติก จองตอนนี้เพื่อรับบริการดัดผมที่จะทำให้คุณดูดีและรู้สึกผ่อนคลายในร้านที่หรูหราและมีระดับของ 30Shine"
}

export const Curling3_en = {
    "SV_CUR3_TITLE_1": "Hair Curling And Styling",
    "SV_CUR3_CONTENT_1": "Hair curling and styling is the secret to keep men's hair structured and in-place all day, make it look as charming as it is done at salons. Just do it once, your hair form and structure is well-kept for several months.",
    "SV_CUR3_TITLE_2": "WHY HAIR CURLING BECOMES A NEW TREND OF MODERN MEN?",
    "SV_CUR3_CONTENT_2.1": "Hair curling is a trend favored by men because of many benefits it brings about. Hair defects that men often encounter are quickly resolved by this technique.",
    "SV_CUR3_CONTENT_2.2": "If a man has thin hair, curling hair will make it look fluffy, creating a thicker hair look. In contrast, thick and dry hair will become much softer and smoother when being curled.",
    "SV_CUR3_CONTENT_2.3": "Most importantly, curling hair makes men look more handsome and attractive whenever they go - whether it is school, work or hangouts. Men's curling hairstyles also fit many kinds of clothing, making it easier to restyle themselves.",
    "SV_CUR3_CONTENT_2.4": "You no longer have to struggle to dry hair or style with wax since your curled hair is always well-structured, in-place and smooth all day. Just do it once for a handsome long-lasting hairstyle that costs little effort to take care or style.",
    "SV_CUR3_TITLE_3": "A CRAZE FOR HAIR CURLING CREATED BY STARS",
    "SV_CUR3_CONTENT_3.1": "With such great benefits, thousands of Vietnamese male stars and Hollywood, Korea stars, etc. are enchanted and enthusiastic about curling hairstyles.",
    "SV_CUR3_CONTENT_3.2": "Take a tour around showbiz circle, we can notice various curling hairstyles everywhere. Son Tung M-TP, Isaac, Soobin Hoang Son, Justatee, etc. are all male stars that could not stand out of the hair-curling fever.Thousands of men have followed the hair curling trend. Now it is your time to give it a try to feel the perfect appearance that your curls bring to you. ",
    "SV_CUR3_TITLE_4": "CURL AND STYLE YOUR HAIR NOWHERE ELSE BUT 30SHINE",
    "SV_CUR3_CONTENT_4.1": "It is 30Shine that blow up the trend of curling hair in Vietnam by its million-view videos on Youtube. Thousands of men have removed the term simple haircut from their lists and opted for curling and styling hair for an attractive appearance.",
    "SV_CUR3_CONTENT_4.2": "Even famous figures like Justatee, Lou Hoang, Karik, national football player Phan Van Duc, N. P. Hong Duy, etc. quickly followed the trend and had themselves the best curling hairstyles right at 30Shine. This is because of these following:",
    "SV_CUR3_CONTENT_LI_1": "30Shine's stylist are all hair masters from all over the country. Any new hairstyle or trend are updated rapidly by stylists of 30Shine.",
    "SV_CUR3_CONTENT_LI_2": "Experience Korean hair curling technology at incredibly reasonable prices. At 30Shine, with only 260k, you are able to experience high-tech curling service as well as premium curling hair products from Korea. In addition, should you be Shine Member, the discount price for you is only 234k.",
    "SV_CUR3_CONTENT_LI_3": "Unique scheduling service - come and get your haircut right away. If you book in advance via 30shine.com, you will be served immediately upon your arrival at the salon, which saves a lot of your time.",
    "SV_CUR3_CONTENT_LI_4": "Not only do you have a handsome hair look, you are also advised on how to wash and take care of your hair to keep it volumizing for the longest time by our stylists.",
    "SV_CUR3_CONTENT_LI_5": "30Shine also has a hair curling warranty service to be found nowhere else: you are provided with free hair correction service within 15 days of your curling. All we do is for your satisfaction.",
    "SV_CUR3_LI_4_TITLE": "Top trendy curling hairstyles at 30Shine:",
    "SV_CUR3_LI_4_TITLE_1": "Customer's feedbacks:",
    "SV_CUR3_LI_4_1": "At first I saw a lot of my friends do hair curling so I decided to give it a try. When I finished hair curling, I was totally surprised by my brand-new appearance. Everyone praised me for my handsome new look. That was out of my expectation!",
    "SV_CUR3_LI_4_2": "As an office worker, I don't have much time to dry and style my hair every morning. It is hair curling that really saves my day. It costs me only 1-2 swipes every morning to keep hair in-place. For such convenience, I have been “addicted” to hair curling for almost 2 years now. ",
    "SV_CUR3_LI_4_3": "That day, I took my boy to Salon at Hang Bong street to have his hair curled and found that he was much more stylish in that curled hair. Whenever I took him out on the street, people kept asking me where he did such handsome-looking hair curling. Thank you so much the stylist for your wonderful advice.",
    "SV_CUR3_LI_4_4": "Thousands of men have come to 30Shine to own themselves gentleman, handsome curled hairstyles. Book now to get your own curled hairstyle as well as experience the relaxing, classy space of 30Shine."
}
