import React from 'react';
import './style.scss';
import CONSTAINS from '../../comp/constants'
import { getAllProvince, getAllSalonFilter } from '../../utils/CommomServices';
import MediaQuery from 'react-responsive';
import Modal from 'antd/lib/modal/Modal';
import DropdownCustom from '../../comp/dropdown/DropdownCustom'

const iconCar = CONSTAINS.URL_IMAGES + 'car-64.png';

const Content = props => (
    <div className="item">
        <div className="address">
            <span className="key">{props.count + 1}</span>
            <span className="name">{props.name} {props.address}</span>
        </div>
        <div className="phone">
            <div className="box align-middle">
                <span>{props.phone}</span>
            </div>
            {props.parking &&
                <img src={iconCar} alt="icon"></img>
            }
        </div>
    </div>
)


const ListDataModal = props => (
    <div>
        {props.listData && props.listData.map((item, key) => {
            return (
                <li style={{
                    fontSize: '25px', height: '40px',
                    fontWeight: '700', borderBottom: '1px solid #ccc'
                }}
                    onClick={() => props.onClick(item, key)}
                    key={key}>{item.cityName}</li>
            );
        })
        }
    </div>
)

class DropdownLocation extends React.Component {

    state = {
        cityName: 'BANGKOK',
        selected: 0,
        listProvince: [],
        listSalon: [],
        visible: false
    }

    getDataSalon = (data) => {
        getAllSalonFilter(data).then((response) => {
            this.setState({ listSalon: response.reverse() });
        }).catch((error) => { console.log(error) });
    }


    componentWillMount() {
        this.getDataSalon({ cityId: 81 });
        getAllProvince().then((response) => {
            this.setState({ listProvince: response });
        });
    }

    onClick = (value) => {
        this.setState({ cityName: value.cityName.toUpperCase(), visible: false });
        this.getDataSalon({ cityId: value.cityId });
    }
    showModal = () => {
        this.setState({ visible: true });
    }
    handleCancel = () => {
        this.setState({ visible: false });
    }

    render() {
        const { cityName, listProvince, listSalon, visible } = this.state;
        return (
            <div className="dropdown-location">
                <div className="btn-group">

                    <MediaQuery minWidth={768}>
                        <DropdownCustom text={cityName}>
                            <ul className="dropdown-menu">
                                {listProvince && listProvince.map((item, key) => {
                                    return (
                                        <li className="dropdown-item" href="#" onClick={() => this.onClick(item)} key={key}>
                                            {item.cityName.toUpperCase()}
                                        </li>
                                    );
                                })}
                            </ul>
                        </DropdownCustom>
                    </MediaQuery>

                    <MediaQuery maxWidth={767}>
                        <div className="dropdown-custom">
                            <div className="btn-custom" onClick={this.showModal}> {cityName}</div>
                        </div>
                        <Modal
                            style={{ top: '2%' }}
                            visible={visible}
                            title={null}
                            footer={null}
                            onCancel={this.handleCancel}
                        >
                            <ListDataModal listData={listProvince} onClick={this.onClick} />
                        </Modal>
                    </MediaQuery>
                </div>

                <div className="list-data">
                    {listSalon.map((item, key) => {
                        return (
                            <Content key={key} count={key}
                                {...item}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default DropdownLocation;