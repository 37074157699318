import React, { useState, useEffect } from 'react'
import Modal from 'antd/lib/modal/Modal';
import Input from 'antd/lib/input/Input';
import Row from 'antd/lib/row/index';
import Col from 'antd/lib/col/index';
import CONSTANS from '../../comp/constants'

const URL = CONSTANS.URL_IMAGES;
const icUser = URL + 'Membership/user.png'
const icPhone = URL + 'Membership/phone.png'
const icButtom = URL + 'Membership/btnPayment.png'

export default function ModalBuyShine(props) {
    const [visible, setVisible] = useState(false);
    const name = useFormInput('Nhập họ tên...');
    const phone = useFormInput('Nhập số điện thoại...');

    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible])

    const onCancel = () => {
        props.setVisible(false);
        // if (visible) setVisible(false);
    }

    const onOk = () => {

    }

    return (
        <Modal
            title={useTitle}
            className="modal-buy-shine"
            visible={visible}
            footer={null}
            onCancel={onCancel}
            onOk={onOk}
        >
            <Row style={{ marginBottom: '15px' }}>
                <Col span={4}><img src={icUser} alt="icon-user"></img></Col>
                <Col span={20}><Input {...name}  ></Input></Col>
            </Row>
            <Row>
                <Col span={4}><img src={icPhone} alt="icon-phone"></img></Col>
                <Col span={20}> <Input {...phone}  ></Input></Col>
            </Row>
            <img src={icButtom} className="ic-button" alt="icon-btn"></img>
        </Modal>
    );
}

const useTitle = (
    <div><span >ANH VUI LÒNG NHẬP THÔNG TIN</span> <br />
        <span className="text">(Để tiến hành việc mua Shine Member)</span>
    </div>
)

const useFormInput = (placeholder) => {
    const [value, setValue] = useState('');

    function handleChange(e) {
        setValue(e.target.value)
    }
    return {
        value,
        onChange: handleChange,
        placeholder: placeholder
    }
}