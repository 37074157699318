import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { en } from './locale/en'
import { th } from './locale/th'
import { about_en, about_th } from './locale/About'
import { services } from './locale//services/index'
import { Booking_success_th, Booking_success_en } from './locale/Bookingsuccess'
import { ModalCheckBooking } from './locale/ModalCheckBooking';
import { OrtherServices_en, OrtherServices_th } from './locale/OrtherServices'
import { SummerRemix } from './locale/LookBook/index'

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            th: {
                translations: {
                    ...th,
                    ...about_th,
                    ...services.th,
                    ...Booking_success_th,
                    ...ModalCheckBooking.th,
                    ...OrtherServices_th,
                    ...SummerRemix.th
                }
            },
            en: {
                translations: {
                    ...en,
                    ...about_en,
                    ...services.en,
                    ...Booking_success_en,
                    ...ModalCheckBooking.en,
                    ...OrtherServices_en,
                    ...SummerRemix.en
                }
            }
        },
        fallbackLng: "th",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
