import React, { useState, useEffect } from 'react';
import CONSTANTS from '../../../../comp/constants';
import MySwiper from '../../../../comp/my-swiper/MySwiper';
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './style.scss';
const URL = CONSTANTS.URL_IMAGES;

const Fashion30Shine = () => {
    const { t, i18n } = useTranslation();

    const ic1_th = URL + 'BestHair/th-home-summer-remix.jpg'
    const ic2_th = URL + 'BestHair/th-home-kid.jpg'
    const ic3_th = URL + 'BestHair/th-home-mohican.jpg'
    const listIconTh = [ic1_th, ic2_th, ic3_th]
    const ic1_en = URL + 'BestHair/en-home-summer-remix.jpg'
    const ic2_en = URL + 'BestHair/en-home-kid.jpg'
    const ic3_en = URL + 'BestHair/en-home-mohican.jpg'
    const listIconEn = [ic1_en, ic2_en, ic3_en]

    const _setList = (icon) => {
        return [
            {
                icon: icon[0],
                text: 'LOOK_BOOK_ITEM_1',
                url: '/lookbook/summer-remix'
            },
            {
                icon: icon[1],
                text: 'LOOK_BOOK_ITEM_2',
                url: '/lookbook/top-4-hairstyle-for-kids'
            },
            {
                icon: icon[2],
                text: 'LOOK_BOOK_ITEM_3',
                url: '/lookbook/mohican-hot-trend'
            }
        ]
    }

    const [ListData, setListData] = useState(i18n.language.includes("en") ? _setList(listIconTh) : _setList(listIconEn));

    useEffect(() => {
        if (i18n.language.includes("en")) {
            setListData(_setList(listIconEn));
        } else {
            setListData(_setList(listIconTh));
        }
    }, [i18n.language])


    return (
        <div className="fashion-30shine">
            <div className="header">{t("LOOK_BOOK")}</div>
            <MySwiper spaceBetween={10} large={2.2} medium={2.2} small={1.3} >
                {ListData.map((item, key) => {
                    return (
                        <div className="item" key={key}>
                            <Link to={item.url}>
                                <img src={item.icon} alt="Icon" style={{ width: '100%' }}></img>
                            </Link>
                            <div className="text" ><span>{t(item.text)}</span></div>
                        </div>
                    );
                })}
            </MySwiper>
        </div>
    )
}

export default Fashion30Shine;