export const Booking_success_en = {
    "THANK_YOU_START": "Thank you",
    "THANK_YOU_END": "so much for choosing 30Shine!",
    "CHECK_GOOGLE_MAP": "(Check on google map)",
    "MAP_CAR": "Car parking",
    "GO_TO_10": "(You should come 10 minutes earlier than booking time)",
    "GOOD_STYLIST": "30Shine already provided you the best stylist",
    "SAVE_IMAGES": "30Shine saved photo of your last haircut, if you want to keep your previous hair style, just ask the stylist!",
    "IF_FUNNY": "If you are satisfied with the haircut, 30Shine would love to take photos to recreate your haircut more precisely next time.",
    "VOUCHER_SUCCESS": "Your promotion code is successfully activated. Our system will automatically recognize and apply the code when you checkout at the store.",
    "DAY": "day",
    "HOUR": "hour",
    "MINUTES": "minutes",
    "SECONDS": "seconds",
    "TIME_START": "Your service will come within",
    "TIME_END": "",
    "CHANGE_BOOKING": "CHANGE THE BOOKED TIME",
    "DELETE_BOOKING": "CANCEL BOOKING",
    "CHANGE_TIME": "DO YOU WANT TO PICK ANOTHER TIME?",
    "DELETE_COMFIRM": "Confirm your cancellation",
    "BOOKING_SUCCESS_LI1": "You should take notice of the following:",
    "BOOKING_SUCCESS_LI2_START": "- If you come",
    "BOOKING_SUCCESS_LI2_END": "late, 30Shine will reset your booking into another time or another day. Please inform to the receptionist to set another schedule.",
    "BOOKING_SUCCESS_LI3": "- This booking applies only to you. Should you have any friends coming along, please go back to the booking page to book for each individual.",
    "BOOKING_SUCCESS_LI4_START": "- Time of service would be",
    "BOOKING_SUCCESS_LI4_END": " later than the booked time.",
    "SUCCESS": "Success",
    "ERROR": "Error",
    "IS_CHANGE_BOOKING": "Change booking",

    BOOKING_SUCCESS_BOTTOM: "Tens of thousands men is becoming more handsome thanks to these 2 services<br/> 30Shine would love you to try it",
}

export const Booking_success_th = {
    "THANK_YOU_START": "ขอบคุณ",
    "THANK_YOU_END": "ที่ให้โอกาส 30Shine ได้บริการ!",
    "CHECK_GOOGLE_MAP": "(ดูแผนที่)",
    "MAP_CAR": "ที่จอดรถ",
    "GO_TO_10": "(หวังว่าคุณจะมาถึงก่อนเวลา 10 นาทีเพื่อให้เราสามารถให้บริการคุณอย่างเต็มที่ )",
    "GOOD_STYLIST": "30Shine ได้จัดสไตลิสต์ที่ดีที่สุดไว้สำหรับคุณแล้ว",
    "SAVE_IMAGES": "30Shine ได้เก็บภาพครั้งก่อนของคุณไว้ คุณต้องการตัดผมทรงเดิมสามารถบอกสไตลิสต์ได้เลย!",
    "IF_FUNNY": "ถ้าคุณพอใจกับทรงผม 30Shine  ขออนุญาตถ่ายภาพเก็บไว้ ครั้งหน้า บริการคุณได้ดีกว่านี้",
    "VOUCHER_SUCCESS": "รหัสของคุณถูกเปิดใช้งานสำเร็จแล้ว ระบบของฉันจะเก็บข้อมูล โปรโมชั่นพิเศษ อัตโนมัติ ให้คุณเมื่อคุณชำระเงินที่ร้าน!",
    "DAY": "วัน",
    "HOUR": "ชั่วโมง",
    "MINUTES": "นาที",
    "SECONDS": "วินาที",
    "TIME_START": "เหลือ",
    "TIME_END": "วินาที ถึงเวลาตัดผมของคุณ!",
    "CHANGE_BOOKING": "เปลี่ยนเวลาจอง",
    "DELETE_BOOKING": "ยกเลิก",
    "CHANGE_TIME": "คุณอยากเปลี่ยนเป็นช่วงเวลาอื่นไหม?",
    "DELETE_COMFIRM": "ยืนยันการยกเลิก",
    "BOOKING_SUCCESS_LI1": "หมายเหตุ!",
    "BOOKING_SUCCESS_LI2_START": "- หากมาช้าเกิน",
    "BOOKING_SUCCESS_LI2_END": "ทางเราจะเปลี่ยนเป็นช่วงเวลาอื่นหรือวันอื่นให้คุณ หวังว่าคุณจะเข้าใจและแจ้งกับพนักงานต้อนรับเพื่อจองเวลาอื่น",
    "BOOKING_SUCCESS_LI3": "- การจองนี้เฉพาะคุณเท่านั้น หากคุณมีเพื่อนมาด้วยโปรดกลับไปที่หน้าการจองเพื่อจองรายบุคคล",
    "BOOKING_SUCCESS_LI4_START": "- เวลาบริการอาจช้ากว่าเวลานัดหมาย",
    "BOOKING_SUCCESS_LI4_END": "",
    "SUCCESS": "ความสำเร็จ",
    "ERROR": "ความผิดพลาด",
    "IS_CHANGE_BOOKING": "เปลี่ยนเวลาจอง",

    BOOKING_SUCCESS_BOTTOM: "รวมเป็นหมื่นพี่น้องหล่อขึ้นจากการบริการใหม่จาก2 บริการใหม่นี้ </br>30shine  เชิญคุณลองดู",

}