export const Kid_en = {
    "KID_TOP_4": "Top 4 Cutest hairstyles for boys",
    "KID_PARENTS": "Parents often opt for crew cut hairstyle for their son since it gives their boys simple and boyish look. However, these parents might not know about many other handsome-looking and cute hairstyles that will definitely make their son shine. Let's have a look at the magical ways that 30Shine do to \"witchcraft\" your sons's hairlook!",
    "KID_SUPPER": "Super Korean-looking with LAYERED HAIR",
    "KID_SHOULD_YOU": "Should you want your boys to have a cute and childlike look, you should check out layered hairstyle now.",
    "KID_THE_NEADTLY": "The neatly trimmed layered hairstyle will embrace the face, making your boys look more chubby and lovely. If you want to boost the cuteness tenfold, just add some volumes to your son' s hair by doing a few Korean-looking loose curls.",
    "KID_STYLIST_MAN_BUM": "Stylish Man Bun, why not?",
    "KID_CUTE_WAVY": "Cute wavy-curly hair is always a common and safe hairstyles for baby boys. However, should parents want their boys to be more dynamic and rebellious, it is 30Shine's tips to transform traditional curly hair into cool and trendy man bun hairstyles. ",
    "KID_HOW": "How? Easy as a pie! Our professional 30Shine's stylists do the haircut on the back and sides neatly, highlight by some boyish \"tattoos\" which would be not only lovely but also suitable for their age.",
    "KID_TIE_ALL": "Tie all the curls and your boy will possess a creative, European-like street style of man bun hair. ",
    "KID_THE_BIGGEST": "The biggest advantage of this hairstyle is its flexibility. When bun is tied, your boy looks cool. When bun is untied, your boy looks cute in curls. When wearing the sun glasses and dashing streetwear outfit, your boy would definitely be the center of attention. ",
    "KID_BECOME": "Become a star with Side Part hairstyle",
    "KID_SHOULD_YOU_WANT": "Should you want your boys to be a star, check out the trendy Side part hairstyle.",
    "KID_WITH_SIDE": "With Side Part haircut, the back and sides are clipped neatly. Hair on top is trimmed to a medium length, occasionally 5-7 cm in length, making boys not only look handsome but also comfortable for every activity. Side Part hairstyle often involves the act of separating hair into 2 parts. It depends on your boy' s face shape that our 30Shine stylists will make it with ratio of either 3-7 or 4-6.",
    "KID_DASHING": "Dashing Quiff hairstyle",
    "KID_GENTLEMEN": "Like gentlemen, Quiff's hairstyle is also a hair trend that is causing fever for baby boys today. Just imagine a little bit, Mom and Dad. Your boy in Quiff hairstyle is wearing a really dashing suit, how mature and lovely he is! Surely, not only parents but people around will be surprised!",
    "KID_BESIDES": "Besides, Quiff hairstyle is favored by parents since the sides are clipped neatly and top hair is at medium length, which brings the comfort and confidence for energetic boys.",
    "KID_VISIT_30SHINE": "Visit 30Shine and your boy will shine!",
    "KID_A_HANDSOME": "A handsome hairlook and a striking appearance not only boost boys' confidence in public but also give their parents a sense of pride.",
    "KID_WHY_KID_LOVE": "Why kids love doing the haircut at 30Shine?",
    "KID_AFTER": "After having the haircut, all kids look lovely and stunning",
    "KID_FAMOUS": "Famous singers, actors choose to have their haircut at 30Shine",
    "KID_OUR_30SHINE": "Our 30Shine stylists and skinners are so friendly that kids feel like at home. Do the haircut is no longer a fight for kids, but a truly happy and relaxing moment. ",
    "KID_5_STEPS": "5-step Kid Combo has the same process as for adult",
    "KID_SHAMPOO": "Shampoo hair to make it soft and clean",
    "KID_ADVISE": "Advise on choosing perfect hairstyles",
    "KID_DO_THE": "Do the haircut",
    "KID_DO_POST": "Do post haircut washing for relaxing feeling all day",
    "KID_HAIRSTYLE": "Hairstyle with wax",
    "KID_PARENTS_NO": "Parents no longer have to worry about making time to have kids to the salon. With our unique booking service at 30shine.com, whether it is weekday or weekend, parents and kids are provided best services at any time of arrival. The worrisome of parents' tight schedule giving no time to take kids to the salon has gone for good!",
    "KID_HEY_MOM": "Hey Mom and Dad, let's come to 30Shine to make kids shine now!",

}



export const Kid_th = {
    "KID_TOP_4": " 4 อันดับทรงผมน่ารักสำหรับเด็กผู้ชาย",
    "KID_PARENTS": "ผู้ปกครองที่มักจะตัดผมให้เด็กด้วยทรงผมที่ทั้งง่ายและดูแข็งแรง แต่ผู้ปกครองรู้ไหมว่ายังมีทรงผมที่สวยงามและน่ารักมากมายเพื่อให้เด็กๆหล่อเปล่งประกาย ผู้ปกครองต้องมาดูว่าที่ 30Shine ตัดผมให้เด็กๆแบบไหน!",
    "KID_SUPPER": "ลุคเกาหลีด้วยทรงผม LAYER ",
    "KID_SHOULD_YOU": "หากคุณต้องการให้ลูกของคุณดูน่ารักและไร้เดียงสาเหมาะสมกับวัยของพวกเขาคุณควรเลือกทรง layer ",
    "KID_THE_NEADTLY": "ทรงผม layer ที่ตัดอย่างประณีต เหมาะสมกับใบหน้าทำให้เด็กดูอวบอ้วนน่ารักมากขึ้น หากต้องการเพิ่มระดับความน่ารัก 10 เท่าผู้ปกครองเพียงแค่ม้วนผมเบาๆ ให้เด็กจะทำให้เด็กมีสไตล์เหมือนหนุ่มเกาหลี",
    "KID_STYLIST_MAN_BUM": "ทำไมจะสร้างเอกลักษณ์ด้วยทรง Man Bun ไม่ได้หล่ะ?",
    "KID_CUTE_WAVY": "ผมหยักศกน่ารักเป็นทรงผมธรรมดาๆไม่มีอะไรพิเศษ แต่ถ้าผู้ปกครองต้องการให้เด็กๆดูซุกซนมากขึ้น 30Shine จะบอกเคล็ดลับในการเปลี่ยนทรงผมแบบดั้งเดิมให้กลายเป็นทรงผม man bun สุดเท่ห์และทันสมัย",
    "KID_HOW": "จะทำแบบไหนหรอ? ง่ายมากเลย! สไตลิสต์มืออาชีพที่ 30Shine จะตัดผมข้างหลังและด้านข้างทั้ง 2 ข้างให้เรียบร้อยและเป็นระเบียบเพื่อสร้างจุดเด่นด้วย \"ลวดลาย\" ที่แข็งแกร่งแต่ยังคงน่ารักเหมาะสมกับวัย",
    "KID_TIE_ALL": "เมื่อมัดผมที่เป็นลอนเด็กแๆ จะดูเป็นเด็กที่มีความคิดสร้างสรรค์เหมือนชาวยุโรปที่ทำผมทรง Man Bun",
    "KID_THE_BIGGEST": "ข้อดีของทรงผมนี้คือความยืดหยุ่น เมื่อมัดผมเด็กๆจะดูเท่ เมื่อปล่อยผมเด็กๆจะดูน่ารักผมจะเป็นลอนสวย เมื่อสวมแว่นกันแดดและชุดสตรีทแฟชั่นเด็กๆจะกลายเป็นที่สนใจทันที",
    "KID_BECOME": "มาเป็นดารากับทรงผม Side Part",
    "KID_SHOULD_YOU_WANT": "หากคุณต้องการให้ลูกของคุณดูเหมือนดารา คุณควรเลือกทรงผม Side Part ให้เด็กๆ",
    "KID_WITH_SIDE": "ตัดผมทรง Side Part ด้านข้างทั้ง 2 ข้างและส่วนท้ายทอยจะตัดสูงขึ้น ส่วนบนจะถูกตัดให้มีความยาวปานกลาง (5-7 ซม.) ทำให้เด็กดูทั้งหล่อและเรียบร้อยเพื่อให้เด็กๆเคลื่อนไหวได้อย่างอิสระ เมื่อทำผมทรง Side Part สไตลิสต์ของ 30Shine จะแบ่งผมของเด็กออกเป็น 2 ส่วนคือ 3 - 7 หรือ 4 - 6 ขึ้นอยู่กับใบหน้าของเด็กแต่ละคน",
    "KID_DASHING": "ทรงผมสไตล์ Quiff",
    "KID_GENTLEMEN": "ดูเป็นสุภาพบุรุษกับทรงผม Quiff ที่เป็นเทรนด์ผมที่เด็กผู้ชายให้ความสนใจเป็นอย่างมาก ผู้ปกครองพยายามจินตนาการถึงทรงผม Quiff ของลูกชายพร้อมสวมชุดสูทสุดเท่ดูเป็นผู้ใหญ่และน่ารักแค่ไหน รับประกันได้เลยว่าทรงผมนี้จะทำให้คุณและทุกคนจะตกตะลึงกับทรงผมของเด็ก!",
    "KID_BESIDES": "นอกจากนี้ทรงผม Quiff ยังได้รับความนิยมจากผู้ปกครองเพราะว่าผมทั้ง 2 ข้างถูกตัดสั้นและผมด้านบนยาวซึ่งให้ความสะดวกสบายแก่เด็กๆและดูมีความซุกซนไปในตัว",

    "KID_VISIT_30SHINE": "อยากหล่อเปล่งประกายต้องมาที่ 30Shine",
    "KID_A_HANDSOME": "ทรงผมและรูปลักษณ์ที่สวยงามจะนำไปสู่การฝึกฝนความกล้าหาญและความมั่นใจให้แก่เด็กๆ ไม่เพียงเท่านั้นเด็กยิ่งหล่อพ่อแม่ยิ่งรู้สึกดีและภาคภูมิใจที่มีลูกที่น่ารักๆแบบนี้",
    "KID_WHY_KID_LOVE": "เหตุผลที่ทำให้เด็กๆชอบตัดผมที่ 30Shine: ",
    "KID_AFTER": "หลังจากที่เด็กได้ตัดผมแล้วจะดูหล่อและน่ารักขึ้นทันทีใครเห็นก็ต้องมอง",
    "KID_FAMOUS": "นักร้องและนักแสดงชื่อดังต่างก็ให้ความเชื่อถือและเลือกที่จะตัดผมที่ 30Shine",
    "KID_OUR_30SHINE": "สไตลิสต์และสกินเนอร์ของเรามีความเป็นมิตรมากจนเด็กๆไม่รู้สึกอยากกลับบ้าน การไปตัดผมจึงไม่ต้องต่อสู้กับเด็กๆอีกต่อไป แต่จะเป็นช่วงเวลาที่มีความสุขและผ่อนคลายของเด็กๆ",
    "KID_5_STEPS": "Kid Combo 5 ขั้นตอนเหมือนผู้ใหญ่",
    "KID_SHAMPOO": "สระผมให้ผมนุ่มและสะอาด",
    "KID_ADVISE": "ให้คำแนะนำในการเลือกทรงผม",
    "KID_DO_THE": "ตัดผม",
    "KID_DO_POST": "สระผมให้เด็กช่วยให้เด็กไม่คัน สบายและเล่นสนุกได้ตลอดวัน",
    "KID_HAIRSTYLE": "จัดทรงด้วย WAX",
    "KID_PARENTS_NO": "ผู้ปกครองสบายใจได้เกี่ยวกับเรื่องการจัดการเวลาเพื่อพาเด็กๆไปตัดผม ด้วยเทคโนโลยีการจองคิวที่มีเฉพาะที่ 30shine.com เท่านั้น ไม่ว่าจะเป็นวันธรรมดาหรือวันหยุดสุดสัปดาห์ผู้ปกครองและเด็กๆจะได้รับบริการที่ดีที่สุดตลอดเวลาที่เดินทางมาถึงเมื่อถึงเวลาตัดก็ได้ตัดเลยไม่ต้องรอคิวนาน ไม่ต้องกังวลว่าจะไม่มีเวลาพาเด็กๆไปตัดผม!",
    "KID_HEY_MOM": "คุณพ่อคุณแม่พาลูกๆมาที่ 30Shine ตอนนี้ได้เลย เพื่อทำให้ลูกของคุณหล่อและเปล่งประกาย!",
}
