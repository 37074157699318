export const Mohican_remix_en = {
    MOHICAN_TEXT_1: "If you are looking for a handsome-looking hairstyle for this summer,<br/> then top-picked Mohican will never let you down.",
    "MOHICAN_TEXT_2": "THE HAIRSTYLE CREATING A CRAZE AMONG THE YOUTH 2019",
    "MOHICAN_TEXT_3": "Mohican is the hairstyle that both sides of the head are clean shaven, its medium-length fringe is styled to have volumes and made to fall forward.",
    "MOHICAN_TEXT_4": "This modern-looking and dynamic hairstyle is setting the hair trend this 2019 summer",
    "MOHICAN_TEXT_5": "THE BEST SUITED MEN'S HAIR FOR ASIAN SO FAR",
    "MOHICAN_TEXT_6_1": "Mohican is by far the most suited hairstyle for Asian men's faces. Whether it is chubby, square or round face, you will all shine in Mohican hairstyle.",
    "MOHICAN_TEXT_6_2": "By its high and tight cut, Mohican gives men cool feeling, especially in the hot and humid weather of summer.",
    "MOHICAN_TEXT_6_3": "In addition, the fringe is styled up to make it volumious, which helps men hack 3-5 cm of height, therefore boost their confidence.",
    "MOHICAN_TEXT_7": "\"Whether you have chubby, square or round face, you will all shine!\"",
    "MOHICAN_TEXT_8": "Be the trendsetter of men's hair in 2019 summer",
    "MOHICAN_TEXT_9": "At 30Shine, every 8 out of 10 our clients asks for Mohican hairstyle, which proves the attractiveness of this trendy hairstyle.",
    "MOHICAN_TEXT_10": "Not only is it dynamic for hanging out",
    "MOHICAN_TEXT_11": "but it still keep formality for school and workplace",
    "MOHICAN_TEXT_12": "Short and dynamic hairlook of Mohican is suitable for every outfit and context. Not only is it formal and appropriate for workplaces but it is also truly active for hang-outs. If you are student boys, the school supervisors would also be \"persuaded\" by its neat and formal look.",
    "MOHICAN_TEXT_13": "Be cool",
    "MOHICAN_WHEN_MIXED": "when mixed with fashionable hair colors",
    "MOHICAN_ANY_FASHIONABLE": "Any fashionable hair colors can perfectly match with Mohican.",
    "MOHICAN_WANNA_BE_A_DASHING": "Wanna be a dashing, charming guy, get Mohican in dark brown, smoke brown colors.",
    "MOHICAN_WANNA_BE_A_COOL": "Wanna be a cool man, get Mohican in platinum blonde or fashionable light smoke colors",
    "MOHICAN_WANNA_A_MOHICAN": "Wanna a Mohican haircut",
    "MOHICAN_VISIT_30SHiNE": "Visit 30Shine",
    "MOHICAN_IT_IS_30SHINE": "It is 30Shine that initiates the Mohican hair trend by million-view videos on YouTube. 30Shine provides Mohican haircut services every hour, every day, till the arms getting fatigue ... so we are so confident in making you handsome. ",
    "MOHICAN_WITH_HIGHT_1": "With high salary offers, only 30Shine can recruit top-notch male hairdressers throughout the country. Our stylists are constantly trained on new male hair techniques and trends.",
    "MOHICAN_WITH_HIGHT_2": "When you go to some local hair salons, sometimes you will be mistaken for your desired hairstyles and get the bad ones. But when coming to 30Shine, just order \"Mohican hairstyle, please\" and you will definitely get the most handsome, standard Mohican hairlook.",
    "MOHICAN_WITH_HIGHT_3": "Nowhere else can you find the dedication of stylists in instructing customers on how to style with wax like at 30Shine. A quick, simple styling techniques but 100% warranty of stunning hairlook",
    "MOHICAN_3_RESONS": "3 REASONS WHY 30SHINE IS A MUST-VISIT PLACE",
    "MOHICAN_7_STEPS": "7-step Combo with only 100k",
    "MOHICAN_7_STEPS_1": "FACIAL WASH",
    "MOHICAN_7_STEPS_2": "HIGH-TECH FACIAL SKIN CARE",
    "MOHICAN_7_STEPS_3": "HAIR SHAMPOOING & SCALP MASSAGE",
    "MOHICAN_7_STEPS_4": "ADVISORY",
    "MOHICAN_7_STEPS_5": "HAIRCUT",
    "MOHICAN_7_STEPS_6": "POST HAIRCUT WASH",
    "MOHICAN_7_STEPS_7": "HAIRSTYLING WITH WAX",
    "MOHICAN_FREE_HAIR": "Free hair correction within 7 days, discount offered for the delay...warranty services to be found nowhere else but 30Shine.",
    "MOHICAN_VISIT_30SHINE_NOW": "VISIT 30SHINE NOW TO SHINE WITH HANDSOME-LOOKING MOHICAN HAIRSTYLE THIS 2019 SUMMER!"
}



export const Mohican_remix_th = {
    "MOHICAN_TEXT_1": "“หากคุณกำลังมองหาทรงผมที่หล่อสำหรับช่วงฤดูร้อนทรงผม Mohican ก็เป็นตัวเลือกอันดับ 1”",
    "MOHICAN_TEXT_2": "ทรงผมที่วัยรุ่นนิยมทำที่สุดในปี 2019",
    "MOHICAN_TEXT_3": "Mohican เป็นทรงผมที่มีผมข้างหน้ายาวปานกลางเซ็ตขึ้นเทไปข้างหน้าและด้านข้างทั้ง 2 ข้างตัดสั้น",
    "MOHICAN_TEXT_4": "ทรงผมที่มีความแข็งแรงและทันสมัยนี้กำลังเป็นเทรนด์ในฤดูร้อนปี 2019",
    "MOHICAN_TEXT_5": "ทรงผมที่เหมาะกับผู้ชายเอเชียตั้งแต่อดีตจนถึงปัจจุบัน",
    "MOHICAN_TEXT_6_1": "ยังไม่เคยมีทรงผมที่เหมาะกับใบหน้าของผู้ชายเอเชียมาก่อนถึงขนาดนี้ ไม่ว่าจะใบหน้าใหญ่ แหลม เหลี่ยม กลม ... ต่างก็หล่อมากเมื่อตัดผมทรง Mohican",
    "MOHICAN_TEXT_6_2": "การตัดผมสั้นสูงและเรียบร้อยของ Mohican ยังสร้างความรู้สึกเย็นสบายเหมาะกับสภาพอากาศร้อนในฤดูร้อน",
    "MOHICAN_TEXT_6_3": "และยังไม่หมดเพียงเท่านั้น ผมข้างหน้าของทรง Mohican ที่จัดทรงสูงช่วยให้ผู้ชายดูสูงขึ้น 3 - 5 ซม. เพิ่มมั่นใจเมื่อเดินกับเพื่อนๆ!",
    "MOHICAN_TEXT_7": "\"ไม่ว่าจะมีใบหน้าใหญ่ เหลี่ยมหรือกลมต่างก็สวยเหมือนกัน\"",
    "MOHICAN_TEXT_8": "เป็นเทรนด์ของผมผู้ชายในฤดูร้อนปี 2019",
    "MOHICAN_TEXT_9": "ที่ 30Shine ลูกค้าของเราทุก 8 จาก 10 คนจะถามถึงทรงผม Mohican ซึ่งพิสูจน์ให้เห็นถึงความร้อนแรงของทรงผมนี้",
    "MOHICAN_TEXT_10": "กระฉับกระเฉงเมื่อออกไปข้างนอก",
    "MOHICAN_TEXT_11": "แต่ยังคงดูจริงจังเมื่อไปโรงเรียนและไปทำงาน",
    "MOHICAN_TEXT_12": "\"ทรงผมสั้นและดูมีความกระฉับกระเฉงของ Mohican เหมาะสำหรับทุกชุดและทุกสถานการณ์ ไม่ว่าจะลุคสุขุมหรือสุภาพเมื่อออกไปทำงาน แต่ดูกระฉับกระเฉงเมื่อออกไปข้างนอก หากคุณอยู่ในโรงเรียนทรงผมนี้ก็เป็นที่น่า \"พอใจมาก\"สำหรับบรรดาอาจารย์เพราะดูเรียบร้อย\"",
    "MOHICAN_TEXT_13": "เท่มาก",
    "MOHICAN_WHEN_MIXED": "เมื่อรวมกับสีผมแฟชั่น",
    "MOHICAN_ANY_FASHIONABLE": "ไม่ว่าจะผมสีใดก็ตามจะกลายเป็นคู่ที่สมบูรณ์แบบทันทีเมื่อตัดผมทรง Mohican",
    "MOHICAN_WANNA_BE_A_DASHING": "ต้องการที่จะโรแมนติกและมีเสน่ห์ควรตัดผมทรง Mohican พร้อมทำผมสีน้ำตาลควันบุหรี่หรือสีน้ำตาลเข้ม",
    "MOHICAN_WANNA_BE_A_COOL": "หากคุณต้องการลุคเท่ๆให้ทำผมทรง Mohican ย้อมสีด้วยสีแพลตทินัมหรือควันสว่างจะดูอินเทรนด์ทันที",
    "MOHICAN_WANNA_A_MOHICAN": "อยากตัดทรง Mohican",
    "MOHICAN_VISIT_30SHiNE": "ต้องมาที่ 30shine",
    "MOHICAN_IT_IS_30SHINE": "30Shine เป็นที่เริ่มต้นเทรนด์ทรงผม Mohican ด้วยวิดีโอนับล้านบน YouTube 30Shine ตัดผมทรง Mohican ทุกๆชั่วโมงทุกวันตัดจนเมื่อยมือ ... มั่นใจได้เลยว่าเราจะตัดผมที่หล่อที่สุดให้คุณ",
    "MOHICAN_WITH_HIGHT_1": "ด้วยเงินเดือนที่สูงมาก มีเพียง 30Shine เท่านั้นที่รวบรวมสไตลิสต์มืออาชีพทั่วประเทศและยังได้รับการฝึกฝนอย่างต่อเนื่องเกี่ยวกับเทคนิคและเทรนด์ใหม่ในการทำผมชาย",
    "MOHICAN_WITH_HIGHT_2": "ตัดผมจากร้านอื่น คุณอธิบายทรงที่คุณต้องการไปแต่ช่างไม่เข้าใจบางครั้งตัดผิด แต่หากคุณไปที่ 30Shine เพียงแค่ตะโกน \"Mohican\" คุณจะได้รูปแบบที่ดีที่สุดมาตรฐาน Mohican",
    "MOHICAN_WITH_HIGHT_3": "ไม่มีที่ไหนที่มีสไตลิสต์แนะนำการจัดทรงโดยการใช้ WAX อย่างเป็นกันเองเหมือนที่ 30Shine จัดทรงด้วย WAX สุดง่ายและรวดเร็วรับประกันได้เลยว่าคุณจะหล่ออย่างแน่นนอน",

    "MOHICAN_3_RESONS": "อีก 3 เหตุผลที่คุณต้องมาที่ 30SHINE",
    "MOHICAN_7_STEPS": "Combo 7 ขั้นตอนที่ดีที่สุดในราคาเพียง 100k เท่านั้น:",
    "MOHICAN_7_STEPS_1": "ล้างหน้า",
    "MOHICAN_7_STEPS_2": "การดูแลใบหน้าด้วยเทคโนโลยีขั้นสูง",
    "MOHICAN_7_STEPS_3": "สระผมและนวดศีรษะ",
    "MOHICAN_7_STEPS_4": "ให้คำปรึกษา",
    "MOHICAN_7_STEPS_5": "ตัดผม",
    "MOHICAN_7_STEPS_6": "สระผม",
    "MOHICAN_7_STEPS_7": "จัดทรงด้วย WAX",
    "MOHICAN_FREE_HAIR": "แก้ไขทรงผมฟรีภายใน 7 วัน หากคุณรอนานคุณจะได้ส่วนลดทันที ... บริการรับประกันที่ไม่มีที่ไหนกล้าทำยกเว้นที่ 30Shine",
    "MOHICAN_VISIT_30SHINE_NOW": "มาที่ 30SHINE ตอนนี้เพื่อตัดผมทรง MOHICAN ให้หล่อและเปล่งประกายน่าดึงดูดในฤดูร้อน 2019 นี้!"

}
