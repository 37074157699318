export const Echosline_th = {
    "EC_ITEM1_1": "ทำสีผมอย่างมีระดับด้วยผลิตภัณฑ์ ECHOSLINE",
    "EC_ITEM1_2": "มาที่ 30shine ตอนนี้",
    "EC_ITEM1_3": "การทำสีผมด้วยสีย้อมผมระดับไฮเอนด์ระดับโลกของ Echosline โดยช่างทำผมมืออาชีพที่ 30Shine ราคาเพียง 289k แม้แต่ลูกค้าที่เอาใจยากยังรู้สึกพึงพอใจ!",

    "EC_ITEM2_1": "แบรนด์ชั้นนำของโลก",
    "EC_ITEM2_2": "ด้วยประวัติการพัฒนากว่า 20 ปี Echosline เป็นหนึ่งในแบรนด์พรีเมี่ยมที่สุดในอุตสาหกรรมแฟชั่นผม ผลิตภัณฑ์แต่ละชิ้นของ Echosline ได้รับการค้นคว้าอย่างกระตือรือร้น เปิดตัวโดยช่างทำผมชั้นนำของอิตาลี ดังนั้น Echosline จึงได้รับความไว้วางใจและเป็นที่ชื่นชอบจากทั่วทุกมุมโลกเสมอ เป็นผลิตภัณฑ์ชั้นนำในร้านเสริมสวยระดับสูง",
    "EC_ITEM2_3": "ด้วยเป้าหมายในการช่วยเหลือผู้ชายชาวไทยในการอัพเดทแฟชั่นที่เร็วที่สุดในโลก ร้านตัดผมในเครือ 30Shine เป็นเพียงร้านเดียวในเอเชียแปซิฟิกที่ใช้ผลิตภัณฑ์สีย้อมผมคุณภาพสูงจากอิตาลี Echosline",

    "EC_ITEM3_1": "ทำไมถึงควรเลือก Echosline ?",
    "EC_ITEM3_2": "สีย้อมผม Echosline - ผลิตภัณฑ์ย้อมผมคุณภาพสูงอันดับ 1 ในอิตาลี - คือความลับที่จะช่วยให้ผู้ชายมีสไตล์ผมทันสมัยอินเทรนด์และนำแฟชั่น ",
    "EC_ITEM3_3": "ผลิตภัณฑ์ย้อมผม Echosline ให้สีที่แน่นอนตรงกับความต้องการผมเงางามอยู่เสมอ สำหรับ 30Shine - การย้อมผมด้วยผลิตภัณฑ์ย้อมผม Echosline จะไม่เกิดการเบี่ยงเบนของสีหรือสีเพี้ยนอย่างแน่นอน ",
    "EC_ITEM3_4": "ไม่ต้องกังวลเรื่องผมเสียหรือผมแห้งอีกต่อไป Echosline เป็นผลิตภัณฑ์ย้อมผมตัวแรกที่จะช่วยฟื้นฟูเส้นผมจากภายในสู่ภายนอกด้วยสารสำคัญของวิตามินซี น้ำมันมะพร้าวและขี้ผึ้ง ",
    "EC_ITEM3_5": "เมื่อรวมกับความสามารถของสไตลิสต์ที่ 30Shine เพียงแค่ย้อมหนึ่งครั้งสีจะคงอยู่นานหลายเดือนและสวยงามเสมอเมื่อก้าวออกจากร้าน",

    "EC_ITEM4_1": "ผลิตภัณฑ์สีย้อมผม ECHOSLINE",
    "EC_ITEM4_2": "เป็นผู้นำเทรนด์ที่ 30SHINE"
}

export const Echosline_en = {
    "EC_ITEM1_1": "Premium Hair Coloring Echosline",
    "EC_ITEM1_2": "Visit 30Shine now",
    "EC_ITEM1_3": "Only with 289k can you use high-quality hair dye from top world brand Echosline as well as receive top-notch services from skilled barbers at 30Shine, even the most difficult customers feel satisfied.",

    "EC_ITEM2_1": "World's Leading Brand",
    "EC_ITEM2_2": "With 20 years of establishment, Echosline is one of the most preminum brands in the hair fashion industry. Each product of Echosline is researched enthusiastically, launched by the leading hairdressers of Italian fashion capital. Therefore, Echosline is always trusted and favored all over the world and constantly on top shelf of high-end salons.",
    "EC_ITEM2_3": "With the goal of helping Thai men update the latest trend in the world fashion, 30Shine is the only haircut chain in Asia-Pacific area exclusively using the high-class Echosline color chart from Italy.",

    "EC_ITEM3_1": "Why should you choose Echosline?",
    "EC_ITEM3_2": "Echosline hair color - The Number 1 Italian hair color restorer - is the secret that helps men become trendsetters owning fashionable, trendy hair styles.",
    "EC_ITEM3_3": "Echosline hair colors offer absolute even coverage, leave hair shiny. Come to 30Shine to have your hair dyed with Echosline products, there is no chance you leave the salon with the wrong shade.",
    "EC_ITEM3_4": "No more worries about dry or damaged hair since Echosline is the first hair color to help restore damaged hair from inside out by Vitamin C essence, essences extracted from coconut oil and beeswax.",
    "EC_ITEM3_5": "Together with the professionalism of 30Shine Stylishes, men are always satisfied with distinctive fade-resistant feature that keeps true color for months and gives a constant like-new look.",

    "EC_ITEM4_1": "ECHOSLINE HAIR COLOR CHART",
    "EC_ITEM4_2": "BE A TRENDSETTER AT 30SHINE"
}


