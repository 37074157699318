const configV2_STD =
{
    BASE_URL: "https://std-api-booking.30shine.com/",
    ____Comment: "___________________________________________",
    getAllSalonFilter: "api-booking/salon/salon-filter",
    checkBooking: "api-booking/customer/check-book",
    smsRequest: "api-booking/fifteen-minutes/sms-request",
    verifySmsRequest: "api-booking/fifteen-minutes/verify-sms-request",
    GetHousByDate: "api-booking/fifteen-minutes/book-hours",
    getTopLike: "https://5uf927vfz0.execute-api.ap-southeast-1.amazonaws.com/Prod/api/stylemaster/top-like-in-month-pagesize-4",
    getShineTV: "api-maketing/video",
    getPer_Page: "wp-json/wp/v2/posts",
    getAllDistrict: "api-booking/salon",
    getAllProvince: "api-booking/city/list-city",
    getDistrictById: "api-booking/district",
    getStyListInfo: "api-booking/stylist-info",
    getSalonSuggestion: "api-booking/fifteen-minutes/salon-near/book-hours",
    getSalonNear: "/api-booking/fifteen-minutes/salon-near-customer/book-hours",
    GetSalonById: "/api-booking/salon/",
    GetAllSalonById: "/api-booking/salon",
    setVoucher: "api-booking/marketing-campaign/voucher",
    checkCampaign: "api-booking/marketing-campaign",
    booKing: "api-booking/fifteen-minutes/booking",
    upDateBooKing: "api-booking/fifteen-minutes/booking/",
    deleteBooKing: "api-booking/booking/",
    getInfoBooKing: "api-booking/fifteen-minutes/",
    checkOtp: "/api-booking/customer/verify-otp-booking",
    requesOtp: "/api-booking/customer/phone/otp",
    GetSalonOff: "api-booking/salon/salon-off",
    putTracking: "https://0z5i7bxrof.execute-api.ap-southeast-1.amazonaws.com/Prod/api-tracking",
    postTracking: "https://0z5i7bxrof.execute-api.ap-southeast-1.amazonaws.com/Prod/api-tracking",
    getServiceStep3: "/api/combo/step3",
    getQuestions: "https://std-api-tracking.30shine.com/api/customer-question",
    postAswers: "https://std-api-tracking.30shine.com/api/customer-question/answers",
    getStyleMaster: "https://5uf927vfz0.execute-api.ap-southeast-1.amazonaws.com/Prod/api/stylemaster/top-like-in-month-pagesize-1000",
    getStyleMasterMonth: "https://5uf927vfz0.execute-api.ap-southeast-1.amazonaws.com/Staging/api/stylemaster/top-like-three-month",
    getAllStyList: "api-booking/stylist",
    getBlogHome: "https://5uf927vfz0.execute-api.ap-southeast-1.amazonaws.com/Prod/wp-json/wp/v2/posts?per_page=3",
    // getBlogHome: "https://5uf927vfz0.execute-api.ap-southeast-1.amazonaws.com/API_Blog4V3/wp-json/wp/v2/posts?per_page=3",
    getShineMember: "api-booking/customer/history-booking"
}

export default configV2_STD;