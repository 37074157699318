
export const en = {
    //--Menu
    "HOME": "Home",
    "SERVICES": "Services",
    "30SHINE_TV": "30Shine TV",
    "TOP_HAIR_HOT": "Trendy Hair Styles",
    "LOOK_BOOK_MENU": "Lookbook",
    "OTHER_SERVICES": "Other services",
    "LANGUAGE": "Language",
    //----Input-Phone
    "BTN_BOOKING": "BOOK AN APPOINTMENT",
    "PLACEHODER_INPUT_PHONE": "Enter your phone number",
    "TEXT_BOOKING": "Pay after service, Cancel booking at will",
    "HISTORY_BOOKING": "REVIEW YOUR RESERVATION",
    "TEXT_BOTTOM": "Wanna shine - Visit 30Shine",
    //_________Home 
    "HOME_PRICE_LIST": "PRICE LIST",
    "TOP_HAIR_HOT_IN_MONTH": "Top hot 30Shine's hairstyles for men in the month",
    "LIKE": "Like",
    //----LookBook of Home 
    "LOOK_BOOK": "30SHINE'S LOOKBOOK",
    "LOOK_BOOK_ITEM_1": "MEN'S HAIR COLLECTION - SUMMER REMIX",
    "LOOK_BOOK_ITEM_2": "TOP 4 HAIRSTYLES FOR KIDS",
    "LOOK_BOOK_ITEM_3": "MOHICAN - HOT TREND OF MEN'S HAIRSTYLE IN SUMMER",
    "KID_COMBO": "Kid Combo",
    "SHINE_COMBO": "Shine Combo",
    //----LookBook of Lookbook 
    "LOOK_BOOK_HEADER": "LOOK BOOK FASHION",
    //----Shine_Care
    "SHINE_CARE": "30SHINE CARE COMMITMENT",
    "SHINE_CARE_DAY": "Days",
    "SHINE_CARE_DOWN": "Discount",
    SHINE_CARE_TITLE_1: "Free hair<br/>correction",
    SHINE_CARE_TITLE_2: "Hair coloring<br/>warranty",
    SHINE_CARE_TITLE_3: "Free exchanges<br/>returns ",
    SHINE_CARE_TITLE_4: "Hair perming<br/>warranty",
    SHINE_CARE_CONTENT_1: "If you are not satisfied with your hairstyle after going home for any reason, 30Shine will correct that hair for free within 7 days. You shall book a reservation as normal and inform to the receptionist about your hair correction upon arrival.<br/><b>Application:</b> Any salon of 30Shine.",
    SHINE_CARE_CONTENT_1_1: "<b>Application:</b> Any salon of 30Shine.",
    SHINE_CARE_CONTENT_3: "Styling products (spray, wax) under 1500 BTH bought at 30Shine are FREE to be exchanged or returned (refunded 100% of the amount) within 3 days from the time of purchase, even when the products are used.<br/><b>Application</b>: Any salon of 30Shine.<br/><b>Commitment</b>: 100% refund",
    SHINE_CARE_CONTENT_4: "If you are not satisfied with your curled/straightened hair after going home, 30Shine will fix it for free withing 14 days. You shall book a reservation as usual and inform to the receptionists that you come for fixing your hair.",
    SHINE_CARE_CONTENT_2: "If your dyed hair may not be as you expected, 30Shine will fix it for you for free within 7 days. You can book as usual and inform to the receptionists that you come to fix the hair color.",
    "LIST_STORE": "LIST OF SALONS (9.00 AM - 21.30 PM)",
    //---Booking-B2
    "SELECT_TIME": "Pick A Date And Time",
    //---Booking-B3
    "VOUCHER_TITLE": "Enter The Voucher Code",
    "APPLY_VOUCHER": "APPLY",
    "EXAMPLE_VOUCHER": "Example: 30SHINE-THAILAND",
    "CODE_ERROR": "Incorrect code",
    "CODE_SUCCESS": "Apply successfully",
    "BOOKING_TITLE_MESSAGE_BOTTOM": "EACH PHONE NUMBER APPLIES TO ONLY ONE PERSON",
    "BOOKING_CONTENT_MESSAGE_BOTTOM": "(*) If you come in group, please use another phone number to book for each individual or call the hotline for help!",
    "CUSTOM_NOTE_TITLE": "Note",
    "CUSTOM_NOTE_CONTENT": "Should you have any special requirement, please write here...",
    "FREE": "Available",
    "NOT_FREE": "Full",
    "SUGGESTIONSALON": "Do You Want To Choose Another Salon?",
    "NEAR_VISIT_SALON": "Only 2 minutes of driving time",
    "NEAR_VISIT_YOUR": "Near your place - Only ",
    "KM": "km away",
    //----Fixed buttom
    "BTN_BACK": 'BACK',
    "BTN_NEXT": "NEXT",
    "SELECT_TIME_FIXED": "PICK TIME",
    "BOOKING_FIXED": "BOOKING",
    "BOOKING_NOW": "BOOKING NOW",
    "SEE_MORE": "More",
    //--Footer
    "HOT_LINE": "Hotline for Feedback:",

    //----Message
    "BOOKING_FALSE": "Booking Error!",
    "BOOKING_SUCCESS": "Your booking is successfully booked!",
    "PHONE_ERROR": "Please type in a 10-digits phone number!",
    "NOTICE": "Notice",
    "MASSAGE_FULL_HOURS": "The selected time is fully booked!",
    "VOUCHER_NOT_NULL": "Promotion code field can not be empty!",
    "NOT_SELECT_HAIR_TIME": "Please select a time frame to book!",
    "CONFIRM_UPDATE_BOOKING": "Do you really want to change your booking?",
    "CONFIRM_DELETE_BOOKING": "Are you surely want to cancel the booking?",
    "CHANGE_BOOKING": "Change booking time",
    "FEEDBACK_HOTLINE": "Service quality feedback",
    "TIME_START": "Service time 8.30 - 21.30 (including Saturday and Sunday)",
    "BOOKING_NOT_DELAY": "Book now, getting service without waiting in line at",
    "RANKKING": "Rank",
    "TOP_HAIR": "TOP TRENDY HAIR OF 30SHINE",
    "RANKING_HISTORY": "RANK HISTORY",
    "RANKING_MONTH": "RANK FOR THE MONTH",
    "MONTH": "Month",
    "USE": "Use",
    "CURLING": "Loreal or Shishedo Hair Curling",
    "HAIR_COLORING": "Freecia Hair Coloring",
    "APPLY_COOL": "Facial mask",
    "NOSE_ACNE": 'Acne removal',
    "DETOX": "Scalp detox",
    "HAIR_REMOVAL": "Hair bleach",
    "HAIR_CARE": "Protein treatment for damaged hair",
    "ECHISLINE": "Premium Echosline hair coloring",
    "EXFOLIATION": "Facial exfoliation",
    "CURLING_VIP": "Premium ATS hair curling",
    TITLE_OPENING_DATE: "Book a haircut schedule <br/> the Salon is about to open",
    "TITLE_OPENING_DATE_MAKETING": "จองคิวตัดผมให้หล่อ",
    "YES": "YES",
    "NO": "NO",
    "EP_TOC": "Hair straightening",
    "CHAT_ON_LINE": "Chat on line",
    "CHAT_ON_FACEBOOK": "Chat on facebook",
    "SALON_OFF_MESSAGE": "30Shine 1501 Sukhumvit 67 Soi, Phrakhanong would like to inform to all of our valued customers about the closing of our salon temporarily, beginning from 3rd March, 2022 \n" +
        "We will be back in the near future with bunch of valuable services and promotions to our beloved customers. For more details, please contact our Line ID @30shine \n" +
        "Thank you for choosing our services and be our valued customer.",
    "NOTI": "30Shine Anouncement"
}