export const Curling_th = {
    "CUR_TITLE": "ดัดวอลลุ่มด้วยเทคโนโลยี ATS ระดับพรีเมียม ",
    "CUR_ITEM_1": "การดัดผมเป็นความลับอย่างหนึ่งสำหรับผู้ชายที่จะทำให้ผมอยู่ทรงได้ตลอดวันและดูมีเสน่ห์ราวกับทำที่ร้านเสริมสวยทุกวัน ทำแค่ครั้งเดียวทรงผมของคุณจะคงความสวยงามอยู่หลายเดือน",
    CUR_ITEM_2: "<b>แพคเก็จการดัดวอลลุ่มด้วยเทคโนโลยี ATS</b> ระดับพรีเมียม ของ 30Shine มอบสิ่งที่ดีที่สุดให้แก่คุณนั่นก็คือ:",
    "CUR_ITEM_2.1": "ไม่มีแอมโมเนียที่ทำให้เกิดกลิ่นอันไม่พึงประสงค์",
    "CUR_ITEM_2.2": "ไม่มี MEA และ HCL ที่เป็นอันตรายต่อเส้นผม",
    "CUR_ITEM_2.3": "ทำให้ผมอยู่ทรงสวยสมบูรณ์แบบ",
    "CUR_ITEM_2.4": "คงความนุ่มลื่นไม่ชี้ฟู",
    "CUR_ITEM_2.5": "สามารถดัดได้หลายครั้ง",
    "CUR_ITEM_2.6": "ใช้สำหรับผมเสีย",
    "CUR_ITEM_3": "โดยเฉพาะอย่างยิ่งผลิตภัณฑ์นี้มีส่วนผสมของคอลลาเจนและเคราตินที่ช่วยให้ผมของคุณนุ่มลื่นและเงางาม",
    "CUR_ITEM_4": "เทรนด์ทรงผมดัดของ 30Shine",
    "CUR_ITEM_5": "จองทันทีตอนนี้เพื่อรับบริการดัดวอลลุ่มให้คุณมีทรงผมที่ดูดีและรู้สึกผ่อนคลายในร้านที่หรูหราและมีระดับของ 30Shine.",
}

export const Curling_en = {
    "CUR_TITLE": "Premium ATS Hair Curling and Volumizing Package",
    "CUR_ITEM_1": "Hair curling and volumizing is the secret to keep men's hair volumized and in-place all day, make it look as charming as it is done at salons. Just do it once, your hair form and structure is well-kept for several months.",
    "CUR_ITEM_2": "The premium ATS Curling and Volumizing Package offers you outstanding advantages:",
    "CUR_ITEM_2.1": "No unpleasant Ammonia odor",
    "CUR_ITEM_2.2": "No harmful MEA, HCL",
    "CUR_ITEM_2.3": "Offer perfect fluffy, structured hairstyles",
    "CUR_ITEM_2.4": "Keep maximum smoothness of hair",
    "CUR_ITEM_2.5": "Unrestricted times for bending",
    "CUR_ITEM_2.6": "Applicable for damaged hair",
    "CUR_ITEM_3": "In particular, the product is enhanced with Collagen and Keratin which bring smoothness and perfect gloss for your hair.",
    "CUR_ITEM_4": "TRENDSETTING HAIRSTYLES AT 30SHINE",
    "CUR_ITEM_5": "Book now to get a handsome-looking hair curling style as well as enjoy yourself in the luxurious, classy space of 30Shine.",
}