import React from 'react'

export default function BackDrop({ show, togleMenu }) {

    const drawerClasses = show ? 'back-drop open' : 'back-drop'
    return (
        <div className={drawerClasses} style={{ position: 'fixed', width: '100%', height: '100%', top: 0, left: 0, zIndex: 500, backgroundColor: '#000', opacity: .5 }}
            onClick={() => togleMenu(false)}
        ></div>
    )
}



