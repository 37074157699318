import { Summer_remix_th, Summer_remix_en } from './Summer-remix'
import { Mohican_remix_th, Mohican_remix_en } from './Mohican'
import { Kid_th, Kid_en } from './Kid'

export const SummerRemix = {
    th: {
        "SUMMER_HEADER": "คอลเล็กชั่นทรงผมผู้ชาย",
        ...Summer_remix_th,
        ...Mohican_remix_th,
        ...Kid_th
    },
    en: {
        "SUMMER_HEADER": "Men's hair collection",
        ...Summer_remix_en,
        ...Mohican_remix_en,
        ...Kid_en
    },

}