import React, { useEffect } from 'react';
import { SetUpdateBooKing } from '../../actions'
import { connect } from 'react-redux';
import Slide from './Slide';
import Container from './container/Container';
import './css.scss';

const Home = (props) => {

    useEffect(() => {
        document.title = 'จองคิวตัดผม - ร้านตัดผมชาย 30Shine';
        props.SetUpdateBooKing({});
        localStorage.clear();
    }, [])
    return (
        <div className="home">
            <Slide />
            <Container />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        SetUpdateBooKing: (value) => dispatch(SetUpdateBooKing(value))
    }
};

export default connect(null, mapDispatchToProps)(Home);
