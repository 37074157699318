import React, { Component, Fragment } from 'react';
import Notification from 'antd/lib/notification/index'
import Input from 'antd/lib/input/Input';
import Row from 'antd/lib/row/index';
import { withRouter } from "react-router-dom";
import CONSTANS from '../constants'
import './index.scss';
import { withTranslation } from 'react-i18next'

const URL = CONSTANS.URL_IMAGES;

const IconClick = URL + 'click1.png';
const iconPhone = URL + 'Trangchu/item_call.png';
const iconSpace = URL + 'Trangchu/gachh.png';


const Placeholder = "PLACEHODER_INPUT_PHONE";
class Home extends Component {
    state = {
        phone: ""
    }

    onchangeData = (e) => {
        const { value } = e.target;
        if (((value.charCodeAt(value.length - 1) > 47 && value.charCodeAt(value.length - 1) < 58) || !value || value[value.length - 1] === '.') && value.length < 13) {
            let text = value;
            if (value.length === 4 && value[4] !== '.' && value.length > this.state.phone.length) {
                text = value + '.';
            }
            if (value.length === 8 && value[8] !== '.' && value.length > this.state.phone.length) {
                text = value + '.';
            }
            this.setState({ phone: text });
        }
    }

    onPaste = () => {
        setTimeout(() => {
            const _phone = this.state.phone;
            const phone = _phone.substring(0, 4) + '.' + _phone.substring(4, 7) + '.' + _phone.substring(7, 10);
            this.setState({ phone: phone });
        }, 200);
    }

    showMessage() {
        const config = {
            message: this.props.t("NOTICE"),
            description: this.props.t("PHONE_ERROR"),
            duration: 3,
            className: "error"
        }
        return Notification.error(config);
    }

    handleBoongKing = () => {

        const length = this.state.phone.length;
        if (length === 12 || length === 10) {
            const _phone = length === 10 ? this.state.phone : this.state.phone.replace(/\./g, '')
            const link = '/booking/' + _phone;
            this.setState({ phone: '' })
            this.props.onCancel();
            if (!this.props.salonNew) {
                this.props.history.push(link);
            } else {
                this.props.onChanPhone(this.state.phone.replace(/\./g, ''));
            }
        } else {
            this.showMessage();
        }
    }

    onKeyDown = (e) => {
        if (e.keyCode === 13)
            this.handleBoongKing();
    }

    render() {
        const { t } = this.props;
        const { phone } = this.state;
        return (
            <Fragment>
                <div className="input-other">
                    <div className="input-phone">
                        <div className="input-text">
                            <div className="icon">
                                <img src={iconPhone} alt="Icon"></img>
                                <img src={iconSpace} alt="Icon" className="right"></img>
                            </div>

                            <Input placeholder={t(Placeholder)} value={phone}
                                size="large" onChange={this.onchangeData}
                                onKeyDown={this.onKeyDown}
                                type="tel"
                                onPaste={this.onPaste}
                            />
                        </div>

                        <div style={{ display: 'flex', textAlign: 'center', marginTop: '10px' }}>
                            <div className="btn-booking" onClick={this.handleBoongKing}>
                                <Row>
                                    <span className="booking-text" >{t("BTN_BOOKING")}
                                        <img src={IconClick} style={{ width: '30px', height: '30px', marginBottom: '5px' }} alt="click" /></span>
                                </Row>
                                <Row><span className="cancle">{t("TEXT_BOOKING")}</span></Row>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default withTranslation()(withRouter(Home));

