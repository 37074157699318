import React, { useState } from 'react';
import { withRouter } from 'react-router-dom'
import CONSTANS from '../../comp/constants'
import { Link } from 'react-router-dom';
import './style.scss';
import Icon from 'antd/lib/icon/index';
import DropdownLocation from '../modalLocation/ModalLocation';
import DownloadApp from './Headerbottom';
import { connect } from 'react-redux';
// import MediaQuery from 'react-responsive';
const logo = CONSTANS.URL_IMAGES + 'Trangchu/logo.png';

const Header = ({ dataBanner, togleMenu, history }) => {
    const [visible, setVisible] = useState(false);
    // const [imgdata, setImg] = useState(null);
    // const _random = useRef(1);
    // const [height, setHeight] = useState({ pc: document.documentElement.offsetWidth / 26.794, mb: document.documentElement.offsetWidth / 13.84 });

    const toggleVisible = () => {
        setVisible(!visible);
    }

    // const ranDom = () => {
    //     let min = 0;
    //     let max = 3;
    //     let random = Math.floor(Math.random() * (+max - +min)) + +min;
    //     return random;
    // }

    // useEffect(() => {
    //     let listImgaesPc = [];
    //     let listImgaesMb = [];
    //     if (dataBanner.length > 0) {
    //         dataBanner.map(item => {
    //             return item.placementId === 9 ? item.isWebsite ? listImgaesPc.push(item) : listImgaesMb.push(item) : null;
    //         })
    //         setImg({ pc: listImgaesPc, mb: listImgaesMb });
    //     }
    //     _random.current = ranDom();
    //     setTimeout(() => {
    //         if (height.pc)
    //             setHeight({ pc: null, mb: null });
    //     }, 1000)
    // }, [dataBanner])

    // const link = history.location.pathname;
    // const drawerClasser = link === '/' ? true : false
    return (
        <div className="content-header">
            {/* {drawerClasser && <div className="header">
                <MediaQuery minWidth={768}>
                    <div style={height.pc ? { minHeight: height.pc } : {}} >
                        {imgdata &&
                            <a href={imgdata.pc[_random.current].redirectLink} target="_blank" rel="noopener noreferrer" >
                                <img src={imgdata.pc[_random.current].link} className="imgPc" alt="iconHeader" />
                            </a>
                        }
                    </div>
                </MediaQuery>

                <MediaQuery maxWidth={767}>
                    <div style={height.mb ? { minHeight: height.mb } : {}}>
                        {imgdata &&
                            <a href={imgdata.mb[_random.current].redirectLink} target="_blank" rel="noopener noreferrer" >
                                <img src={imgdata.mb[_random.current].link} className="imgMobile" alt="iconHeader" />
                            </a>
                        }
                    </div>
                </MediaQuery>
            </div>
            } */}

            <div className="logo">
                <Link to="/">
                    <img alt="Logo" src={logo}></img>
                </Link>
                <div className="menu">
                    <Icon onClick={toggleVisible} type="phone" style={{ color: '#fff', marginRight: '20px' }} />
                    <Icon id="togle-menu" type="menu-unfold"
                        style={{ color: '#fff' }} onClick={() => togleMenu(true)} />
                </div>
            </div>
            <DropdownLocation visible={visible} toggleVisible={toggleVisible} />
            <DownloadApp />
        </div >
    );
}

const mapStateToProps = (state) => {
    const { dataBanner } = state;
    return { dataBanner: dataBanner }
}

export default withRouter(connect(mapStateToProps)(Header));
