export const th = {
    //--Menu
    "HOME": "บ้าน",
    "SERVICES": "บริการ",
    "30SHINE_TV": "30Shine TV",
    "TOP_HAIR_HOT": "ทรงผมยอดนิยม",
    "LOOK_BOOK_MENU": "Lookbook",
    "OTHER_SERVICES": "บริการอื่นๆ",
    "LANGUAGE": "ภาษา",
    //----Input-Phone

    "BTN_BOOKING": "จองคิว",
    "PLACEHODER_INPUT_PHONE": "* กรอกหมายเลขโทรศัพท์",
    "TEXT_BOOKING": "ตัดเสร็จค่อยจ่ายเงิน ยกเลิกการจองได้",
    "HISTORY_BOOKING": "ดูตารางเวลาที่คุณจองไว้",
    "TEXT_BOTTOM": "อยากดูดีต้องมาที่ - 30Shine",
    //_________Home 
    "HOME_PRICE_LIST": "ราคา",
    "TOP_HAIR_HOT_IN_MONTH": "ทรงผมยอดนิยมของ 30 Shine เดือนนี้",
    "LIKE": "ชอบ",
    //----LookBook of Home 
    "LOOK_BOOK": "30SHINE'S LOOKBOOK",
    "LOOK_BOOK_ITEM_1": "คอลเล็กชั่นทรงผมผู้ชาย - Summer Remix",
    "LOOK_BOOK_ITEM_2": "4 ทรงผมยอดนิยมสำหรับเด็กผู้ชาย",
    "LOOK_BOOK_ITEM_3": "MOHICAN - เทรนด์ผมผู้ชายหน้าร้อนนี้",
    "KID_COMBO": "Kid Combo",
    "SHINE_COMBO": "Shine Combo",
    //----LookBook of Lookbook 
    "LOOK_BOOK_HEADER": "Lookbook แฟชั่น",
    //----Shine_Care
    "SHINE_CARE": "คำสัญญาจาก 30SHINE CARE",
    "SHINE_CARE_DAY": "วัน",
    "SHINE_CARE_DOWN": "ลดลง",
    SHINE_CARE_TITLE_1: "แก้ทรงผมฟรี",
    SHINE_CARE_TITLE_2: "รัปประกันทำสีผม",
    SHINE_CARE_TITLE_3: "เปลี่ยนหรือคืนสินค้าฟรี",
    SHINE_CARE_TITLE_4: "รับประกันดัดผม/ยืดผม",
    SHINE_CARE_CONTENT_1: "หากคุณไม่พอใจกับทรงผมหลังจากกลับบ้าน โดยไม่ว่าจะด้วยเหตุผลใดก็ตาม 30Shine จะช่วยให้คุณจัดซ่อมแซมทรงผมได้ฟรีภายใน 7 วัน คุณจองเวลาตามปกติและแจ้งการซ่อมแซมผมกับพนักงานต้อนรับใช้งานได้: สำหรับร้านเสริมสวยใด ๆ ในระบบ 30Shine ทั้งหมด<br/><b> ใช้สำหรับ: </b> ร้านเสริมสวยในระบบ 30Shine",
    SHINE_CARE_CONTENT_1_1: "<b> ใช้สำหรับ: </b> ร้านเสริมสวยในระบบ 30Shine",
    SHINE_CARE_CONTENT_3: "ผลิตภัณฑ์จัดแต่งทรงผม (สเปรย์, wax) ราคาไม่เกิน 1500 BTN ซื้อได้ที่ 30Shine คุณสามารถเปลี่ยนหรือคืนสินค้าได้ฟรี (คืนเงินเต็มจำนวน) ภายใน 3 วันหลังจากที่ซื้อไป แม้ว่าจะใช้ผลิตภัณฑ์นั้นไปแล้วก็ตาม<br/><b>ใช้ได้ที่</b>: ร้านที่มีระบบ 30Shine<br/><b>ข้อตกลง</b>: คืนเงิน 100%",
    SHINE_CARE_CONTENT_4: "หากคุณไม่พอใจกับการดัดผมหรือยืดผมหลังจากกลับบ้าน ทางร้าน 30Shine จะแก้ไขให้ฟรีภายใน 14 วัน ทางร้านแนะนำให้คุณจองตามปกติและแจ้งพนักงานต้อนรับว่าคุณมาเพื่อซ่อมผม",
    SHINE_CARE_CONTENT_2: "หลังจากที่คุณกลับบ้านแล้วผมที่ย้อมไปอาจไม่เป็นแบบที่คุณคิดไว้ 30Shine จะแก้ไขให้คุณฟรีภายใน 7 วันคุณสามารถจองเวลาตามปกติและแจ้งกับพนักงานต้อนรับว่ามาแก้สีผม",
    "LIST_STORE": "รายชื่อร้าน (9:00 - 21:30 น.)",
    //---Booking-B2
    "SELECT_TIME": "เลือกวันและเวลา",
    //---Booking-B3
    "VOUCHER_TITLE": "กรอกรหัสโปรโมชั่น",
    "VOUCHER_NOT_NULL": "รหัสสิทธิ์ไม่ว่าง!",
    "APPLY_VOUCHER": "ใช้",
    "EXAMPLE_VOUCHER": "เช่น 30SHINE-THAILAND",
    "CODE_ERROR": "รหัสโปรโมชั่นไม่ถูกต้องกรุณาตรวจสอบใหม่อีกครั้ง",
    "CODE_SUCCESS": "ใช้รหัสโปรโมชั่นสำเร็จ",
    "BOOKING_TITLE_MESSAGE_BOTTOM": "เบอร์โทรศัพท์แต่ละเบอร์จองได้ 1 คนเท่านั้น",
    "BOOKING_CONTENT_MESSAGE_BOTTOM": "(*) ถ้าตัดหลายคนโปรดใช้หมายเลขโทรศัพท์อื่นเพื่อทำการจองอีกครั้งหรือโทร hotline เพื่อทำการนัดหมาย!",
    "CUSTOM_NOTE_TITLE": "หมายเหตุ",
    "CUSTOM_NOTE_CONTENT": "หากคุณต้องการบริการพิเศษอื่น ๆ โปรดพิมพ์ตรงนี้...",
    "FREE": "ยังมีที่ว่าง",
    "NOT_FREE": "เต็มที่",
    "SUGGESTIONSALON": "คุณต้องการให้แนะนำร้านอื่นไหม",
    "NEAR_VISIT_SALON": "ขับรถเพียง 2 นาที",
    "NEAR_VISIT_YOUR": "ใกล้ตำแหน่งปัจจุบันของคุณ - ",
    "KM": "กม",
    //----Fixed buttom
    "BTN_BACK": 'กลับ',
    "BTN_NEXT": "ถัดไป",
    "SELECT_TIME_FIXED": "เลือกเวลาตัด",
    "BOOKING_FIXED": "จอง",
    "BOOKING_NOW": "จองตอนนี้",
    "SEE_MORE": "ดูเพิ่มเติ่ม",
    //--Footer
    "HOT_LINE": "หากมีข้อเสนอแนะเกี่ยวกับการบริการโทร Hotline :",

    //----Message
    "BOOKING_FALSE": "เกิดข้อผิดพลาดในการจอง!",
    "BOOKING_SUCCESS": "ทำการจองสำเร็จ!",
    "PHONE_ERROR": "คุณป้อนหมายเลขโทรศัพท์ 10 หลักเพื่อช่วยฉัน!",
    "NOTICE": "แจ้งให้ทราบ",
    "MASSAGE_FULL_HOURS": "เวลาคิวเต็ม",
    "NOT_SELECT_HAIR_TIME": "คุณยังไม่ได้เลือกเวลาตัด",
    "CONFIRM_UPDATE_BOOKING": "คุณมั่นใจว่าคุณต้องการเปลี่ยนตารางเวลาหรือไม่?",
    "CONFIRM_DELETE_BOOKING": "คุณแน่ใจหรือไม่ว่าต้องการยกเลิกคิวจอง",
    "CHANGE_BOOKING": "เปลี่ยนคิวที่จองไว้",
    "FEEDBACK_HOTLINE": "สายด่วนแสดงความคิดเห็นคุณภาพบริการ",
    "TIME_START": "บริการตั้งแต่เวลา 8.30 - 21.30 (ทุกวัน)",
    "BOOKING_NOT_DELAY": "อย่ารอช้ารีบจองตอนนี้เลย! ที่",
    "RANKKING": "การจัดอันดับ",
    "TOP_HAIR": "ผมเทรนด์ยอดนิยม 30 เม็ด",
    "RANKING_HISTORY": "ประวัติการจัดอันดับ",
    "RANKING_MONTH": "อันดับสำหรับเดือน",
    "MONTH": "เดือน",
    "USE": "ใช้",
    "CURLING": "ดัด Loreal   หรือ Shishedo",
    "HAIR_COLORING": "ย้อมผม Freecia",
    "APPLY_COOL": " มาร์คหน้า",
    "NOSE_ACNE": 'ดูดสิวเสี้ยนจมูก',
    "DETOX": "ดีท็อกซ์หนังศีรษะ",
    "HAIR_REMOVAL": " ฟอกสีผม/ต่อครั้ง",
    "HAIR_CARE": "อบไอน้ำ",
    "ECHISLINE": "ทำสิEchosline",
    "EXFOLIATION": " ขัดหน้า",
    "CURLING_VIP": " ดัดผมน้ำยาอย่างดี",
    "TITLE_OPENING_DATE": "จองคิวตัดผมให้หล่อ __วันเปิดร้านใหม่",
    "TITLE_OPENING_DATE_MAKETING": "จองคิวตัดผมให้หล่อ",
    "OPENING_DATE": "วันเปิด",
    "YES": "มี",
    "NO": "ไม่",
    "EP_TOC": "ยืดผมน้ำยาอย่างดี",
    "CHAT_ON_LINE": "ทักแชทไลน์",
    "CHAT_ON_FACEBOOK": "แชทเฟสบุ๊ค",
    "SALON_OFF_MESSAGE": "30Shine 1501 สุขุมวิท ซอย 67 จะปิดให้บริการตั้งแต่วันที่ 3 มีนาคม 2566\n" +
        "ทางร้านจะกลับมาด้วยชุดบริการและของขวัญพิเศษสำหรับลูกค้าทุกๆ ท่าน รายละเอียดในไลน์ ID @30shine\n" +
        "ขอบคุณลูกค้าที่เลือกใช้บริการของเราตลอดเวลาที่ผ่านมาค่ะ",
    "NOTI": "30shine ประกาศ"
}


// kid_combo  "KID_HEADER_TITLE": "รับหล่อเหมือนพ่อเหมือนลูกชายกับ Kid combo 80k ที่ 30shine",
// mat-na-lanh-cool-shine "CO_TITLE": "มาร์คหน้าเย็น",
// nhuom-mau-thoi-trang "SV_DYE_HEADER_TITLE": "การย้อมสีผมตามแฟชั่น",
// nhuom-toc-nam-cao-cap-echosline  "EC_ITEM1_1": "ทำสีผมอย่างมีระดับด้วยผลิตภัณฑ์ ECHOSLINE",
// tay-da-chet-skin-shine  "EX_TITLE": "ผลัดเซลล์ผิว",
// duong-toc     "HC_HEADER_TITLE": "บำรุงผมเสียด้วยโปรตีน",
// uon-toc-nam-cao-cap-ats "CUR_TITLE": "ดัดวอลลุ่มด้วยเทคโนโลยี ATS ระดับพรีเมียม ",
// uon-toc  "SV_CUR3_TITLE_1": "การดัดและจัดแต่งทรงผม",
// taytoc  "HA_TITLE": "ฟอกสีผมผู้ชาย",
// shinecombo  "SV_HOME_HEADER_TTILE_1": "Shine Combo 7 ขั้นตอน",